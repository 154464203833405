import axios from 'axios';

const API = axios.create({
  //baseURL: `${process.env.REACT_APP_BASE_URL}`,
  baseURL: `https://alm.tenie.co`,
});

//Company Routes
export const getData = (userId) => API.get(`/company/${userId}/getData`);
export const editData = (userId, data) =>
  API.post(`/company/${userId}/editData`, data);
export const changeOwner = (data) => API.post('/company/changeOwner', data);
export const getCompaniesForSuperAdmin = (userId) =>
  API.get(`/company/${userId}/getCompaniesForSuperAdmin`);
export const getDeletedCompaniesForSuperAdmin = (userId) =>
  API.get(`/company/${userId}/getDeletedCompaniesForSuperAdmin`);
export const deleteCompanyBySuperAdmin = (userId, data) => {
  console.log(data);
  return API.post(`/company/${userId}/deleteCompany`, data);
};

export const suspendCompanyBySuperAdmin = (userId, data) => {
  return API.post(`/company/${userId}/suspendCompany`, data);
}

export const changeAccountTypeBySuperAdmin = (userId,data) => {
  return API.post(`/company/${userId}/changeAccountType`, data);
}

export const suspendedTrialPeriodCompanies = (userId) => {
  return API.get(`/company/suspendedTrialPeriodCompanies`);
}

//Category Requests
export const getCategoriesData = (userId) =>
  API.get(`/company/${userId}/getCategoriesData`);
export const addCategory = (data) => API.post('/company/addCategory', data);
export const editCategory = (data) => API.post('/company/editCategory', data);
export const deleteCategory = (data) =>
  API.post('/company/deleteCategory', data);

//Employee Requests
export const getEmployeesData = (userId) =>
  API.get(`/company/${userId}/getEmployeesData`);
export const addEmployee = (data) => API.post('/company/addEmployee', data);
export const editEmployee = (data) => API.post('/company/editEmployee', data);
export const deleteEmployee = (data) =>
  API.post('/company/deleteEmployee', data);
export const importEmployeesData = (data) =>
  API.post('/company/importEmployees', data);

