import React, { useEffect } from 'react';
import geombg from '../../img/geom.svg';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { signUpAdmin } from '../../actions/AuthAction.js';
import * as authAPI from '../../api/AuthRequest';


/* SWAL - Sweet Alert */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import wave from '../../img/alert_wave.svg'
import bell from '../../img/bellIcon.svg'

const MySwal = withReactContent(Swal);

const SignupRight = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.authReducer.loading);
  const [nextPage, setNextPage] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('Select Currency');
  const [selectedIndustry, setSelectedIndustry] = useState('Select Industry');
  const currencyOptions = [
    'Select Currency',
    'INR',
    'USD',
    'EUR',
    'GBP',
    'AUD',
    'JPY',
  ];
  const industryOptions = [
    'Select Industry',
    'Information Technology',
    'Automobile',
    'Pharmaceutical',
    'Textile',
    'Telecommunications',
    'Banking',
    'Agriculture',
    'Construction',
    'Tourism',
    'Healthcare',
    'Retail',
    'Media and Entertainment',
    'Energy',
    'Manufacturing',
    'Financial Services',
    'Education',
    'Hospitality',
    'Transportation',
    'Real Estate',
    'Food Processing',
  ];
  const handleCurrencyChange = (e) => {
    console.log(e.target.value);
    setSelectedCurrency(e.target.value);
  };
  const handleIndustryChange = (e) => {
    console.log(e.target.value);
    setSelectedIndustry(e.target.value);
  };
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    policy: false,
  });
  const [companyData, setCompanyData] = useState({
    companyName: '',
    vatNum: '',
    gstNum: '',
    panNum: '',
    industry: '',
  });
  const [errorMsg, setErrorMsg] = useState({
    name: '',
    email: '',
    password: '',
    country: '',
    region: '',
    phone: '',
    companyErr: '',
  });
  const regExp = RegExp(/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
  const strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
  );
  const [phone, setPhone] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedRegion, setSelectedRegion] = useState();

  const formValid = () => {
    let isValid = true;

    Object.values(errorMsg).forEach((val) => {
      if (val.length > 0) {
        isValid = false;
      }
    });

    Object.values(data).forEach((val) => {
      if (val === '') {
        isValid = false;
      }
    });
    if (phone.length < 11) {
      isValid = false;
      // setErrorMsg({ ...errorMsg, phone: "Please enter a valid phone number" });
      return isValid;
    }
    if (!selectedCountry) {
      isValid = false;
      // setErrorMsg({ ...errorMsg, country: "Please select your country" });
      return isValid;
    }
    if (!selectedRegion) {
      isValid = false;
      // setErrorMsg({ ...errorMsg, region: "Please select your region" });
      return isValid;
    }
    if (!data.policy) {
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  const handleNext = async(e) => {
    let isvalid = true;
    if (!companyData.companyName || selectedIndustry === 'Select Industry') {
      setErrorMsg({
        ...errorMsg,
        companyErr: 'Please fill in all the fields',
      });
      isvalid = false;
    }
    if (!companyData.vatNum && !companyData.gstNum && !companyData.panNum) {
      setErrorMsg({
        ...errorMsg,
        companyErr: 'Please fill in all the fields',
      });
      isvalid = false;
    }
    const formData = {
      vatNum: companyData.vatNum,
      gstNum: companyData.gstNum,
      panNum: companyData.panNum,
      companyName: companyData.companyName
    }
    try{
      const res = await authAPI.checkValidity(formData);
      console.log(isvalid);
      if (isvalid) setNextPage(true);
    }catch(error){
      MySwal.fire({
        title: <h5>Company Already Registered</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: "#1CA0C4",
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width:'375px',
        confirmButtonText: 'Ok !!',
      });
    }
  };

  const handleCompanyFormChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });

    let isvalid = true;
    Object.values(companyData).forEach((val) => {
      if (val === null) {
        isvalid = false;
      }
    });
    if (isvalid) setErrorMsg({ ...errorMsg, companyErr: '' });
  };
  const handleChange = (e) => {
    // console.log(errorMsg);
    const { name, value, type, checked } = e.target;

    let isError = { ...errorMsg };

    switch (name) {
      case 'name':
        isError.name = value.length < 4 ? 'Atleast 4 characaters required' : '';
        break;
      case 'email':
        isError.email = regExp.test(value) ? '' : 'Email address is invalid';
        break;
      case 'password':
        isError.password = isError.password = strongRegex.test(value)
          ? ''
          : 'Password should be atlest 8 characters long, should contain 1 uppercase letter, 1 lowercase letter, 1 number and an special character';

        break;
      default:
        break;
    }

    setErrorMsg(isError);
    setData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValid);
    if (formValid()) {
      const newData = {
        ...data,
        ...companyData,
        phone: phone,
        industry:selectedIndustry,
        currency: selectedCurrency,
        country: selectedCountry,
        region: selectedRegion,
      };
      dispatch(signUpAdmin(newData));
    } else {
      MySwal.fire({
        title: <h5>Form is inValid</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: "#1CA0C4",
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width:'375px',
        confirmButtonText: 'Ok !!',
      });
      console.log('Form is invalid!');
    }
  };
  return (
    <div className="signupRight">
      <div className={nextPage ? 'signup-top' : 'signup-top right'}>
        {nextPage && (
          <span
            className="go-back"
            onClick={() => {
              setNextPage(false);
            }}
          >
            Back
          </span>
        )}
        <span className="already-account">
          Already have a Tenie ALM account? <Link to="/signin">Sign In</Link>
        </span>
      </div>

      <form action="" className="signupForm" onSubmit={handleSubmit}>
        {nextPage ? (
          <>
            <input
              className="signupForm-data"
              type="text"
              placeholder="Full Name"
              name="name"
              onChange={handleChange}
              value={data.name}
            />
            {errorMsg.name.length > 0 && (
              <span className="invalid-feedback">{errorMsg.name}</span>
            )}
            <input
              className="signupForm-data"
              type="email"
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
              value={data.email}
            />
            {errorMsg.email.length > 0 && (
              <span className="invalid-feedback">{errorMsg.email}</span>
            )}
            <div className="phone-number">
              <PhoneInput
                country="us"
                specialLabel=""
                inputStyle={{
                  background: '#F5F5F5',
                  border: '1px solid #D9D9D9',
                  height: '45px',
                  width: '100%',
                }}
                placeholder="Mobile Number"
                value={phone}
                onChange={setPhone}
                defaultErrorMessage="It doesn't works, why?"
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    return true;
                  }
                }}
              />
            </div>
            <input
              className="signupForm-data"
              type="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              value={data.password}
            />
            {errorMsg.password.length > 0 && (
              <span className="invalid-feedback">{errorMsg.password}</span>
            )}
            <CountryDropdown
              value={selectedCountry}
              onChange={setSelectedCountry}
              className="signupForm-data"
              name="country"
              // defaultOptionLabel="Select Country"
            />
            <RegionDropdown
              country={selectedCountry?selectedCountry:true}
              value={selectedRegion}
              onChange={setSelectedRegion}
              className="signupForm-data"
              defaultOptionLabel="Select Region"
              name="region"
            />
            {errorMsg.region.length > 0 && (
              <span className="invalid-feedback">{errorMsg.region}</span>
            )}
            <select
              value={selectedCurrency}
              className="signupForm-data"
              onChange={handleCurrencyChange}
            >
              {currencyOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <div className="policy">
              <input
                type="checkbox"
                id="policy"
                checked={data.policy}
                onChange={handleChange}
                name="policy"
              />
              <label  id='privacy' htmlFor="policy">
                I agree to the <a href="https://tenie.co/privacyPolicy" target="_blank">Privacy Policy and Other T&C</a>.
              </label>
            </div>
            <button
              className={
                data.policy ? 'signupForm-button' : 'signupForm-button inactive'
              }
              type="submit"
              disabled={!data.policy}
            >
              <span className="create-account">Create Account</span>
            </button>
          </>
        ) : (
          <>
            {errorMsg.companyErr.length > 0 && (
              <span className="invalid-feedback">{errorMsg.companyErr}</span>
            )}
            {/* <h2>Company Details</h2> */}
            <input
              className="signupForm-data"
              type="text"
              placeholder="Company Name"
              name="companyName"
              onChange={handleCompanyFormChange}
              value={companyData.companyName}
            />
            <select
              value={selectedIndustry}
              className="signupForm-data"
              onChange={handleIndustryChange}
            >
              {industryOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <input
              className="signupForm-data"
              type="text"
              placeholder="VAT Number"
              name="vatNum"
              onChange={handleCompanyFormChange}
              value={companyData.vatNum}
            />
            <input
              className="signupForm-data"
              type="text"
              placeholder="GST Number"
              name="gstNum"
              onChange={handleCompanyFormChange}
              value={companyData.gstNum}
            />
            <input
              className="signupForm-data"
              type="text"
              placeholder="PAN Number"
              name="panNum"
              onChange={handleCompanyFormChange}
              value={companyData.panNum}
            />
            <br />
            <button
              className="signupForm-button"
              type="button"
              onClick={handleNext}
            >
              Next
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default SignupRight;
