import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import './ReportPage.css';
import BarIcon from '../../img/BarIcon.svg';
import AssetIcon from '../../img/AssetIcon.svg';
import List from '../../img/List.svg';
import TaxIcon from '../../img/TaxIcon.svg';
import { useNavigate } from 'react-router-dom';
import searchLogo from '../../img/searchLogo.svg';

const ReportPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.authReducer.authData?.userData);
  if (
    user.role === 'ADMIN' ||
    user.role === 'MANAGER' ||
    user.role === 'ANALYTICS'
  ) {
    return (
      <>
        <div className="reportProfile">
          <Sidebar />
          <div className="reportRight">
            <Navbar pageHeader="Reports" />
            {/* <div className="search-report"></div> */}
            <div className="report-menu">
              <div id="AssetOverview">
                <img
                  src={AssetIcon}
                  className="ReportMenuicon"
                  alt=""
                  style={{ paddingRight: '0.5rem' }}
                />{' '}
                Asset Overview
              </div>
              <div id="ExpenseOverview">
                <img
                  src={BarIcon}
                  className="ReportMenuicon"
                  alt=""
                  style={{ paddingRight: '0.5rem' }}
                />{' '}
                Expense Overview
              </div>
              <div id="Depreciation">
                <img
                  src={TaxIcon}
                  className="ReportMenuicon"
                  alt=""
                  style={{ paddingRight: '0.5rem' }}
                />{' '}
                Depreciation
              </div>
            </div>
            <div className="reportItems">
              <div className="report-labels">
                <div
                  className="assetReports"
                  style={{ marginTop: '5rem' }}
                  onClick={() => {
                    navigate('/report-management/asset-summary');
                  }}
                >
                  <img src={AssetIcon} alt="" />
                  Asset Summary
                </div>
                <div
                  className="assetReports"
                  onClick={() => {
                    navigate('/report-management/asset-detail');
                  }}
                >
                  <img src={AssetIcon} alt="" />
                  Asset Detail
                </div>
              </div>
              <div className="report-labels">
                <div
                  style={{ marginTop: '5rem' }}
                  className="expReports"
                  onClick={() => {
                    navigate('/report-management/asset-cost-summary');
                  }}
                >
                  <img src={BarIcon} alt="" />
                  Asset Cost Summary
                </div>
                <div
                  className="expReports"
                  onClick={() => {
                    navigate('/report-management/asset-cost-detail');
                  }}
                >
                  <img src={BarIcon} alt="" />
                  Asset Cost Detail
                </div>
              </div>
              <div className="report-labels">
                <div
                  className="depReports"
                  style={{ marginTop: '5rem' }}
                  onClick={() => {
                    navigate('/report-management/depreciation-summary');
                  }}
                >
                  <img src={TaxIcon} alt="" />
                  Depreciation Summary
                </div>
                <div
                  className="depReports"
                  onClick={() => {
                    navigate('/report-management/depreciation-by-asset');
                  }}
                >
                  <img src={TaxIcon} alt="" />
                  Depreciation by Asset
                </div>
                <div
                  className="depReports"
                  onClick={() => {
                    navigate('/report-management/depreciation-forcast');
                  }}
                >
                  <img src={TaxIcon} alt="" />
                  Depreciation Forecast
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ReportPage;
