const years = [
  `Jan`,
  `Feb`,
  `Mar`,
  `Apr`,
  `May`,
  `Jun`,
  `Jul`,
  `Aug`,
  `Sep`,
  `Oct`,
  `Nov`,
  `Dec`,
];

export function getLastSixMonths() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Returns zero-based month (0 - 11)
  const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the current year

  const lastSixMonths = [];

  // Loop through the last 6 months
  for (let i = 0; i < 6; i++) {
    const index = currentMonth - i;
    const adjustedIndex = index < 0 ? 12 + index : index; // Adjust for negative index

    const monthYear = years[adjustedIndex] + `'${currentYear}`;
    lastSixMonths.unshift(monthYear); // Add monthYear to the beginning of the array
  }

  return lastSixMonths;
}

export function convertDateToFormat(arr) {
  const purchaseDataMap = new Map();

  arr.forEach((item) => {
    const date = new Date(item.purchaseDate);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      year: '2-digit',
    });
    
    if (purchaseDataMap.has(formattedDate)) {
      const existingPurchaseValue = purchaseDataMap.get(formattedDate);
      purchaseDataMap.set(
        formattedDate,
        existingPurchaseValue + Number(item.purchaseValue)
      );
    } else {
      purchaseDataMap.set(formattedDate, Number(item.purchaseValue));
    }
  });

  const formattedData = Array.from(
    purchaseDataMap,
    ([purchaseDate, purchaseValue]) => ({
      purchaseDate,
      purchaseValue: purchaseValue.toFixed(2),
    })
  );
  formattedData.sort(
    (a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate)
  );
  const lastSixElements = formattedData.slice(-6);

  return lastSixElements;
}

export function isPurchasedInCurrentMonth(purchaseDate) {
  const currentDate = new Date();
  const [purchaseYear, purchaseMonth] = purchaseDate.split('-');
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

  return (
    Number(purchaseYear) === currentYear &&
    Number(purchaseMonth) === currentMonth
  );
}
