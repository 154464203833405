import axios from "axios";

const API = axios.create({ 
    //baseURL: `${process.env.REACT_APP_BASE_URL}` 
    baseURL: `https://alm.tenie.co`,
});

export const createUser = (formData) => API.post("/user/createUser", formData);
export const getAllUsers = (userId) => API.get(`/user/${userId}/getAllUsers`);
export const geUsersForSuperAdmin = (userId) => API.get(`/user/${userId}/getUsersForSuperAdmin`);
export const updateUser = (formData) => API.post("/user/updateUser", formData);
export const deleteUser = (formData) => API.delete(`/user/${formData.userId}/${formData.id}/deleteuser`);
export const deleteUserBySuperAdmin = (userId,data) => API.post(`/user/${userId}/deleteUserBySuperAdmin`,data);
export const resetPassword = (userId,data) => API.post(`/user/${userId}/resetPassword`,data);
export const resetPasswordByCompany = (userId,data)  => API.post(`/user/${userId}/resetPasswordByCompany`,data);
export const sendOtpToUser = (data) => API.post(`/user/sendEmail`,data);
export const verifyUserOtp = (data) => API.post(`/user/verifyOTP`,data);
export const resetPasswordByUser = (data) => API.post(`/user/resetPasswordByUser`,data);
