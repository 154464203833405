import React from "react";
import logo from "../../img/tenie_logo.svg";
import leftImg from "../../img/signup_left_img.svg";
import waves from "../../img/waves.svg";

const SignupLeft = () => {
  return (
    <div className="signupLeft">
      <img className="tenie-logo" src={logo} />
      <img className="singup-left-img" src={leftImg} />
      <div className="waves-cont"></div>
    </div>
  );
};

export default SignupLeft;
