import Modal from 'react-modal';
import personLogo from '../../img/createUserField.svg';
import React, { useState } from 'react';
import './CreateUserModal.css';
import * as UserApi from '../../api/UserRequest';
import { useSelector } from 'react-redux';
import eye from '../../img/eye.svg'
import invisible from '../../img/invisible.png'
import {generateRandomPassword} from '../../Utils/numberFormatConverter'

/* SWAL - Sweet Alert */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import wave from '../../img/alert_wave.svg'
import bell from '../../img/bellIcon.svg'

const MySwal = withReactContent(Swal);

const CreateUserModal = ({ createModal, setCreateModal }) => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const regExp = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

  const [formData, setFormData] = useState({
    name: '',
    department: '',
    email: '',
    phone: '',
    password: '',
    role: 'Analytics',
    region: '',
  });
  const [errorMsg, setErrorMsg] = useState({ msg: '', email: '' });
  const [showPassword, setShowPassword] = useState(false);
  console.log(errorMsg);
  const handleChange = (e) => {
    e.preventDefault();
    let isvalid = true;
    if (e.target.value === '') {
      setErrorMsg({
        ...errorMsg,
        msg: 'Please fill in all the fields',
      });
      isvalid = false;
    }
    if (isvalid) {
      if (isvalid) setErrorMsg({ ...errorMsg, msg: '' });
    }

    if (e.target.name === 'email') {
      regExp.test(e.target.value)
        ? setErrorMsg({ ...errorMsg, email: '' })
        : setErrorMsg({ ...errorMsg, email: 'Email address is invalid' });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isvalid = true;
    console.log(formData);
    Object.values(formData).forEach((val) => {
      if (val === '') {
        setErrorMsg({
          ...errorMsg,
          msg: 'Please fill in all the fields',
        });
        isvalid = false;
        // return false;
      }
    });
    console.log(isvalid);
    Object.values(errorMsg).forEach((val) => {
      if (val.length > 0) {
        isvalid = false;
        // return false;
      }
    });
    console.log(errorMsg);
    if (isvalid) {
      console.log(formData);
      const newData = { userId: user._id, ...formData };
      try {
        const response = await UserApi.createUser(newData);
        console.log(response);
        if (response.status === 200) {
          setFormData({
            name: '',
            department: '',
            email: '',
            phone: '',
            role: 'ANALYTICS',
            region: '',
          });
          MySwal.fire({
            title: <h5>User Created Successfully </h5>,
            imageUrl: '/img/bell.svg',
            imageAlt: 'Custom image',
            confirmButtonColor: "#1CA0C4",
            background: '#fff url(/img/wave.svg) no-repeat center bottom',
            width:'375px',
            confirmButtonText: 'Ok !!',
          });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 500){
          MySwal.fire({
            title: <h5>An account already exists with this email</h5>,
            imageUrl: '/img/bell.svg',
            imageAlt: 'Custom image',
            confirmButtonColor: "#1CA0C4",
            background: '#fff url(/img/wave.svg) no-repeat center bottom',
            width:'375px',
            confirmButtonText: 'Ok !!',
          });
        }
        else if (error.response.status === 403){
          MySwal.fire({
            title: <h5>You Do Not Have Access!!</h5>,
            imageUrl: '/img/bell.svg',
            imageAlt: 'Custom image',
            confirmButtonColor: "#1CA0C4",
            background: '#fff url(/img/wave.svg) no-repeat center bottom',
            width:'375px',
            confirmButtonText: 'Ok !!',
          });
        }
        else {
          MySwal.fire({
            title: <h5>Error! User Not Created</h5>,
            imageUrl: '/img/bell.svg',
            imageAlt: 'Custom image',
            confirmButtonColor: "#1CA0C4",
            background: '#fff url(/img/wave.svg) no-repeat center bottom',
            width:'375px',
            confirmButtonText: 'Ok !!',
          });
        }
      }
    }
    setCreateModal(false);
  };
  const handleClose = (event) => {
    event.preventDefault();
    setCreateModal(false);
    setFormData({
      name: '',
      department: '',
      email: '',
      phone: '',
      role: 'Analytics',
      region: '',
    });
    setErrorMsg({ msg: '', email: '' });
  };
  console.log(formData);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Modal style={{content:{width:"40rem",padding:"0",marginLeft:"25rem"}}} isOpen={createModal} onRequestClose={() => setCreateModal(false)}>
        <div className="modalNav">
          Add User
        </div>
      <form action="" className="createUser-form">
        {errorMsg.msg.length > 0 && (
          <span className="invalid-feedback">{errorMsg.msg}</span>
        )}
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" />
            <label htmlFor="name">Name</label>
          </div>
          <input
            type="text"
            id="name"
            name="name"
            onChange={handleChange}
            value={formData.name}
          />
        </div>
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" value={formData.department} />
            <label htmlFor="department">Department</label>
          </div>
          <input
            type="text"
            id="department"
            name="department"
            value={formData.department}
            onChange={handleChange}
          />
        </div>
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" />
            <label htmlFor="Name">Role</label>
          </div>
          <select name="role" onChange={handleChange} value={formData.role}>
            <option selected value="ANALYTICS">
              Analytics
            </option>
            <option value="TEAM">Team</option>
            {user.role === 'ADMIN' && <option value="MANAGER">Manager</option>}
            {user.role === 'ADMIN' && <option value="ADMIN">Admin</option>}
          </select>
        </div>
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" />
            <label htmlFor="email">Email</label>
          </div>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        {errorMsg.email.length > 0 && (
          <span className="invalid-feedback">{errorMsg.email}</span>
        )}
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" />
            <label htmlFor="phone">Phone</label>
          </div>
          <input
            type="phone"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" />
            <label htmlFor="phone">Password</label>
          </div>
          <div>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <span onClick={togglePasswordVisibility} style={{cursor:'pointer'}}>
              <img style={{width:"1rem"}} src={showPassword?invisible:eye} alt="" srcset="" />
            </span>
            <div id="generatePassword" onClick={(e) =>{
              setFormData({...formData, ["password"]:generateRandomPassword()})
            }}>Generate Password</div>
          </div>
        </div>
        <div className="createUser-data">
          <div>
            <img src={personLogo} alt="" />
            <label htmlFor="region">Location</label>
          </div>
          <input
            type="text"
            id="region"
            name="region"
            value={formData.region}
            onChange={handleChange}
          />
        </div>

        <div className="createUser-buttons">
          <button
            type="submit"
            className="createUser-button-submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            type="button"
            className="createUser-button-cancel"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateUserModal;
