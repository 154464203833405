const companyReducer = (
  state = { companyData: null, loading: false, error: false, uploading: false },
  action
) => {
  switch (action.type) {
    // Get Company Data (GET REQUEST)
    case 'RETREIVING_START':
      return { ...state, loading: true, error: false };
    case 'RETREIVING_SUCCESS':
      return {
        ...state,
        companyData: action.data,
        loading: false,
        error: false,
      };
    case 'RETREIVING_FAIL':
      return { ...state, loading: false, error: true };
    case 'LOG_OUT':
      localStorage.clear();
      return { ...state, companyData: null, loading: false, error: false };
    default:
      return state;
  }
};

export default companyReducer;
