import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from 'react-country-region-selector';
// import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import './CompanyProfile.css';
import { useSelector } from 'react-redux';
import * as CompanyApi from '../../api/CompanyRequest';
import * as UserApi from '../../api/UserRequest';
import Modal from 'react-modal';

/* SWAL - Sweet Alert */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import wave from '../../img/alert_wave.svg';
import bell from '../../img/bellIcon.svg';

const MySwal = withReactContent(Swal);

const CompanyProfile = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [edit, setEdit] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyName: '',
    address: '',
    country: '',
    region: '',
    industry: '',
    vatNum: 0,
    panNum: 0,
    gstNum: 0,
    totalUsers: '',
    primaryUserName: '',
    primaryUserEmail: '',
    primaryUserPhone: 0,
  });

  const [phone, setPhone] = useState();
  const [vat, setVat] = useState();
  const [pan, setPan] = useState();
  const [gst, setGst] = useState();
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [selectedState, setSelectedState] = useState();
  const [file, setFile] = useState();
  const [adminData, setAdminData] = useState();
  const [changeAdminModal, setChangeAdminModal] = useState(false);
  const [adminValue, setAdminValue] = useState();
  const [adminModalNext, setAdminModalNext] = useState(false);

  useEffect(() => {
    const getCompanyData = async () => {
      const response = await CompanyApi.getData(user._id);
      setCompanyData(response.data);
      setSelectedCountry(response.data.country);
      setSelectedState(response.data.region);
      setPhone(response.data.primaryUserPhone);
      setGst(response.data.gstNum);
      setPan(response.data.panNum);
      setVat(response.data.vatNum);
    };

    if (user.role === 'ADMIN' || user.role === 'MANAGER') getCompanyData();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const handleOwnerChange = async (e) => {
    e.preventDefault();
    const response = await UserApi.getAllUsers(user._id);
    const filteredData = response.data.filter(function (ob) {
      return ob.role === 'ADMIN' && ob.email !== companyData.primaryUserEmail;
    });
    // console.log(filteredData);
    if (filteredData.length === 0) {
      MySwal.fire({
        title: <h5>No Other Admin Found</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: '#1CA0C4',
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width: '375px',
        confirmButtonText: 'Ok !!',
      });
      return;
    }
    setAdminData(filteredData);
    setAdminValue(filteredData[0]._id);
    setChangeAdminModal(true);
  };
  const handleConfirmOwnerChange = async (e) => {
    e.preventDefault();
    console.log(adminValue);
    const response = await CompanyApi.changeOwner({
      userId: user._id,
      companyId: companyData._id,
      newOwnerId: adminValue,
    });
    console.log(response);
    setChangeAdminModal(false);
  };
  const handleRejectOwnerChange = (e) => {
    setAdminData([]);
    setAdminModalNext(false);
    setChangeAdminModal(false);
  };
  const changeOwnerNext = (e) => {};

  const handleEdit = async (e) => {
    e.preventDefault();
    console.log(edit);
    if (!edit) {
      setEdit(true);
    } else {
      companyData.primaryUserPhone = phone;
      companyData.panNum = pan;
      companyData.vatNum = vat;
      companyData.gstNum = gst;
      companyData.country = selectedCountry;
      companyData.region = selectedState;
      await CompanyApi.editData(user._id, companyData);
      MySwal.fire({
        title: <h5>Saved Successfully</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: '#1CA0C4',
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width: '375px',
        confirmButtonText: 'Ok !!',
      });
      setEdit(false);
    }
  };
  function handleFile(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleChange = (e) => {
    let isvalid = true;
    Object.values(companyData).forEach((val) => {
      if (val === '') {
        isvalid = false;
      }
    });
    if (e.target.value === '') {
      isvalid = false;
    }
    if (e.target.name === 'status' && e.target.value !== 'ASSIGNED') {
      const updateArr = companyData.allocationHist;
      for (let i = 0; i < updateArr.length; i++) {
        if (updateArr[i].deallocationDate === '') {
          MySwal.fire({
            title: <h5>Deallocate asset to change status</h5>,
            imageUrl: '/img/bell.svg',
            imageAlt: 'Custom image',
            confirmButtonColor: '#1CA0C4',
            background: '#fff url(/img/wave.svg) no-repeat center bottom',
            width: '375px',
            confirmButtonText: 'Ok !!',
          });
          return;
        }
      }
    }
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
  };
  if (user.role === 'ADMIN' || user.role === 'MANAGER') {
    return (
      <div className="companyProfile">
        <Sidebar />
        <div className="companyRight">
          <Navbar pageHeader="Company Profile" />
          <div className="company-header">Company Information</div>
          {!companyData ? (
            <div className="unautorizedAccess">
              <h4>Loading Data...</h4>
            </div>
          ) : (
            <div className="cf-main">
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    id="companyName"
                    type="text"
                    readOnly={true}
                    name="companyName"
                    value={companyData.companyName}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="address">Company Address</label>
                  <textarea
                    id="address"
                    readOnly={!edit}
                    type="text"
                    name="address"
                    value={companyData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="companyLegalName">Company Legal Name</label>
                  <input
                    id="companyLegalName"
                    type="text"
                    name="companyLegalName"
                    readOnly={!edit}
                    value={''}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="companyEmail">Company Email</label>
                  <input
                    id="companyEmail"
                    type="text"
                    name="companyEmail"
                    readOnly={!edit}
                    value={''}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
              </div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="companyPhone">Company Phone</label>
                  <input
                    id="companyPhone"
                    type="text"
                    name="companyPhone"
                    readOnly={!edit}
                    value={''}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
              </div>
              <div className="company-header">Basic Information</div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="country">Country</label>
                  <CountryDropdown
                    name="country"
                    id="country"
                    readOnly={!edit}
                    value={selectedCountry}
                    onChange={edit ? (e) => setSelectedCountry(e) : undefined}
                    className="country"
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="state">Region</label>
                  <RegionDropdown
                    name="state"
                    id="state"
                    country={selectedState}
                    readOnly={!edit}
                    value={edit ? (e) => setSelectedState(e) : undefined}
                    onChange={edit ? setSelectedState : undefined}
                    className="state"
                  />
                </div>
              </div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="industry">Industry</label>
                  <input
                    id="industry"
                    type="text"
                    name="industry"
                    readOnly={!edit}
                    value={companyData.industry}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="website">Website</label>
                  <input
                    id="website"
                    type="text"
                    name="website"
                    readOnly={!edit}
                    value={''}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
              </div>
              <div className="company-header">Tax Information</div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="vatid">VAT ID</label>
                  <input
                    id="vatid"
                    type="text"
                    name="vatid"
                    readOnly={!edit}
                    value={vat}
                    onChange={edit ? (e) => setVat(e.target.value) : undefined}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="vatid">Tax ID</label>
                  <input
                    id="vatid"
                    type="text"
                    name="vatid"
                    readOnly={!edit}
                    value={vat}
                    onChange={edit ? (e) => setVat(e.target.value) : undefined}
                  />
                </div>
              </div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="pan">PAN</label>
                  <input
                    id="pan"
                    type="text"
                    name="pan"
                    readOnly={!edit}
                    value={pan}
                    onChange={edit ? (e) => setPan(e.target.value) : undefined}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="gst">GST</label>
                  <input
                    id="gst"
                    type="text"
                    name="gst"
                    readOnly={!edit}
                    value={gst}
                    onChange={edit ? (e) => setGst(e.target.value) : undefined}
                  />
                </div>
              </div>
              <div className="company-header">User Information</div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="userCount">No of Users</label>
                  <input
                    id="userCount"
                    type="text"
                    name="userCount"
                    readOnly
                    value={companyData.totalUsers.length}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="primaryUserName">Primary User Name</label>
                  <input
                    readOnly={!edit}
                    id="primaryUserName"
                    type="text"
                    name="primaryUserName"
                    value={companyData.primaryUserName}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
              </div>
              <div className="cf-flex">
                <div className="companyForm-inputCont">
                  <label htmlFor="primaryUserEmail">Primary User Email</label>
                  <input
                    id="primaryUserEmail"
                    type="text"
                    name="primaryUserEmail"
                    readOnly={!edit}
                    value={companyData.primaryUserEmail}
                    onChange={edit ? handleChange : undefined}
                  />
                </div>
                <div className="companyForm-inputCont">
                  <label htmlFor="primaryUserEmail">Primary User Phone</label>
                  <input
                    id="mobileNumber"
                    type="number"
                    name="mobileNumber"
                    value={phone}
                    readOnly={!edit}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div
                className="cf-flex"
                style={{
                  width: '100%',
                  backgroundColor: '#E4E3E3',
                }}
              >
                <div></div>
                <div className="company-buttons">
                  <button
                    type="button"
                    className={
                      edit
                        ? 'company-button-submit-save'
                        : 'company-button-submit'
                    }
                    onClick={handleEdit}
                  >
                    {edit ? 'Save' : 'Edit'}
                  </button>
                  <button
                    type="button"
                    className="company-button-changeOwner"
                    onClick={handleOwnerChange}
                  >
                    Change Owner
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          isOpen={changeAdminModal}
          onRequestClose={() => setChangeAdminModal(false)}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
            },
            content: {
              position: 'absolute',
              top: '25%',
              left: '25%',
              right: '25%',
              bottom: '25%',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '40px',
              outline: 'none',
              padding: '2rem',
            },
          }}
        >
          {adminModalNext ? (
            <div className="deleteModal">
              <h1>Are you sure you want to change the owner account?</h1>
              <div className="deleteModal-btn">
                <button
                  type="submit"
                  className="userTable-edit"
                  onClick={handleConfirmOwnerChange}
                >
                  YES
                </button>
                <button
                  type="button"
                  className="userTable-delete"
                  onClick={handleRejectOwnerChange}
                >
                  NO
                </button>
              </div>
            </div>
          ) : (
            <div className="company-selectModal">
              <button
                className="companyModal-closebtn"
                onClick={() => setChangeAdminModal(false)}
              >
                Close
              </button>
              <select
                value={adminValue}
                onChange={(e) => setAdminValue(e.target.value)}
              >
                {adminData?.map((item) => {
                  return (
                    <option key={item.email} value={item._id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <button
                className="company-button-changeOwner"
                onClick={() => setAdminModalNext(true)}
              >
                Select
              </button>
            </div>
          )}
        </Modal>
      </div>
    );
  } else {
    <h4 className="unautorizedAccess">You do not have access to this page!</h4>;
  }
};

export default CompanyProfile;
