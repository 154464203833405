import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import personLogo from "../../img/createUserField.svg";
import { read, utils, writeFile } from "xlsx";
import Modal from "react-modal";
import "./AddCategory.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as CompanyApi from "../../api/CompanyRequest";
import View from '../../img/views.svg'
import Delete from '../../img/deleteBlue.svg'
import exportImg from '../../img/export.svg'
import Edit from '../../img/edit.svg'

const AddCategory = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [modalOpened, setModalOpened] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [fieldCounter, setFieldCounter] = useState(1);
  const dataTypeOptions = ["text", "number"];
  const [tableData, setTableData] = useState();
  const [categoriesData, setCategoriesData] = useState();

  const [fieldData, setFieldData] = useState({
    categoryName: "",
    content: [
      {
        field: "",
        fieldType: dataTypeOptions[0],
      },
    ],
  });

  useEffect(() => {
    const getCompanyData = async () => {
      const response = await CompanyApi.getData(user._id);
      setCategoriesData(response.data.assetCategories);
    };

    if (user.role === "ADMIN" || user.role === "MANAGER") getCompanyData();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  function flatten(array) {
    var result = [];
    array.forEach(function (a) {
      result.push(a);
      console.log(a);
      if (Array.isArray(a.content)) {
        result = result.concat(flatten(a.content));
      }
    });
    return result;
  }
  const handleExport = () => {
    let maxIndex = -1;

    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].content.length > maxIndex) {
        maxIndex = tableData[i].content.length;
      }
    }

    let newArr = [];
    let newObj = {};
    // for (let i = 0; i < tableData[0].content.length; i++) {
    Object.assign(newObj, tableData[0].content);
    // console.log(tableData[0].content[i]);
    // }
    console.log(newObj);

    const dataHeader = [];
    for (let i = 0; i < maxIndex; i++) {
      dataHeader.push("Data " + (i + 1));
    }

    const headings = [["Name", ...dataHeader]];
    // console.log(headings);
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, tableData, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Employees");
    writeFile(wb, "EmployeesReport.xlsx");
  };
  const handleAddField = (e) => {
    e.preventDefault();
    // setFieldCounter((oldValue) => oldValue + 1);
    // setModalOpened(true);
    // console.log(fieldData.content.length);
    setFieldData({
      ...fieldData,
      content: [
        ...fieldData.content,
        {
          field: "",
          fieldType: dataTypeOptions[0],
        },
      ],
    });
  };

  const handleRemoveField = (e) => {
    e.preventDefault();
    if (fieldData.content.length > 1) {
      // setFieldCounter((oldValue) => oldValue - 1);
      const newArray = fieldData.content.slice(0, -1);
      // console.log(newArray);
      setFieldData({ ...fieldData, content: newArray });
    } else {
      alert("There should be atleast one value");
    }
  };

  const handleChange = (index) => (e) => {
    if (e.target.name === "categoryName") {
      setFieldData({ ...fieldData, [e.target.name]: e.target.value });
    } else {
      const newArray = fieldData.content.map((item, i) => {
        if (index === i) {
          return { ...item, [e.target.name]: e.target.value };
        } else {
          return item;
        }
      });
      setFieldData({ ...fieldData, content: newArray });
    }
  };

  const handleSubmit = async (event) => {
    // event.preventDefault();
    // setTableData([...tableData, fieldData]);
    try {
      const response = await CompanyApi.addCategory({
        userId: user._id,
        ...fieldData,
      });
      if (response.status === 200) {
        setFieldData({
          categoryName: "",
          content: [
            {
              field: "",
              fieldType: dataTypeOptions[0],
            },
          ],
        });
        setModalOpened(false);
        alert("Category Added Successfully!");
      }
    } catch (error) {
      setModalOpened(false);
      console.log(error);
      if (error.response.status === 500) alert("Error Adding Cateogry!");
      else if (error.response.status === 403) alert("You do not have access!");
      else alert("Error!");
    }
    setModalOpened(false);
  };

  const handleShowMore = (event) => {};

  const handleEdit = (id) => (event) => {
    event.preventDefault();
    setEditId(id);
    const currData = categoriesData.find((item) => item._id === id);
    console.log(currData);
    setFieldData(currData);
    setEditModal(true);
  };

  const handleCloseModal = (event) => {
    event.preventDefault();
    setModalOpened(false);
    setEditModal(false);

    setFieldData({
      categoryName: "",
      content: [
        {
          field: "",
          fieldType: dataTypeOptions[0],
        },
      ],
    });
  };

  const handleEditSubmit = async (event) => {
    // event.preventDefault();
    // let newArr = categoriesData.map((item, index) => {
    //   if (item._id === editId) {
    //     return fieldData;
    //   }
    //   return item;
    // });
    // setTableData(newArr);

    try {
      const response = await CompanyApi.editCategory({
        userId: user._id,
        categoryId: editId,
        ...fieldData,
      });
      console.log(response);
      if (response.status === 200) {
        setFieldData({
          categoryName: "",
          content: [
            {
              field: "",
              fieldType: dataTypeOptions[0],
            },
          ],
        });
        alert("Category Updated Successfully!");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) alert("Error Updating!");
      else if (error.response.status === 403) alert("You do not have access!");
      else alert("Error Updating!");
    }
    setEditModal(false);
    // window.location.reload(false);
  };

  const handleDeleteModal = (id) => (event) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  const handleDeleteConfirm = async (event) => {
    // event.preventDefault();

    try {
      const response = await CompanyApi.deleteCategory({
        userId: user._id,
        categoryId: deleteId,
      });
      console.log(response);
      if (response.status === 200) {
        setFieldData({
          categoryName: "",
          content: [
            {
              field: "",
              fieldType: dataTypeOptions[0],
            },
          ],
        });
        alert("Category Deleted Successfully!");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 500) alert("Error Deleting!");
      else if (error.response.status === 403) alert("You do not have access!");
      else alert("Error Deleting!");
    }

    setDeleteModal(false);
    setDeleteId(null);
  };

  const handleDeleteReject = (event) => {
    event.preventDefault();
    setDeleteId(null);
    setDeleteModal(false);
  };

  return (
    <div className="addCategory sidebar-container">
      <Sidebar />
      <div className="addCategory-container">
        <Navbar pageHeader="Add Category" functionality={setModalOpened}/>
        <div className="print-export print-export2" style={{backgroundColor:'#ffffff'}}>
          <div ></div>
          <div className="import export">
            {/* Import */}

            {/* <label className="custom-file-label" htmlFor="inputGroupFile">
              Import
              <input
                hidden
                type="file"
                name="file"
                id="inputGroupFile"
                onChange={handleImport}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                //     onChange={(e) => {
                //   const file = e.target.files[0];
                //   handleImport(file);
                // }}
              />
            </label> */}
            {/* <img src={downArrow} alt="" /> */}
            <div className="export">
            <img src={exportImg} style={{width:'6rem', cursor:'pointer'}} onClick={handleExport} alt="" />
              {/* <button className="custom-file-label" onClick={handleExport}>
                Export
              </button> */}
            </div>
          </div>
        </div>

        <table className="userTable">
          <tr className="userTable-row-header">
            {/* <th>Select</th> */}
            <th>S. No</th>
            <th>Name</th>
            <th>Feature 1</th>
            <th>Feature 2</th>
            <th>Feature 3</th>
            <th>Feature 4</th>
            <th>Feature 5</th>
            <th>Feature 6</th>
            <th>More</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          {/* <tr>
            <td>1</td>
            <td>Name</td>
            <td>data1</td>
            <td>data</td>
            <td>data</td>
            <td>data</td>
            <td>data</td>
            <td>data</td>
            <td>data</td>
            <td>
              <button className="userTable-edit" onClick={handleEdit}>
                Edit
              </button>
            </td>
            <td>
              <button className="userTable-delete">Delete</button>
            </td>
          </tr> */}
          {categoriesData?.length >= 1 &&
            categoriesData?.map(({ categoryName, content, _id }, index) => (
              <tr>
                {/* <td>
                <input type="checkbox" id="policy" name="policy" />
              </td> */}
                <td>{index + 1}</td>
                <td>{categoryName}</td>
                <td>{content[0]?.field}</td>
                <td>{content[1] ? content[1].field : ""}</td>
                <td>{content[2] ? content[2].field : ""}</td>
                <td>{content[3] ? content[3].field : ""}</td>
                <td>{content[4] ? content[4].field : ""}</td>
                <td>{content[5] ? content[5].field : ""}</td>
                <td className="show-more" onClick={handleEdit(_id)}>
                  {content.length > 6 ? "Show More" : ""}
                </td>
                {/* {data.content.map((contentData, index1) => (
                  <td>{contentData.field}</td>
                ))} */}

                <td >
                  {/* <button className="userTable-edit" onClick={handleEdit(_id)}>
                    Edit
                  </button> */}
                  <img src={Edit} alt=""  onClick={handleEdit(_id)} style={{width:'60px', marginLeft:"-1rem", cursor:"pointer"}}/>
                </td>
                <td>
                  {/* <button
                    className="userTable-delete"
                    onClick={handleDeleteModal(_id)}
                  >
                    Delete
                  </button> */}
                  <img src={Delete} alt=""  onClick={handleDeleteModal(_id)} style={{width:'60px', marginLeft:"-1rem", cursor:"pointer"}}/>
                </td>
              </tr>
            ))}
        </table>
      </div>

      {/* Add category modal */}
      <Modal
        style={{content:{width:"40rem",padding:"0",marginLeft:"25rem"}}}
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
        shouldCloseOnOverlayClick={false}
      >
        <div className="modalNav">
          Add Category
        </div>
        <form className="createUser-form addCategory">
          <div className="createUser-data">
            <div>
              <img src={personLogo} alt="" />
              <label htmlFor="categoryName">Category Name</label>
            </div>
            <input
              type="text"
              id="categoryName"
              name="categoryName"
              onChange={handleChange(0)}
              value={fieldData?.categoryName}
            />
          </div>
          {/* {[...Array(fieldCounter)].map((fieldCounter, index) => ( */}
          {fieldData?.content.map((data, index) => (
            <div className="dataField-container">
              <div className="createUser-data">
                <div>
                  <img src={personLogo} alt="" />
                  <label htmlFor="harwareData">HD{index + 1} Name</label>
                </div>
                <input
                  type="text"
                  id={"field"}
                  name={"field"}
                  onChange={handleChange(index)}
                  value={data.field}
                />
              </div>
              <div className="createUser-data">
                <div>
                  <img src={personLogo} alt="" />
                  <label htmlFor="Name">HD{index + 1} Data Type</label>
                </div>
                <select
                  id={"fieldType"}
                  name={"fieldType"}
                  value={data.fieldType}
                  onChange={handleChange(index)}
                >
                  {dataTypeOptions.map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
          <div className="createUser-buttons categoryButtons">
            <div>
              <button
                className="createUser-button-cancel"
                onClick={handleAddField}
              >
                Add Field
              </button>
              <button
                className="createUser-button-cancel"
                onClick={handleRemoveField}
              >
                Remove Field
              </button>
            </div>
            <div>
              <button
                type="button"
                className="createUser-button-submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="createUser-button-cancel"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
      {/* Edit Modal */}
      <Modal style={{content:{width:"40rem",padding:"0",marginLeft:"25rem"}}} isOpen={editModal} onRequestClose={() => setEditModal(false)}>
      <div className="modalNav">
         Edit Category
        </div>
        <form className="createUser-form addCategory">
          <div className="createUser-data">
            <div>
              <img src={personLogo} alt="" />
              <label htmlFor="categoryName">Category Name</label>
            </div>
            <input
              type="text"
              id="categoryName"
              name="categoryName"
              onChange={handleChange(0)}
              value={fieldData?.categoryName}
            />
          </div>

          {fieldData?.content.map((data, index) => (
            <div className="dataField-container">
              <div className="createUser-data">
                <div>
                  <img src={personLogo} alt="" />
                  <label htmlFor="harwareData">HD{index + 1} Name</label>
                </div>
                <input
                  type="text"
                  id={"field"}
                  name={"field"}
                  onChange={handleChange(index)}
                  value={data.field}
                />
              </div>
              <div className="createUser-data">
                <div>
                  <img src={personLogo} alt="" />
                  <label htmlFor="Name">HD{index + 1} Data Type</label>
                </div>
                <select
                  id={"fieldType"}
                  name={"fieldType"}
                  value={data.fieldType}
                  onChange={handleChange(index)}
                >
                  {dataTypeOptions.map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
          <div className="createUser-buttons categoryButtons">
            <div>
              <button
                className="createUser-button-cancel"
                onClick={handleAddField}
              >
                Add Field
              </button>
              <button
                className="createUser-button-cancel"
                onClick={handleRemoveField}
              >
                Remove Field
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="createUser-button-submit"
                onClick={handleEditSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="createUser-button-cancel"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "25%",
            left: "25%",
            right: "25%",
            bottom: "25%",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "40px",
            outline: "none",
            padding: "2rem",
          },
        }}
      >
        <div className="deleteModal">
          <h1>Are you sure you want to delete?</h1>
          <div className="deleteModal-btn">
            <button
              type="button"
              className="userTable-edit"
              onClick={handleDeleteConfirm}
            >
              YES
            </button>
            <button
              type="button"
              className="userTable-delete"
              onClick={handleDeleteReject}
            >
              NO
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddCategory;
