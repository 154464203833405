import React, { useEffect, useState } from 'react';
import addIcon from '../../img/addAssetIcon.svg';

import searchLogo from '../../img/searchLogo.svg';
import downArrow from '../../img/downArrow.svg';
import exportImg from '../../img/export.svg';
import printerLogo from '../../img/printerLogo.svg';
import sortSign from '../../img/sortSign.svg';
import Navbar from '../Navbar/Navbar';
import * as AssetApi from '../../api/AssetRequest';
import * as CompanyApi from '../../api/CompanyRequest';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import './HomePage.css';
import Sidebar from '../Sidebar/Sidebar';
import HomePageEdit from './HomePageEdit';
import addOrangeSVG from '../../img/addOrangeSVG.svg';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as dateConverter from '../../Utils/dateFormat';
import * as numberConverter from '../../Utils/numberFormatConverter';
import XLSX from 'sheetjs-style';
import INR from '../../img/currencies/INR.png';
import EUR from '../../img/currencies/EUR.png';
import USD from '../../img/currencies/USD.png';
import View from '../../img/views.svg';
import Delete from '../../img/deleteBlue.svg';
import Edit from '../../img/edit.svg';
// import { format } from "date-fns";

const HomePage = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [assetsData, setAssetsData] = useState();
  const [modalOpen, setModalOpened] = useState(false);
  const [viewAssetData, setViewAssetData] = useState(); //stores object id of asset we want to see
  const [hardwareDataKeys, setHardwareDataKeys] = useState(); //stores object id of asset we want to see
  const [editFormOpen, setEditForm] = useState(false);
  const [editAssetData, setEditAssetData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [assetFilter, setAssetFilter] = useState('ALL');
  const [dateFormat, setDateFormat] = useState(false);
  const [numberFormat, setNumberFormat] = useState(false);
  const [dupAssetData, setDupAssetData] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [idx, setIdx] = useState();
  const currecy = [INR, USD, EUR];

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const handleExport = async () => {
    const exportData = assetsData.map(
      ({
        _id,
        hardwareData,
        serviceArr,
        allocationHist,
        companyId,
        document,
        createdAt,
        updatedAt,
        __v,
        ...keepAttrs
      }) => keepAttrs
    );
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'AssetReport' + fileExtension);
  };

  useEffect(() => {
    const getCategories = async () => {
      const response = await AssetApi.getAllAssets(user._id);
      console.log(response);
      setAssetsData(response.data);
      setDupAssetData(response.data);
    };
    getCategories();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    const getCompnay = async () => {
      const response = await CompanyApi.getData(user._id);
      setIdx(
        currecy.findIndex((item) => {
          return item.toString().includes(response.data.currency);
        })
      );
      if (response.data.numberFormat === 'indian') {
        setNumberFormat(true);
      }
      if (response.data.dateFormat === 'dd-mm-yyyy') {
        setDateFormat(true);
      }
    };
    getCompnay();
  }, []);
  const handleSortModel = (event) => {
    const data2 = [...assetsData].sort((a, b) =>
      a.model.toLowerCase() < b.model.toLowerCase() ? -1 : 1
    );

    data2.map((d) => console.log('with conversion', d.name)); // doe, Ibas
    setAssetsData(data2);
  };
  const handleSortManufacturer = (event) => {
    const data2 = [...assetsData].sort((a, b) =>
      a.manufacturer.toLowerCase() < b.manufacturer.toLowerCase() ? -1 : 1
    );

    data2.map((d) => console.log('with conversion', d.name)); // doe, Ibas
    setAssetsData(data2);
  };
  const handleSortDealer = (event) => {
    const data2 = [...assetsData].sort((a, b) =>
      a.dealerName.toLowerCase() < b.dealerName.toLowerCase() ? -1 : 1
    );

    data2.map((d) => console.log('with conversion', d.name)); // doe, Ibas
    setAssetsData(data2);
  };
  const handleSortPurchaseDate = (event) => {
    const data2 = [...assetsData].sort((a, b) => {
      const dateA = new Date(a.purchaseDate);
      const dateB = new Date(b.purchaseDate);
      return dateA - dateB;
    });

    data2.map((d) => console.log('with conversion', d.name)); // List of asset names sorted by purchase date
    setAssetsData(data2);
  };
  const handleSortStatus = (event) => {
    const data2 = [...assetsData].sort((a, b) =>
      a.status.toLowerCase() < b.status.toLowerCase() ? -1 : 1
    );

    data2.map((d) => console.log('with conversion', d.name)); // doe, Ibas
    setAssetsData(data2);
  };
  const handleSortAllocated = (event) => {
    const data2 = [...assetsData].sort((a, b) =>
      a.currentUserName.toLowerCase() < b.currentUserName.toLowerCase() ? -1 : 1
    );

    data2.map((d) => console.log('with conversion', d.name)); // doe, Ibas
    setAssetsData(data2);
  };
  const handleSearchFilter = (e) => {
    const result = dupAssetData.filter((o) =>
      Object.keys(o).some((k) => {
        if (k === 'hardwareData') {
          return o[k].categoryName
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        if (typeof o[k] === 'string')
          return o[k].toLowerCase().includes(e.target.value.toLowerCase());
      })
    );

    // console.log(result);
    setAssetsData(result);
    setSearchFilter(e.target.value);
  };

  const handleViewAsset = (assetId) => (event) => {
    const currData = assetsData.find((item) => item._id === assetId);
    // console.log(currData);
    setViewAssetData(currData);
    setModalOpened(true);
    const keys = Object.keys(currData.hardwareData);
    const res = keys.filter((item) => {
      return item !== 'categoryId';
    });

    setHardwareDataKeys(res);
  };
  const handleViewModalClose = (e) => {
    setViewAssetData(null);
    setModalOpened(false);
    setHardwareDataKeys(null);
  };
  const handleEditAsset = (assetId) => (e) => {
    const currData = assetsData.find((item) => item._id === assetId);
    console.log('currData', currData);
    setEditAssetData(currData);
    setEditForm(true);
  };
  const handleDeleteModal = (id) => (event) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleDeleteConfirm = async (event) => {
    try {
      const response = await AssetApi.deleteAsset({
        assetId: deleteId,
        userId: user._id,
      });
    } catch (error) {
      if (error.response.status === 500) alert('Error!');
      else if (error.response.status === 403) alert('You do not have access!');
      else alert('ERROR! Asset not Deleted.');
    }
    setDeleteModal(false);
    setDeleteId(null);
    window.location.reload(false);
  };

  const handleDeleteReject = (event) => {
    event.preventDefault();
    setDeleteId(null);
    setDeleteModal(false);
  };
  const handleAssetFilter = (event) => {
    switch (event.target.value) {
      case 'Assigned': {
        const filteredData = dupAssetData.filter(function (item) {
          return item.status === 'Assigned';
        });
        setAssetsData(filteredData);
        break;
      }
      case 'Unassigned': {
        const filteredData = dupAssetData.filter(function (item) {
          return item.status === 'Unassigned';
        });
        setAssetsData(filteredData);
        break;
      }
      case 'Inservice': {
        const filteredData = dupAssetData.filter(function (item) {
          return item.status === 'Inservice';
        });
        setAssetsData(filteredData);
        break;
      }
      case 'Scrapped': {
        const filteredData = dupAssetData.filter(function (item) {
          return item.status === 'Scrapped';
        });
        setAssetsData(filteredData);
        break;
      }
      case 'ALL': {
        setAssetsData(dupAssetData);
        break;
      }
    }
  };
  console.log(viewAssetData);
  console.log(idx);
  return (
    <>
      {editFormOpen ? (
        <HomePageEdit
          formType="edit"
          editFormData={editAssetData}
          editAssetId={editAssetData._id}
          setEditForm={setEditForm}
        />
      ) : (
        <div className="home sidebar-container">
          <Sidebar />
          <div className="homeRight">
            <Navbar pageHeader="Home" />
            {!assetsData ? (
              <div className="unautorizedAccess">
                <h4>Loading Data...</h4>
              </div>
            ) : (
              <>
                <div className="searchbar">
                  <input
                    type="text"
                    value={searchFilter}
                    onChange={handleSearchFilter}
                    style={{
                      fontWeight: 'bold',
                      color: 'black',
                    }}
                    placeholder="Asset ID, Category, Serial Number, etc,."
                  />
                  <img
                    src={searchLogo}
                    style={{ marginLeft: '2rem' }}
                    alt="searchLogo"
                  />
                </div>
                <div className="asset-function-container">
                  <div className="assetFilter">
                    {user.role !== 'ANALYTICS' && (
                      <Link
                        to="../asset-form"
                        style={{ textDecoration: 'none' }}
                      >
                        <div className="add-asset-home">
                          <img
                            src={addOrangeSVG}
                            onClick={() => setModalOpened(true)}
                            className="addOrangeSVG"
                            alt=""
                          />
                        </div>
                      </Link>
                    )}
                    <div>
                      <span style={{ color: '#000000' }}>Status </span>
                      <select
                        name="asssetFilter"
                        id="asssetFilter"
                        // value={assetFilter}
                        style={{
                          backgroundColor: '#1CA0C4',
                          color: 'white',
                          marginLeft: '1rem',
                        }}
                        onChange={handleAssetFilter}
                      >
                        <option default value="ALL">
                          All
                        </option>
                        <option value="Assigned">Assigned</option>
                        <option value="Unassigned">Unassigned</option>
                        <option value="Inservice">Service</option>
                        <option value="Scrapped">Scrapped</option>
                      </select>
                    </div>
                  </div>
                  <div className="print-export">
                    <div className="export">
                      <img
                        src={exportImg}
                        style={{ width: '6rem', cursor: 'pointer' }}
                        onClick={handleExport}
                        alt=""
                      />
                      {/* <button
                        className="home-header-button"
                        style={{background:'transparent', border:'2px solid black',borderLeft:'none', borderRadius:'0', color:'#1CA0C4'}}
                        onClick={handleExport}
                      >
                        Export
                      </button> */}

                      {/* <img src={downArrow} alt="" /> */}
                    </div>
                  </div>
                </div>
                <div className="overflow-table" style={{ width: '86vw' }}>
                  <table
                    // className="assetTable"
                    className="userTable emp-table assetTable"
                  >
                    <tr className="userTable-row-header">
                      <th>
                        <span>S.No.</span>
                      </th>
                      <th>
                        <span>Model</span>
                        <img
                          src={sortSign}
                          className="sort-btn"
                          alt=""
                          onClick={handleSortModel}
                        />
                      </th>
                      <th>
                        <span>Serial Number</span>
                        <img
                          src={sortSign}
                          className="sort-btn"
                          alt=""
                          onClick={handleSortModel}
                        />
                      </th>
                      <th className="home-title">
                        <span>Category</span>
                        <img
                          src={sortSign}
                          className="sort-btn"
                          alt=""
                          onClick={handleSortDealer}
                        />
                      </th>
                      <th className="home-title">
                        <span style={{ marginRight: '0.2rem' }}>
                          Purchase Value
                        </span>
                        (
                        <img
                          src={currecy[idx]}
                          style={{
                            width: '1.3rem',
                            marginBottom: '-0.23rem',
                            marginLeft: '-0.3rem',
                            marginRight: '-0.3rem',
                          }}
                        />
                        )
                        <img
                          src={sortSign}
                          className="sort-btn"
                          alt=""
                          onClick={handleSortDealer}
                        />
                      </th>
                      <th>
                        <span>
                          Purchase Date
                          <img
                            src={sortSign}
                            className="sort-btn"
                            alt=""
                            onClick={handleSortModel}
                          />
                        </span>
                      </th>
                      <th>
                        <span>Status</span>
                        <img
                          src={sortSign}
                          className="sort-btn"
                          alt=""
                          onClick={handleSortStatus}
                        />
                      </th>
                      <th>
                        <span>Employee Email</span>
                        <img
                          src={sortSign}
                          alt=""
                          className="sort-btn"
                          onClick={handleSortAllocated}
                        />
                      </th>
                      <th style={{ textAlign: 'center', paddingRight: '2rem' }}>
                        <span>View</span>
                      </th>
                      <th style={{ textAlign: 'center', paddingRight: '2rem' }}>
                        <span>Edit</span>
                      </th>
                      <th style={{ textAlign: 'center', paddingRight: '2rem' }}>
                        <span>Delete</span>
                      </th>
                    </tr>
                    {assetsData?.map((asset, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{asset.model}</td>
                        <td>{asset.serialNum}</td>
                        <td>{asset.hardwareData.categoryName}</td>
                        {/* <td>{asset.manufacturer}</td> */}
                        <td>
                          {numberFormat
                            ? numberConverter.formatIndian(asset.purchaseValue)
                            : numberConverter.formatInternational(
                                asset.purchaseValue
                              )}
                        </td>

                        <td>
                          {dateConverter.convertToDateFormat(
                            asset.purchaseDate,
                            dateFormat
                          )}
                        </td>
                        <td>{asset.status}</td>

                        <td>
                          {asset.status === 'Unassigned'
                            ? 'N/A'
                            : asset.currentUserEmail}
                        </td>
                        <td>
                          {/* <button
                            onClick={handleViewAsset(asset._id)}
                            className="view-button"
                          >
                            View
                          </button> */}
                          <img
                            src={View}
                            onClick={handleViewAsset(asset._id)}
                            style={{
                              width: '60px',
                              marginRight: '1.2rem',
                              cursor: 'pointer',
                            }}
                            alt=""
                          />
                        </td>
                        <td>
                          {/* <button
                            onClick={handleEditAsset(asset._id)}
                            className="userTable-edit"
                          >
                            Edit
                          </button> */}
                          <img
                            src={Edit}
                            onClick={handleEditAsset(asset._id)}
                            style={{
                              width: '60px',
                              marginRight: '1.2rem',
                              cursor: 'pointer',
                            }}
                            alt=""
                          />
                        </td>
                        <td>
                          {/* <button
                            onClick={handleDeleteModal(asset._id)}
                            className="userTable-delete"
                          >
                            Delete
                          </button> */}
                          <img
                            src={Delete}
                            onClick={handleDeleteModal(asset._id)}
                            style={{
                              width: '60px',
                              marginRight: '1.2rem',
                              cursor: 'pointer',
                            }}
                            alt=""
                          />
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>

                <Modal
                  isOpen={modalOpen}
                  onRequestClose={() => setModalOpened(false)}
                >
                  <form className="formModal">
                    <h2>{viewAssetData?.model}</h2>

                    <div>
                      <div className="formSection-header view-asset-header">
                        <span>Basic Information</span>
                      </div>
                      <div className="formSection-one grid">
                        <div>
                          <p className="view-asset-label"> Description</p>
                          <p className="view-asset-text">
                            {viewAssetData?.description}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Document</p>
                          <p className="view-asset-text">
                            <button style={{margin:'0', padding:'0.2rem'}}><a href={`${process.env.REACT_APP_BASE_URL}/asset/${user._id}/getimage/${viewAssetData?._id}`} style={{textDecoration:'none', color:'white'}} target='_blank'>View Document</a></button>
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Model</p>
                          <p className="view-asset-text">
                            {viewAssetData?.model}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Serial Number</p>
                          <p className="view-asset-text">
                            {viewAssetData?.serialNum}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Status</p>
                          <p className="view-asset-text">
                            {viewAssetData?.status}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="formSection-header view-asset-header">
                        <span>Purchase Details</span>
                      </div>
                      <div className="formSection-one grid">
                        <div>
                          <p className="view-asset-label"> Purchase Date</p>
                          <p className="view-asset-text">
                            {viewAssetData &&
                              dateConverter.convertToDateFormat(
                                viewAssetData.purchaseDate,
                                dateFormat
                              )}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Purchase Value</p>
                          <p className="view-asset-text">
                            {numberFormat
                              ? viewAssetData &&
                                numberConverter.formatIndian(
                                  viewAssetData.purchaseValue
                                )
                              : viewAssetData &&
                                numberConverter.formatInternational(
                                  viewAssetData.purchaseValue
                                )}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Manufacturer</p>
                          <p className="view-asset-text">
                            {viewAssetData?.model}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label">Warranty Period</p>
                          <p className="view-asset-text">
                            {viewAssetData?.warrantyPeriod}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Dealer Name</p>
                          <p className="view-asset-text">
                            {viewAssetData?.dealerName}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Dealer Number</p>
                          <p className="view-asset-text">
                            {viewAssetData?.dealerNumber}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label"> Dep Rate(Tax)</p>
                          <p className="view-asset-text">
                            {viewAssetData?.depRateTax}
                          </p>
                        </div>
                        <div>
                          <p className="view-asset-label">Dep Rate(Other)</p>
                          <p className="view-asset-text">
                            {viewAssetData?.depRateOther}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="formSection-header view-asset-header">
                        <span>Hardware Details</span>
                      </div>
                      <div className="formSection-one grid">
                        {hardwareDataKeys?.map((item, index) => (
                          <div>
                            <p className="view-asset-label">
                              {' '}
                              {item === 'categoryName' ? 'Category Name' : item}
                            </p>
                            <p className="view-asset-text">
                              {viewAssetData?.hardwareData[item] === ''
                                ? '---'
                                : viewAssetData?.hardwareData[item]}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="formSection-header view-asset-header">
                        <span>Service & Repair</span>
                      </div>
                      <div className="formSection-one table">
                        {viewAssetData?.serviceArr.length > 0 ? (
                          <table className="userTable computerForm-table">
                            <tr computerForm-row-header>
                              <th>Service Date</th>
                              <th>Service Cost</th>
                              <th>Warranty</th>
                              <th>Description</th>
                              <th>Document</th>
                            </tr>
                            {viewAssetData?.serviceArr.map((item, index) => (
                              <tr>
                                <td>{item.serviceDate}</td>
                                <td>{item.serviceCost}</td>
                                <td>{item.serviceWarranty}</td>
                                <td>{item.serviceDesc}</td>
                                <td>Document</td>
                              </tr>
                            ))}
                          </table>
                        ) : (
                          <p>No Service History </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="formSection-header view-asset-header">
                        <span>Allocation Details</span>
                      </div>
                      <div className="formSection-one table">
                        {viewAssetData?.allocationHist.length > 0 ? (
                          <table className="userTable computerForm-table">
                            <tr computerForm-row-header>
                              <th>Emp ID</th>
                              <th>Name</th>
                              <th>Department</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Location</th>
                              <th>Allocation Date</th>
                              <th>Deallocation Date</th>
                            </tr>
                            {viewAssetData?.allocationHist.map(
                              (item, index) => (
                                <tr>
                                  <td>{item.empId}</td>
                                  <td>{item.name}</td>
                                  <td>{item.department}</td>
                                  <td>{item.email}</td>
                                  <td>{item.phone}</td>
                                  <td>{item.location}</td>
                                  <td>{item.allocationDate}</td>
                                  <td>
                                    {item.deallocationDate === '' ? (
                                      <td
                                        style={
                                          {
                                            // paddingLeft: "100%",
                                            // paddingRight: "100%",
                                            // textAlign: "center",
                                            // alignSelf: "center",
                                          }
                                        }
                                      ></td>
                                    ) : (
                                      item.deallocationDate
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </table>
                        ) : (
                          <p>No Allocation History</p>
                        )}
                      </div>
                    </div>

                    <div className="modal-buttons">
                      <button
                        type="button"
                        className="closeModal-button"
                        onClick={handleViewModalClose}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </Modal>
                <Modal
                  isOpen={deleteModal}
                  onRequestClose={() => setDeleteModal(false)}
                  style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    },
                    content: {
                      position: 'absolute',
                      top: '25%',
                      left: '25%',
                      right: '25%',
                      bottom: '25%',
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'auto',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '40px',
                      outline: 'none',
                      padding: '2rem',
                    },
                  }}
                >
                  <div className="deleteModal">
                    <h1>Are you sure you want to delete?</h1>
                    <div className="deleteModal-btn">
                      <button
                        type="submit"
                        className="userTable-edit"
                        onClick={handleDeleteConfirm}
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        className="userTable-delete"
                        onClick={handleDeleteReject}
                      >
                        NO
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
