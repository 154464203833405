/* eslint-disable */
import React from 'react';
import './DepreciationByAsset.css';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Calendar from '../../img/Calendar.svg';
import DropDown from '../../img/DropDown.svg';
import Settings from '../../img/Settings.svg';
import PrinterLogo from '../../img/Print.svg';
import * as FileSaver from 'file-saver';
import sortSign from '../../img/sortSign.svg';
import { useSelector } from 'react-redux';
import * as AssetApi from '../../api/AssetRequest';
import * as CompanyApi from '../../api/CompanyRequest';
import * as numberConverter from '../../Utils/numberFormatConverter';
import * as dateConverter from '../../Utils/dateFormat';
import XLSX from 'sheetjs-style';
import INR from '../../img/currencies/INR.png';
import USD from '../../img/currencies/USD.png';
import exportImg from '../../img/export.svg';

const DepreciationByAsset = (props) => {
  // use-selector
  const user = useSelector((state) => state.authReducer.authData?.userData);

  //use-states
  const currecy = [INR, USD];
  const [idx, setIdx] = React.useState();
  const [date, setDate] = React.useState('Today');
  const [calenderDate, setCalenderDate] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [numberFormat, setNumberFormat] = React.useState(false);
  const [dateFormat, setDateFormat] = React.useState(false);
  const [exportData, setExportData] = React.useState();
  const [sortBySerialNum, setsortBySerialNum] = React.useState(false);
  const [sortByPurchaseValue, setsortByPurchaseValue] = React.useState(false);
  const [sortByPurchaseDate, setSortByPurchaseDate] = React.useState(false);
  const [sortByStatus, setSortByStatus] = React.useState(false);
  const [sortByDepRate, setsortByDepRate] = React.useState(false);
  const [sortByAssetName, setSortByAssetName] = React.useState(false);
  const [depArray, setDepArray] = React.useState();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  //function
  const sliceData = (data) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  //regular variables
  let cnt = 0;
  const exportableData = [];
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  React.useEffect(() => {
    const getCompnay = async () => {
      const response = await CompanyApi.getData(user._id);
      if (response.data.numberFormat === 'indian') {
        setNumberFormat(true);
      }
      if (response.data.dateFormat === 'dd-mm-yyyy') {
        setDateFormat(true);
      }
      setIdx(
        currecy.findIndex((item) => {
          return item.toString().includes(response.data.currency);
        })
      );
    };
    getCompnay();
  }, []);
  React.useEffect(() => {
    const getData = async () => {
      const response = await AssetApi.depByAssetReport(user._id);
      setExportData(response.data);
    };
    getData();
  }, []);
  console.log('exportData', exportData);
  //fuctions
  const handleExport = async () => {
    const exportableArray = [];
    exportData.assets.map((item, idx) => {
      const depObj = exportData.depData[idx];
      const exportableObj = {
        SrNo: idx + 1,
        AssetName: item.model,
        SerialNumber: item.serialNumber,
        Manufactorer: item.manufacturer,
        PurchaseDate: item.purchaseDate,
        PurchaseValue: item.purchaseValue,
        DepreciationRateTax: item.depRateTax,
        'Depreciation (2022-2023)': depObj[0].depreciation,
        'WDV (2022-2023)': depObj[0].WDV,
      };
      if (date === 'Last Year' || date === 'Last 3 Years') {
        exportableObj['Depreciation (2021-2022)'] = depObj[0].depreciation;
        exportableObj['WDV (2021-2022)'] = depObj[0].WDV;
      }
      if (date === 'Last 3 Years') {
        exportableObj['Depreciation (2020-2021)'] = depObj[0].depreciation;
        exportableObj['WDV (2020-2021)'] = depObj[0].WDV;
      }
      exportableArray.push(exportableObj);
    });
    const ws = XLSX.utils.json_to_sheet(exportableArray);
    const cellStyle = {
      alignment: { horizontal: 'center' },
      width: 500,
    };
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
        ws[cellAddress].s = cellStyle;
      }
    }
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    console.log(data);
    FileSaver.saveAs(data, 'AssetReport' + fileExtension);
  };

  // sorting functions
  const handleSortBySerialNum = () => {
    if (!sortBySerialNum) {
      const sortedData = exportData.assets.sort(
        (a, b) => a.serialNum - b.serialNum
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    } else {
      const sortedData = exportData.assets.sort(
        (a, b) => b.serialNum - a.serialNum
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    }
    setsortBySerialNum(!sortBySerialNum);
  };

  const handleSortByPurchaseDate = () => {
    if (!sortByPurchaseDate) {
      const sortedData = exportData.assets.sort(
        (a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate)
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    } else {
      const sortedData = exportData.assets.sort(
        (a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate)
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    }
    setSortByPurchaseDate(!sortByPurchaseDate);
  };

  const handleSortByPurchaseValue = () => {
    if (!sortByPurchaseValue) {
      const sortedData = exportData.assets.sort((a, b) =>
          a.purchaseValue - b.purchaseValue
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    } else {
      const sortedData = exportData.assets.sort((a, b) =>
      b.purchaseValue - a.purchaseValue
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    }
    setsortByPurchaseValue(!sortByPurchaseValue);
  };

  const handleSortByStatus = () => {
    if (!sortByStatus) {
      const sortedData = exportData.assets.sort((a, b) =>
        a.status.localeCompare(b.status)
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    } else {
      const sortedData = exportData.assets.sort((a, b) =>
        b.status.localeCompare(a.status)
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    }
    setSortByStatus(!sortByStatus);
  };

  const handleSortByDepRate = () => {
    if (!sortByDepRate) {
      const sortedData = exportData.assets.sort(
        (a, b) => a.depRateTax - b.depRateTax
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    } else {
      const sortedData = exportData.assets.sort(
        (a, b) => b.depRateTax - a.depRateTax
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    }
    setsortByDepRate(!sortByDepRate);
  };

  const handleSortByAssetName = () => {
    if (!sortByAssetName) {
      const sortedData = exportData.assets.sort((a, b) =>
        a.model.localeCompare(b.model)
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    } else {
      const sortedData = exportData.assets.sort((a, b) =>
        b.model.localeCompare(a.model)
      );
      exportData.assets = sortedData;
      setExportData({ ...exportData });
    }
    setSortByAssetName(!sortByAssetName);
  };

  if (exportData) {
    return (
      <>
        <div className="da-reportProfile">
          <Sidebar />
          <div className="da-navAndBody">
            <Navbar pageHeader={props.header} />
            <div className="da-pageHeader">
              <div className="da-date-picker">
                <div className="da-dropdown">
                  <button className="da-dropbtn">
                    <img src={Calendar} />
                    {date}
                    <img src={DropDown} />{' '}
                  </button>
                  <div className="da-dropdown-content">
                    <a
                      onClick={() => {
                        setDate('Today');
                      }}
                    >
                      Today
                    </a>
                    <a
                      onClick={() => {
                        setDate('Last Year');
                      }}
                    >
                      Last Year
                    </a>
                    <a
                      onClick={() => {
                        setDate('Last 3 Years');
                      }}
                    >
                      Last 3 Years
                    </a>
                    <a
                      style={{
                        color: '#1167b1',
                        borderTop: '1px solid lightgray',
                      }}
                      onClick={() => {
                        setDate('Choose');
                      }}
                    >
                      <label style={{ marginRight: '4px' }}>Choose</label>
                      <input
                        value={calenderDate}
                        onChange={(e) => setCalenderDate(e.target.value)}
                        type="date"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="da-printOrExport">
              <img src={exportImg} style={{width:'6rem', cursor:'pointer', marginRight:'1rem'}} onClick={handleExport} alt="" />
              </div>
            </div>
            <div className="da-headline">
              <div>{props.header}</div>
              <div>{props.company}</div>
              {date === 'Today' && (
                <div>{dateConverter.getToday(dateFormat)}</div>
              )}
              {date === 'Last Year' && (
                <div>{dateConverter.getLastYear(dateFormat)}</div>
              )}
              {date === 'Last 3 Years' && (
                <div>{dateConverter.getLastThreeYears(dateFormat)}</div>
              )}
              {date === 'Choose' && (
                <div>
                  {dateConverter.convertToDateFormat(calenderDate, dateFormat)}
                </div>
              )}
            </div>
            <div className="daTable">
              <div className="da-ColContent" id="da-snoCol">
                <div className="da-colHeader">S.No</div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {index + 1}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Asset Name
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    onClick={handleSortByAssetName}
                    style={{ height: '0.8rem' }}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {item.model}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Serial Number{' '}
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    onClick={handleSortBySerialNum}
                    style={{ height: '0.8rem' }}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {item.serialNum}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Manufacturer{' '}
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    style={{ height: '0.8rem' }}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {item.manufacturer}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Status
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    onClick={handleSortByStatus}
                    style={{ height: '0.8rem' }}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {item.status}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Purchase Date{' '}
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    style={{ height: '0.8rem' }}
                    onClick={handleSortByPurchaseDate}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {dateConverter.convertToDateFormat(
                          item.purchaseDate,
                          dateFormat
                        )}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Purchase Value{' '}
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    onClick={handleSortByPurchaseValue}
                    style={{ height: '0.8rem' }}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {' '}
                        {numberFormat
                          ? numberConverter.formatIndian(item.purchaseValue)
                          : numberConverter.formatInternational(
                              item.purchaseValue
                            )}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  Dep Rate (tax){' '}
                  <img
                    src={sortSign}
                    className="da-sort-btn"
                    onClick={handleSortByDepRate}
                    style={{ height: '0.8rem' }}
                    alt=""
                  />
                </div>
                {exportData &&
                  exportData.assets.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {item.depRateTax ? item.depRateTax : 0}%
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  <div className="yearSecret">{currentYear - 1}-</div>
                  <div className="capitalServiceCosts">Depreciation</div>
                </div>
                {exportData &&
                  exportData.depData.map((item, index) => {
                    console.log(item);
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {numberFormat
                          ? numberConverter.formatIndian(item[0].depreciation)
                          : numberConverter.formatInternational(
                              item[0].depreciation
                            )}
                      </div>
                    );
                  })}
              </div>
              <spam className="da-vr"></spam>
              <div className="da-ColContent">
                <div className="da-colHeader">
                  <div className="yearSecret2">{currentYear}</div>
                  <div className="capitalServiceCosts">WDV</div>
                </div>
                {exportData &&
                  exportData.depData.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 ? 'BG1 da-colBody' : 'BG2 da-colBody'
                        }
                      >
                        {numberFormat
                          ? numberConverter.formatIndian(item[0].WDV)
                          : numberConverter.formatInternational(item[0].WDV)}
                      </div>
                    );
                  })}
              </div>
              {(date === 'Last Year' || date === 'Last 3 Years') && (
                <>
                  <spam className="da-vr"></spam>{' '}
                  <div className="da-ColContent">
                    <div className="da-colHeader">
                      <div className="yearSecret">{currentYear - 2}-</div>
                      <div className="capitalServiceCosts">Depreciation</div>
                    </div>
                    {exportData &&
                      exportData.depData.map((item, index) => {
                        return (
                          <div
                            className={
                              index % 2 === 0
                                ? 'BG1 da-colBody'
                                : 'BG2 da-colBody'
                            }
                          >
                            {numberFormat
                              ? numberConverter.formatIndian(
                                  item[0].depreciation
                                )
                              : numberConverter.formatInternational(
                                  item[0].depreciation
                                )}
                          </div>
                        );
                      })}
                  </div>
                  <spam className="da-vr"></spam>
                  <div className="da-ColContent">
                    <div className="da-colHeader">
                      <div className="yearSecret2">{currentYear - 1}</div>
                      <div className="capitalServiceCosts">WDV</div>
                    </div>
                    {exportData &&
                      exportData.depData.map((item, index) => {
                        return (
                          <div
                            className={
                              index % 2 === 0
                                ? 'BG1 da-colBody'
                                : 'BG2 da-colBody'
                            }
                          >
                            {numberFormat
                              ? numberConverter.formatIndian(item[0].WDV)
                              : numberConverter.formatInternational(
                                  item[0].WDV
                                )}
                          </div>
                        );
                      })}
                  </div>
                  <spam className="da-vr"></spam>
                </>
              )}
              {date === 'Last 3 Years' && (
                <>
                  {' '}
                  <div className="da-ColContent ">
                    <div className="da-colHeader">
                      <div className="yearSecret">{currentYear - 3}-</div>
                      <div className="capitalServiceCosts">Depreciation</div>
                    </div>
                    {exportData &&
                      exportData.depData.map((item, index) => {
                        console.log(item);
                        return (
                          <div
                            className={
                              index % 2 === 0
                                ? 'BG1 da-colBody'
                                : 'BG2 da-colBody'
                            }
                          >
                            {numberFormat
                              ? numberConverter.formatIndian(
                                  item[0].depreciation
                                )
                              : numberConverter.formatInternational(
                                  item[0].depreciation
                                )}
                          </div>
                        );
                      })}
                  </div>
                  <spam className="da-vr"></spam>
                  <div className="da-ColContent">
                    <div className="da-colHeader">
                      <div className="yearSecret2">{currentYear - 2}</div>
                      <div className="capitalServiceCosts">WDV</div>
                    </div>
                    {exportData &&
                      exportData.depData.map((item, index) => {
                        return (
                          <div
                            className={
                              index % 2 === 0
                                ? 'BG1 da-colBody'
                                : 'BG2 da-colBody'
                            }
                          >
                            {numberFormat
                              ? numberConverter.formatIndian(item[0].WDV)
                              : numberConverter.formatInternational(
                                  item[0].WDV
                                )}
                          </div>
                        );
                      })}
                  </div>
                  <spam className="da-vr"></spam>
                </>
              )}
              <spam className="da-vr"></spam>
            </div>
          </div>
        </div>
        {/* 
        <div className="da-pagination-controls">
          <div className="da-inner-div-pageController">
            <label>Show:</label>
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <button onClick={() => setCurrentPage(1)}>First</button>
            <button
              onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            >
              Prev
            </button>
            <button
              onClick={() =>
                setCurrentPage(
                  Math.min(
                    Math.ceil(exportData.length / itemsPerPage),
                    currentPage + 1
                  )
                )
              }
            >
              Next
            </button>
            <button
              onClick={() =>
                setCurrentPage(Math.ceil(exportData.length / itemsPerPage))
              }
            >
              Last
            </button>
            <label>
              Page {currentPage} of{' '}
              {Math.ceil(exportData.length / itemsPerPage)}
            </label>
          </div>
        </div> */}
      </>
    );
  } else {
    return (
      <div className="da-unautorizedAccess">
        <h4>Loading Data...</h4>
      </div>
    );
  }
};

export default DepreciationByAsset;
