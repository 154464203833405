import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as UserApi from '../../../api/UserRequest';
import Navbar from '../../Navbar/Navbar';
import Sidebar from '../../Sidebar/Sidebar';
import LockImg from '../../../img/LockImg.svg';
import './SuperUserDetails.css';
import Delete from '../../../img/delete.svg';
import * as API from '../../../api/UserRequest'
const SuperUserDetails = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [userDetails, setUserDetails] = useState([]);
  let cnt = 0;
  const deleteUser = async(data) => {
    try{
      const res = await API.deleteUserBySuperAdmin(user._id,data);
      console.log(res.data);
      alert("User Deleted Successfully");
    }catch(err){
      console.log(err);
    }

  }
  const resetPassword = async(data) => {
    try{
      const res = await API.resetPassword(user._id,data);
      console.log(res.data);
      alert("Password reset Successfully");
    }catch(err){
      console.log(err);
    }

  }
  const handleDelete = (e,data) => {
    e.preventDefault();
    if(window.confirm("Are you sure you want to delete this user?")){
         const sendData = {userId:data}
        deleteUser(sendData);
    }
  }

  const handleReset = (e,data) => {
    e.preventDefault();
    const newPassword = prompt("Please enter a new password");
    if(window.confirm("Are you sure you want to save the new password?")){
        const sendData = {userId:data,newPassword:newPassword};
        resetPassword(sendData);
    }
  }
  useEffect(() => {
    const fetchUsers = async () => {
      const res = await UserApi.geUsersForSuperAdmin(user._id);
      console.log(res.data);
      setUserDetails(res.data);
    };
    fetchUsers();
  },[]);
  if (user.role !== 'SUPER ADMIN') {
    return (
      <h1
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Access Forbidden
      </h1>
    );
  } else {
    return (
      <div className="userProfile">
        <Sidebar />
        <div className="">
          <Navbar pageHeader="Tenie ALM Super Admin | User Details" />
          <div className="titleBar">
            <div id='sNo'>S No.</div>
            <div>User Name</div>
            <div>Company</div>
            <div>Role</div>
            <div>Last Accessed</div>
            <div className="containIcons">Reset Password</div>
            <div className="containIcons">Delete</div>
          </div>
          <div Name="userDetails">
            {userDetails &&
              userDetails.map((user) => {
                cnt++;
                return (
                  <div className={cnt % 2 === 0 ? 'even row' : 'odd row'}>
                    <div id='sNoData'>{cnt}</div>
                    <div>{user.name}</div>
                    <div>{user.companyName}</div>
                    <div>
                      {user.role.slice(0, 1).toUpperCase()}
                      {user.role.slice(1).toLowerCase()}
                    </div>
                    <div>{user.lastAccessed}</div>
                    <div className="containIcons">
                      <img src={LockImg} onClick={(event) => handleReset(event,user._id)} alt="reset password" srcSet="" />
                    </div>
                    <div className="containIcons">
                      <img src={Delete} onClick={(event) => handleDelete(event,user._id)} alt="delete" srcSet="" />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
};

export default SuperUserDetails;
