/* eslint-disable */
import React from 'react';
import './DepreciationSummary.css';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Calendar from '../../img/Calendar.svg';
import DropDown from '../../img/DropDown.svg';
import Settings from '../../img/Settings.svg';
import PrinterLogo from '../../img/Print.svg';
import sortSign from '../../img/sortSign.svg';
import { useSelector } from 'react-redux';
import * as AssetApi from '../../api/AssetRequest';
import * as CompanyApi from '../../api/CompanyRequest';
import * as numberConverter from '../../Utils/numberFormatConverter';
import * as dateConverter from '../../Utils/dateFormat';
import XLSX from 'sheetjs-style';
import INR from '../../img/currencies/INR.png';
import USD from '../../img/currencies/USD.png';
import exportImg from '../../img/export.svg';

const ReportDetails = (props) => {
  // use-selector
  const user = useSelector((state) => state.authReducer.authData?.userData);

  //use-states
  const currecy = [INR, USD];
  const [idx, setIdx] = React.useState();
  const [date, setDate] = React.useState('Today');
  const [calenderDate, setCalenderDate] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [numberFormat, setNumberFormat] = React.useState(false);
  const [dateFormat, setDateFormat] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const [sortByCapitalCost, setSortByCapitalCost] = React.useState(false);
  const [sortByCategory, setSortByCategory] = React.useState(false);
  const [sortByStatus, setSortByStatus] = React.useState(false);
  const [sortByCount, setSortByCount] = React.useState(false);
  const [sortByRepair, setSortByRepair] = React.useState(false);
  const [depArray, setDepArray] = React.useState();

  //function
  const sliceData = (data) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  //regular variables
  let cnt = 0;
  const exportableData = [];
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  //use-effects
  React.useEffect(() => {
    const getCategories = async () => {
      const response = await AssetApi.getAllAssets(user._id);
      const dataByCategory = response.data.reduce((acc, cur) => {
        const category = cur.hardwareData.categoryName;
        const status = cur.status;
        if (!acc[category]) {
          acc[category] = {};
        }
        if (!acc[category][status]) {
          acc[category][status] = [];
        }
        acc[category][status].push(cur);
        return acc;
      }, {});

      Object.entries(dataByCategory).map(([category, item]) => {
        Object.entries(item).map(([status, info]) => {
          let capitalCost = 0;
          let serviceCost = 0;
          let scrappedValue = 0;
          info.map((data) => {
            if (data.purchaseValue) capitalCost += parseInt(data.purchaseValue);
            if (data.scrapValue) scrappedValue += parseInt(data.scrapValue);
            data.serviceArr.map((service) => {
              serviceCost += parseInt(service.serviceCost);
            });
          });
          exportableData.push({
            Category: category,
            Status: status,
            Count: info.length,
            CapitalCost: capitalCost,
            ServiceCost: serviceCost,
            ScrappedValue: scrappedValue,
          });
        });
      });

      setExportData([...exportableData]);
    };
    getCategories();
  }, []);
  React.useEffect(() => {
    const depCalc = async () => {
      const arr = [];
      for (let i = 0; i < exportData.length; ++i) {
        const res = await AssetApi.depSummaryReport({
          Category: exportData[i].Category,
          Status: exportData[i].Status,
          id: user.companyId,
        });
        arr.push(res.data);
      }
      setDepArray(arr);
    };
    if (exportData.length > 0) {
      depCalc();
    }
  }, [exportData]);
  React.useEffect(() => {
    const getCompnay = async () => {
      const response = await CompanyApi.getData(user._id);
      if (response.data.numberFormat === 'indian') {
        setNumberFormat(true);
      }
      if (response.data.dateFormat === 'dd-mm-yyyy') {
        setDateFormat(true);
      }
      setIdx(
        currecy.findIndex((item) => {
          return item.toString().includes(response.data.currency);
        })
      );
    };
    getCompnay();
  }, []);

  //fuctions
  const handleExport = async () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'AssetReport' + fileExtension);
  };

  // sorting functions
  const handleSortByCapitalCost = () => {
    if (!sortByCapitalCost) {
      const sortedData = exportData.sort(
        (a, b) => a.CapitalCost - b.CapitalCost
      );
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort(
        (a, b) => b.CapitalCost - a.CapitalCost
      );
      setExportData(sortedData);
    }
    setSortByCapitalCost(!sortByCapitalCost);
  };

  const handleSortByCategory = () => {
    if (!sortByCategory) {
      const sortedData = exportData.sort((a, b) =>
        a.Category.localeCompare(b.Category)
      );
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort((a, b) =>
        b.Category.localeCompare(a.Category)
      );
      setExportData(sortedData);
    }
    setSortByCategory(!sortByCategory);
  };

  const handleSortByStatus = () => {
    if (!sortByStatus) {
      const sortedData = exportData.sort((a, b) =>
        a.Status.localeCompare(b.Status)
      );
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort((a, b) =>
        b.Status.localeCompare(a.Status)
      );
      setExportData(sortedData);
    }
    setSortByStatus(!sortByStatus);
  };

  const handleSortByCount = () => {
    if (!sortByCount) {
      const sortedData = exportData.sort((a, b) => a.Count - b.Count);
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort((a, b) => b.Count - a.Count);
      setExportData(sortedData);
    }
    setSortByCount(!sortByCount);
  };

  const handleSortByRepair = () => {
    if (!sortByRepair) {
      const sortedData = exportData.sort(
        (a, b) => a.ServiceCost - b.ServiceCost
      );
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort(
        (a, b) => b.ServiceCost - a.ServiceCost
      );
      setExportData(sortedData);
    }
    setSortByRepair(!sortByRepair);
  };

  if (exportData) {
    return (
      <>
        <div className="ds-reportProfile">
          <Sidebar />
          <div className="ds-">
            <Navbar pageHeader={props.header} />
            <div className="ds-pageHeader">
              <div className="ds-date-picker">
                <div className="ds-dropdown">
                  <button className="ds-dropbtn">
                    <img src={Calendar} />
                    {date}
                    <img src={DropDown} />{' '}
                  </button>
                  <div className="ds-dropdown-content">
                    <a
                      onClick={() => {
                        setDate('Today');
                      }}
                    >
                      Today
                    </a>
                    <a
                      onClick={() => {
                        setDate('Last Month');
                      }}
                    >
                      Last Month
                    </a>
                    <a
                      onClick={() => {
                        setDate('Last Year');
                      }}
                    >
                      Last Year
                    </a>
                    <a
                      style={{
                        color: '#1167b1',
                        borderTop: '1px solid lightgray',
                      }}
                      onClick={() => {
                        setDate('Choose');
                      }}
                    >
                      <label style={{ marginRight: '4px' }}>Choose</label>
                      <input
                        value={calenderDate}
                        onChange={(e) => setCalenderDate(e.target.value)}
                        type="date"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="ds-customize">
                <img src={Settings} />
                Customize Report
              </div> */}
              <div className="ds-printOrExport">
                <img
                  src={exportImg}
                  style={{
                    width: '6rem',
                    cursor: 'pointer',
                    marginRight: '1rem',
                  }}
                  onClick={handleExport}
                  alt=""
                />
              </div>
            </div>
            <div className="ds-headline">
              <div>{props.header}</div>
              <div>{props.company}</div>
              {date === 'Today' && (
                <div>{dateConverter.getToday(dateFormat)}</div>
              )}
              {date === 'Last Month' && (
                <div>{dateConverter.getLastMonth(dateFormat)}</div>
              )}
              {date === 'Last Year' && (
                <div>{dateConverter.getLastYear(dateFormat)}</div>
              )}
              {date === 'Choose' && (
                <div>
                  {dateConverter.convertToDateFormat(calenderDate, dateFormat)}
                </div>
              )}
            </div>
            <div className="ds-table-header">
              <div className="ds-SNo">
                S.No.
                <img src={sortSign} className="ds-sort-btn" alt="" />
              </div>
              <spam className="ds-vr"></spam>
              <div>
                Category
                <img
                  src={sortSign}
                  onClick={handleSortByCategory}
                  className="ds-sort-btn"
                  alt=""
                />
              </div>
              <spam className="ds-vr"></spam>
              <div>
                Status
                <img
                  src={sortSign}
                  onClick={handleSortByStatus}
                  className="ds-sort-btn"
                  alt=""
                />
              </div>
              <spam className="ds-vr"></spam>
              <div>
                No. of Assets
                <img
                  src={sortSign}
                  onClick={handleSortByCount}
                  className="ds-sort-btn"
                  alt=""
                />
              </div>
              <spam className="ds-vr"></spam>
              <div>
                Capital Cost (
                <img
                  src={currecy[idx]}
                  style={{
                    width: '1.3rem',
                    height: '1rem',
                    marginLeft: '-3rem',
                    marginRight: '-3rem',
                  }}
                />
                )
                <img
                  src={sortSign}
                  onClick={handleSortByCapitalCost}
                  className="ds-sort-btn"
                  alt=""
                />
              </div>
              <spam className="ds-vr"></spam>
              <div>
                Repair & Service Cost
                <img
                  src={sortSign}
                  onClick={handleSortByRepair}
                  className="ds-sort-btn"
                  alt=""
                />
              </div>
              <spam className="ds-vr"></spam>
              <div id="year">
                <div id="upper">2023-2024</div>
                <div id="lower">
                  <div>Depreciation</div>
                  <spam className="ds-vr"></spam>
                  <div>WDV</div>
                </div>
              </div>
              <spam className="ds-vr"></spam>
              <div id="year">
                <div id="upper">2024-2025</div>
                <div id="lower">
                  <div>Depreciation</div>
                  <spam className="ds-vr"></spam>
                  <div>WDV</div>
                </div>
              </div>
            </div>
            {depArray &&
              sliceData(
                exportData.map((data, index) => {
                  const depData = depArray[index];
                  cnt++;
                  return (
                    <div
                      className={
                        cnt % 2 === 0
                          ? 'ds-table-content BG2'
                          : 'ds-table-content BG1'
                      }
                    >
                      <div className="ds-SNo">{cnt}</div>
                      <div>{data.Category}</div>
                      <div>{data.Status}</div>
                      <div>{data.Count}</div>
                      <div>
                        {numberFormat
                          ? numberConverter.formatIndian(data.CapitalCost)
                          : numberConverter.formatInternational(
                              data.CapitalCost
                            )}
                      </div>
                      <div>
                        {numberFormat
                          ? numberConverter.formatIndian(data.ServiceCost)
                          : numberConverter.formatInternational(
                              data.ServiceCost
                            )}
                      </div>
                      <div id="lower">
                        <div>
                          {numberFormat
                            ? numberConverter.formatIndian(
                                depData[0].depreciation
                              )
                            : numberConverter.formatInternational(
                                depData[0].depreciation
                              )}
                        </div>
                        <div>
                          {numberFormat
                            ? numberConverter.formatIndian(depData[0].WDV)
                            : numberConverter.formatInternational(
                                depData[0].WDV
                              )}
                        </div>
                      </div>
                      <div id="lower">
                        <div>-</div>
                        <div>-</div>
                      </div>
                    </div>
                  );
                })
              )}
          </div>
        </div>
        {/* 
        <div className="ds-pagination-controls">
          <div className="ds-inner-div-pageController">
            <label>Show:</label>
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <button onClick={() => setCurrentPage(1)}>First</button>
            <button
              onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            >
              Prev
            </button>
            <button
              onClick={() =>
                setCurrentPage(
                  Math.min(
                    Math.ceil(exportData.length / itemsPerPage),
                    currentPage + 1
                  )
                )
              }
            >
              Next
            </button>
            <button
              onClick={() =>
                setCurrentPage(Math.ceil(exportData.length / itemsPerPage))
              }
            >
              Last
            </button>
            <label>
              Page {currentPage} of{' '}
              {Math.ceil(exportData.length / itemsPerPage)}
            </label>
          </div>
        </div> */}
      </>
    );
  } else {
    return (
      <div className="ds-unautorizedAccess">
        <h4>Loading Data...</h4>
      </div>
    );
  }
};

export default ReportDetails;
