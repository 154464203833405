import axios from 'axios';

const API = axios.create({
  //baseURL: `${process.env.REACT_APP_BASE_URL}`,
   baseURL: `https://alm.tenie.co`,
});

export const getAllAssets = (userId) =>
  API.get(`/asset/${userId}/getAllAssets`);

export const createAsset = async (formData) => {
  return await API.post('/asset/createAsset', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const editAsset = (formData) => API.post('/asset/updateAsset', formData);

export const deleteAsset = (formData) =>
  API.post('/asset/deleteAsset', formData);

export const removeDeallocatedAssets = () => API.get('/asset/remove');

export const depSummaryReport = (formData) =>
  API.post('/asset/depSummaryReport', formData);

export const depByAssetReport = (formData) =>
  API.get(`/asset/${formData}/depByAssetReport`);

export const depByAssetForcastReport = (formData) =>
  API.get(`/asset/${formData}/depByAssetForcastReport`);
