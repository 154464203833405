import { useState } from "react";
import downArrow from "../../img/downArrow.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SidebarItem({ item }) {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [open, setOpen] = useState(false);

  if (item.childrens) {
    return (
      <>
        {user?.role === "MANAGER" || user?.role === "ADMIN" ? (
          <div className={open ? "sidebar-item open" : "sidebar-item"}>
            <div className="sidebar-title">
              <span>
                {item.icon && (
                  <img
                    src={`${process.env.PUBLIC_URL}/img/${item.icon}`}
                    className="sidebarItem-img"
                    alt="content icon"
                  />
                )}
                {item.title}
              </span>
              <img
                className="bi-chevron-down toggle-btn"
                onClick={() => setOpen(!open)}
                src={downArrow}
                alt=""
              />
            </div>

            <div className="sidebar-content">
              {item.childrens.map((child, index) => (
                <SidebarItem key={index} item={child} />
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return (
      <span>
        <Link to={item.path ? item.path : "#"} className="sidebar-item plain">
          {item.icon && (
            <img
              src={`${process.env.PUBLIC_URL}/img/${item.icon}`}
              className="sidebarItem-img"
              alt="content icon"
            />
          )}
          {item.title}
        </Link>
      </span>
    );
  }
}
