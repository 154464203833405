import * as CompanyApi from "../api/CompanyRequest";

export const getCompanyData = (userId) => async (dispatch) => {
  dispatch({ type: "RETREIVING_START" });
  try {
    const { data } = await CompanyApi.getData(userId);
    console.log(data);
    dispatch({ type: "RETREIVING_SUCCESS", data: data });
  } catch (error) {
    dispatch({ type: "RETREIVING_FAIL" });
    console.log(error);
  }
};
