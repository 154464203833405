import React from "react";
import "./Signup.css";
import SignupLeft from "./SignupLeft";
import SignupRight from "./SignupRight";

const Signup = () => {
  return (
    <div className="signup">
      <SignupLeft />
      <SignupRight />
    </div>
  );
};

export default Signup;
