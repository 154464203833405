  import './App.css';
  import AddCategory from './components/AddCategory/AddCategory';
  import AssetForm from './components/AssetForms/AssetForm';
  import CompanyProfile from './components/CompanyProfile/CompanyProfile';
  import EmployeesManagement from './components/EmployeesManagement/EmployeesManagement';
  import HomePage from './components/HomePage/HomePage';
  import SignIn from './components/SignIn/SignIn';
  import Signup from './components/Signup/Signup';
  import SuperUserDetails from './components/SuperAdmin/SuperUserDetails/SuperUserDetails';
  import UserManagement from './components/UserManagement/UserManagement';
  import { Route, Routes, Navigate } from 'react-router-dom';
  import ReportDetails from './components/ReportDetails/ReportDetails';
  import DepreciationSummary from './components/DepreciationSummary/DepreciationSummary';
  import DepreciationByAsset from './components/DepreciationByAsset/DepreciationByAsset';
  import { useDispatch, useSelector } from 'react-redux';
  import { getCompanyData } from './actions/CompanyAction';
  import Roles from './components/Roles/Roles';
  import * as assetAPI from './api/AssetRequest';
  import * as companyAPI from './api/CompanyRequest';

  import React from 'react';
  import ReportPage from './components/ReportPage/ReportPage';
  import Others from './components/Other-Settings/Others';
  import SuperAdminCompanyDetails from './components/SuperAdmin/SuperAdminCompanyDetails/SuperAdminCompanyDetails';
  import InstanceDetails from './components/SuperAdmin/InstanceDetails/InstanceDetails';
  import DepreciationForcast from './components/DepreciationForcast/DepreciationForcast';
  import companyReducer from './reducers/CompanyReducer';
  import Dashboard from './components/Dashboard/Dashboard';
  import SuperAdminDashboard from './components/SuperAdmin/SuperAdminDashboard/SuperAdminDashboard';
  import AssetDetailReport from './components/AssetDetailReport/AssetDetailReport';
  import AssetCostDetail from './components/AssetCostDetail/AssetCostDetail';
  import AssetCostSummary from './components/AssetCostSummary/AssetCostSummary';

  function App() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData?.userData);
    React.useEffect(() => {
      const dispatchAPIs = async () => {
        await assetAPI.removeDeallocatedAssets();
        await companyAPI.suspendedTrialPeriodCompanies();
      };
      dispatchAPIs();
      if (user) dispatch(getCompanyData(user?._id));
    }, [user]);


    

    return (
      <div className="App">
        <Routes>
          <Route
            path="/signup"
            element={
              user?.role === 'SUPER ADMIN' ? (
                <Navigate to="../superadmin/instanceDetails" />
              ) : user ? (
                <Navigate to="../home" />
              ) : (
                <Signup />
              )
            }
          />
          <Route
            path="/"
            element={
              user?.role === 'SUPER ADMIN' ? (
                <Navigate to="../superadmin/instanceDetails" />
              ) : user ? (
                <Navigate to="../home" />
              ) : (
                <Signup />
              )
            }
          />
          <Route
            path="/home"
            element={user ? <HomePage /> : <Navigate to="../signin" />}
          />
          <Route
            path="/dashboard"
            element={user ? <Dashboard /> : <Navigate to="../signin" />}
          />

          <Route
            path="/signin"
            element={
              user?.role === 'SUPER ADMIN' ? (
                <Navigate to="../superadmin/companyDetails" />
              ) : user ? (
                <Navigate to="../home" />
              ) : (
                <SignIn />
              )
            }
          />
          <Route
            path="/company-profile"
            element={user ? <CompanyProfile /> : <Navigate to="/signin" />}
          />
          <Route
            path="/user-management"
            element={user ? <UserManagement /> : <Navigate to="/signin" />}
          />
          <Route
            path="/report-management"
            element={user ? <ReportPage /> : <Navigate to="/signin" />}
          />
          <Route
            path="/report-management/asset-summary"
            element={
              user ? (
                <ReportDetails
                  header="Asset Summary"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/report-management/asset-detail"
            element={
              user ? (
                <AssetDetailReport
                  header="Asset Detail"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/report-management/asset-cost-detail"
            element={
              user ? (
                <AssetCostDetail
                  header="Asset Cost Detail"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/report-management/asset-cost-summary"
            element={
              user ? (
                <AssetCostSummary
                  header="Asset Cost Summary"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/report-management/depreciation-summary"
            element={
              user ? (
                <DepreciationSummary
                  header="Depreciation Summary"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/report-management/depreciation-by-asset"
            element={
              user ? (
                <DepreciationByAsset
                  header="Depreciation By Asset"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/report-management/depreciation-forcast"
            element={
              user ? (
                <DepreciationForcast
                  header="Depreciation Forcast"
                  company={user.companyName}
                />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          <Route
            path="/other-settings"
            element={user ? <Others /> : <Navigate to="/signin" />}
          />
          <Route
            path="/asset-form"
            element={user ? <AssetForm /> : <Navigate to="/signin" />}
          />
          <Route
            path="/add-category"
            element={user ? <AddCategory /> : <Navigate to="/signin" />}
          />
          <Route
            path="/emp-management"
            element={user ? <EmployeesManagement /> : <Navigate to="/signin" />}
          />
          <Route
            path="/roles-info"
            element={user ? <Roles /> : <Navigate to="/signin" />}
          />
          <Route
            path="/superadmin/userDetails"
            element={user ? <SuperUserDetails /> : <Navigate to="/signin" />}
          />
          <Route
            path="/superadmin/companyDetails"
            element={
              user ? <SuperAdminCompanyDetails /> : <Navigate to="/signin" />
            }
          />
          <Route
            path="/superadmin/instanceDetails"
            element={user ? <InstanceDetails /> : <Navigate to="/signin" />}
          />
          <Route
            path="/superadmin/dashboard"
            element={user ? <SuperAdminDashboard /> : <Navigate to="/signin" />}
          />
        </Routes>
      </div>
    );
  }

  export default App;
