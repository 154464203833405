import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { read, utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx';

import sortSign from '../../img/sortSign.svg';
import personLogo from '../../img/createUserField.svg';
import exportImg from '../../img/export.svg'
import importImg from '../../img/import.svg'
import Modal from 'react-modal';
import './EmployeesManagement.css';
import * as CompanyApi from '../../api/CompanyRequest';
import { useSelector } from 'react-redux';

/* SWAL - Sweet Alert */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import wave from '../../img/alert_wave.svg';
import bell from '../../img/bellIcon.svg';
import Delete from '../../img/deleteBlue.svg';
import Edit from '../../img/edit.svg';

const MySwal = withReactContent(Swal);

const EmployeesManagement = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  let [excelData, setExcelData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [fetchData, setFetchData] = useState(true);
  const [formData, setFormData] = useState({
    empId: '',
    name: '',
    department: '',
    email: '',
    phone: '',
    region: '',
    assets: [],
  });
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    if (fetchData) {
      const getEmployees = async () => {
        const response = await CompanyApi.getEmployeesData(user._id);
        console.log(response);
        setEmployeeData(response.data);
      };
      if (user.role === 'ADMIN' || user.role === 'MANAGER') getEmployees();
      setFetchData(false);
    }

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  console.log(employeeData);

  const handleImport = async ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        // console.log(sheets);
        if (sheets.length) {
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          const response = await CompanyApi.importEmployeesData({
            userId: user._id,
            employees: rows,
          });
          console.log(rows);
          rows = rows.concat(excelData);
          console.log(rows);

          setExcelData(rows);
        } else {
          alert('File has no data!');
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = () => {
    const headings = [
      ['name', 'email', 'department', 'empId', 'phone', 'region', 'assets'],
    ];
    console.log(employeeData);
    // const exportfileData = employeeData.map(
    //   ({ _id, ...keepAttrs }) => keepAttrs
    // );
    const exportfileData = employeeData.map((item) => {
      let assetStr = '';
      for (let i = 0; i < item.assets.length; i++) {
        if (i === item.assets.length - 1) {
          assetStr += item.assets[i].assetModel;
        } else {
          assetStr += item.assets[i].assetModel + ', ';
        }
      }

      const { _id, ...other } = item;
      other['assets'] = assetStr;
      console.log(other);
      return other;
    });
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);

    utils.sheet_add_json(ws, exportfileData, {
      origin: 'A2',
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, 'Employees');
    writeFile(wb, 'EmployeesReport.xlsx');
  };

  const handleExportSample = () => {
    let sampleData = [
      {
        empId: '111',
        name: 'Rohan Mishra',
        department: 'IT',
        email: 'rohan@company.com',
        phone: '915566778899',
        region: 'Noida',
        assets: [],
      },
    ];

    const headings = [
      ['empId', 'name', 'department', 'email', 'phone', 'region', 'assets'],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, sampleData, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Employees');
    writeFile(wb, 'EmployeesSampleReport.xlsx');
  };

  // const handleImport = (file) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);

  //     fileReader.onload = (e) => {
  //       const bufferArray = e.target.result;

  //       const wb = XLSX.read(bufferArray, { type: "buffer" });

  //       const wsname = wb.SheetNames[0];

  //       const ws = wb.Sheets[wsname];

  //       const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(data);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  //   promise.then((data) => {
  //     const oldData = excelData;
  //     setExcelData(data);
  //     console.log(data);
  //     // setExcelData([...excelData, oldData]);
  //     // setExcelData((oldExcelData) => [...oldExcelData, data]);
  //     // useEffect(() => { setExcelData(d) }, [])
  //   });
  // };
  // console.log(excelData);

  const handleSubmit = async (event) => {
    // event.preventDefault();
    console.log(formData);

    try {
      const response = await CompanyApi.addEmployee({
        userId: user._id,
        employee: formData,
      });
      console.log(response);
      if (response.status === 200) {
        setModalOpened(false);

        alert('Employee Added Successfully!');
      }
    } catch (error) {
      setModalOpened(false);
      console.log(error);
      if (error.response.status === 500) alert('Error Adding Employee!');
      else if (error.response.status === 403) alert('You do not have access!');
      else alert('Error!');
    }
    setExcelData((oldExcelData) => [...oldExcelData, formData]);
    setEmployeeData([...employeeData, formData]);

    setModalOpened(false);
    setFetchData(true);
    setFormData({
      empId: '',
      name: '',
      department: '',
      email: '',
      phone: '',
      region: '',
      assets: [],
    });
  };

  const handleClose = (event) => {
    event.preventDefault();
    setModalOpened(false);
    setEditModal(false);
    setFormData({
      empId: '',
      name: '',
      department: '',
      email: '',
      phone: '',
      region: '',
      assets: [],
    });
  };
  const handleChange = (event) => {
    // event.preventDefault();
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSortName = (event) => {
    const data2 = [...excelData].sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );

    data2.map((d) => console.log('with conversion', d.name)); // doe, Ibas
    setExcelData(data2);
  };
  const handleSortDept = (event) => {
    const data2 = [...excelData].sort((a, b) =>
      a.department.toLowerCase() < b.department.toLowerCase() ? -1 : 1
    );

    // data2.map((d) => console.log("with conversion", d.name)); // doe, Ibas
    setExcelData(data2);
  };
  const handleSortEmpid = (event) => {
    const data2 = [...excelData].sort((a, b) => (a.empId < b.empId ? -1 : 1));

    // data2.map((d) => console.log("with conversion", d.name)); // doe, Ibas
    setExcelData(data2);
  };
  const handleSortregion = (event) => {
    const data2 = [...excelData].sort((a, b) =>
      a.region.toLowerCase() < b.region.toLowerCase() ? -1 : 1
    );

    // data2.map((d) => console.log("with conversion", d.name)); // doe, Ibas
    setExcelData(data2);
  };
  const handleEdit = (id) => (event) => {
    setEditId(id);

    const currData = employeeData.find((item) => item._id === id);

    setFormData(currData);
    setEditModal(true);
  };
  const handleEditSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await CompanyApi.editEmployee({
        userId: user._id,
        employee: formData,
      });
      console.log(response);
      if (response.status === 200) {
        setModalOpened(false);
        alert('Employee Updated Successfully!');
      }
    } catch (error) {
      setModalOpened(false);
      console.log(error);
      if (error.response.status === 500) alert('Error Updating Employee!');
      else if (error.response.status === 403) alert('You do not have access!');
      else alert('Error!');
    }
    // setExcelData(newArr);
    setEditModal(false);
    setFetchData(true);
  };
  const handleDeleteModal = (id) => (event) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleDeleteConfirm = async (event) => {
    // event.preventDefault();

    try {
      const response = await CompanyApi.deleteEmployee({
        userId: user._id,
        employeeId: deleteId,
      });
      console.log(response);
      if (response.status === 200) {
        setModalOpened(false);
        const newList = employeeData.filter((item) => item._id !== deleteId);
        setEmployeeData(newList);
        // alert('Employee Deleted Successfully!!!!!!!!!!!!!!!');
        MySwal.fire({
          title: <p>Employee Deleted Successfully!</p>,
          icon: 'success',
        });
      }
    } catch (error) {
      setModalOpened(false);
      console.log(error);
      if (error.response.status === 500) alert('Error Deleting Employee!');
      else if (error.response.status === 401)
        MySwal.fire({
          title: <h5>Can't Delete Employee</h5>,
          imageUrl: '/img/bell.svg',
          imageAlt: 'Custom image',
          confirmButtonColor: '#1CA0C4',
          background: '#fff url(/img/wave.svg) no-repeat center bottom',
          width: '375px',
          confirmButtonText: 'Ok !!',
        });
      else if (error.response.status === 403) alert('You do not have access!');
      else alert('Error!');
    }

    setDeleteModal(false);
    setDeleteId(null);
  };
  const handleDeleteReject = (event) => {
    event.preventDefault();
    setDeleteId(null);
    setDeleteModal(false);
  };

  // console.log(deleteIndex);

  return (
    <div className="employeesManagement sidebar-container">
      <Sidebar />
      <div className="homeRight">
        <Navbar pageHeader="Employees" functionality={setModalOpened} />

        <div className="print-export print-export2" style={{backgroundColor:'#ffffff'}}>
          <div></div>
          <div className="import export">
            {/* Import */}
            <button className="custom-file-label" style={{height:'2rem'}} onClick={handleExportSample}>
              Download Sample
            </button>

            <label className="custom-file-label" htmlFor="inputGroupFile" style={{backgroundColor:'transparent',border:'none'}}>
            <img src={importImg} style={{width:'6rem', cursor:'pointer'}}  alt="" />
              <input
                hidden
                type="file"
                name="file"
                id="inputGroupFile"
                style={{opacity:'0', backgroundColor:'transparent'}}
                onChange={handleImport}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                // onChange={(e) => {
                //   const file = e.target.files[0];
                //   handleImport(file);
                // }}
              />
            </label>
            {/* <img src={downArrow} alt="" /> */}
            <div className="export">
              
            <img src={exportImg} style={{width:'6rem', cursor:'pointer', marginBottom:'0.5rem'}} onClick={handleExport} alt="" />
            </div>
          </div>
        </div>
        <div>
          {employeeData?.length > 0 ? (
            <table className="userTable emp-table">
              <tr className="userTable-row-header">
                <th>
                  <span>S.No.</span>
                  {/* <img src={sortSign} alt="" /> */}
                </th>
                <th>
                  <span>Emp ID</span>
                  <img
                    className="sort-btn"
                    src={sortSign}
                    alt=""
                    onClick={handleSortEmpid}
                  />
                </th>
                <th>
                  <span>Name</span>
                  <img
                    className="sort-btn"
                    src={sortSign}
                    alt=""
                    onClick={handleSortName}
                  />
                </th>
                <th>
                  <span>Department</span>
                  <img
                    className="sort-btn"
                    src={sortSign}
                    alt=""
                    onClick={handleSortDept}
                  />
                </th>
                <th>
                  <span>Email</span>
                  {/* <img src={sortSign} alt="" /> */}
                </th>
                <th>
                  <span>Phone</span>
                </th>
                <th>
                  <span>Location</span>
                  <img
                    className="sort-btn"
                    src={sortSign}
                    alt=""
                    onClick={handleSortregion}
                  />
                </th>
                <th>
                  <span>Assets</span>
                </th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>

              {employeeData?.map((data, index) => (
                <tr className="emp-table-body" key={data.email}>
                  <td>{index + 1}</td>
                  {/* <td>{data.EmpID}</td> */}
                  <td>{data.empId}</td>
                  <td>{data.name}</td>
                  <td>{data.department}</td>
                  <td>{data.email}</td>
                  <td>{data.phone}</td>
                  <td>{data.region}</td>
                  <td>
                    {data?.assets?.map((item, index) => {
                      if (index === data.assets.length - 1)
                        return item.assetModel;
                      else return item.assetModel + ', ';
                    })}
                  </td>
                  <td>
                    {/* <button
                      type="button"
                      className="userTable-edit"
                      onClick={handleEdit(data._id)}
                    >
                      Edit
                    </button> */}
                    <img
                      src={Edit}
                      alt=""
                      onClick={handleEdit(data._id)}
                      style={{
                        width: '60px',
                        marginLeft: '-1rem',
                        cursor: 'pointer',
                      }}
                    />
                  </td>
                  <td>
                    {/* <button
                      type="button"
                      className="userTable-delete"
                      onClick={handleDeleteModal(data._id)}
                    >
                      Delete
                    </button> */}
                    <img
                      src={Delete}
                      alt=""
                      onClick={handleDeleteModal(data._id)}
                      style={{
                        width: '60px',
                        marginLeft: '-1rem',
                        cursor: 'pointer',
                      }}
                    />
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <div className="noEmpData">
              <p>
                Import or Add data manually. Note that in order to import data,
                it should have same column and format as below:
              </p>
              <ul>
                <p>Addtibute ----- Columnn Name</p>
                <li>Employee ID - EmpID</li>
                <li>Employee Name - Name</li>
                <li>Employee Department - Department</li>
                <li>Employee Email - Email</li>
                <li>Employee Phone - Phone</li>
                <li>Employee Region - Region</li>
                <li>Allocated Assets - AllocatedAssets</li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <Modal
        style={{
          content: { width: '40rem', padding: '0', marginLeft: '25rem' },
        }}
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
      >
        <div className="modalNav">Add Employee</div>
        <div className="addEmpModal">
          <form action="" className="createUser-form">
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="empId">Emp ID</label>
              </div>
              <input
                style={{ border: '1px solid #1CA0C4', height: '1.5rem' }}
                type="text"
                id="empId"
                name="empId"
                onChange={handleChange}
                value={formData.empId}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="name">Name</label>
              </div>
              <input
                style={{ border: '1px solid #1CA0C4', height: '1.5rem' }}
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                value={formData.name}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="department">Department</label>
              </div>
              <input
                style={{ border: '1px solid #1CA0C4', height: '1.5rem' }}
                type="text"
                id="department"
                name="department"
                onChange={handleChange}
                value={formData.department}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="email">Email</label>
              </div>
              <input
                style={{ border: '1px solid #1CA0C4', height: '1.5rem' }}
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="phone">phone</label>
              </div>
              <input
                style={{ border: '1px solid #1CA0C4', height: '1.5rem' }}
                type="number"
                id="phone"
                name="phone"
                onChange={handleChange}
                value={formData.phone}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="region">Location</label>
              </div>
              <input
                style={{ border: '1px solid #1CA0C4', height: '1.5rem' }}
                type="text"
                id="region"
                name="region"
                onChange={handleChange}
                value={formData.region}
              />
            </div>
            {/* <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="region">Allocated Assets</label>
              </div>
              <input type="text" id="allocatedAssets" name="allocatedAssets" />
            </div> */}

            <div className="createUser-buttons">
              <button
                type="submit"
                className="createUser-button-submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="createUser-button-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        style={{
          content: { width: '40rem', padding: '0', marginLeft: '25rem' },
        }}
        isOpen={editModal}
        onRequestClose={() => setEditModal(false)}
      >
        <div className="modalNav">Edit Employee</div>
        <div className="addEmpModal">
          <form action="" className="createUser-form">
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="empId">Emp ID</label>
              </div>
              <input
                type="text"
                id="empId"
                name="empId"
                onChange={handleChange}
                value={formData?.empId}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="name">Name</label>
              </div>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                value={formData?.name}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="department">Department</label>
              </div>
              <input
                type="text"
                id="department"
                name="department"
                onChange={handleChange}
                value={formData?.department}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="email">Email</label>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                value={formData?.email}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="phone">phone</label>
              </div>
              <input
                type="number"
                id="phone"
                name="phone"
                onChange={handleChange}
                value={formData?.phone}
              />
            </div>
            <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="region">Location</label>
              </div>
              <input
                type="text"
                id="region"
                name="region"
                onChange={handleChange}
                value={formData?.region}
              />
            </div>
            {/* <div className="createUser-data">
              <div>
                <img src={personLogo} alt="" />
                <label htmlFor="region">Allocated Assets</label>
              </div>
              <input type="text" id="allocatedAssets" name="allocatedAssets" />
            </div> */}

            <div className="createUser-buttons">
              <button
                type="submit"
                className="createUser-button-submit"
                onClick={handleEditSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="createUser-button-cancel"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            top: '25%',
            left: '40%',
            right: '25%',
            bottom: '25%',
            width: '25rem',
            fontSize: '12px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '40px',
            outline: 'none',
            padding: '2rem',
            overflowX: 'hidden',
            overflowY: 'hidden',
          },
        }}
      >
        <div className="deleteModal">
          <img src={bell} alt="" />
          <h1>Are you sure you want to delete this employee?</h1>
          <div className="deleteModal-btn">
            <button
              type="submit"
              className="userTable-edit"
              style={{ backgroundColor: '#E48420' }}
              onClick={handleDeleteConfirm}
            >
              Confirm
            </button>
            <button
              type="button"
              className="userTable-delete"
              style={{ backgroundColor: '#111111' }}
              onClick={handleDeleteReject}
            >
              Cancel
            </button>
          </div>
          <img
            style={{ marginTop: '-1rem', width: '133%' }}
            src={wave}
            alt=""
          />
        </div>
      </Modal>
    </div>
  );
};

export default EmployeesManagement;
