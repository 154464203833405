import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import downArrow from '../../img/downArrow.svg';
import './UserManagement.css';
import * as UserApi from '../../api/UserRequest';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import personLogo from '../../img/createUserField.svg';
import CreateUserModal from './CreateUserModal';

/* SWAL - Sweet Alert */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import wave from '../../img/alert_wave.svg';
import bell from '../../img/bellIcon.svg';
import Delete from '../../img/deleteBlue.svg';
import Edit from '../../img/edit.svg';

const MySwal = withReactContent(Swal);

const UserManagement = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [userData, setUserData] = useState();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    department: '',
    email: '',
    phone: '',
    region: '',
    role: '',
    id: '',
  });

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserApi.getAllUsers(user._id);
      setUserData(response.data);
    };

    if (user.role === 'ADMIN' || user.role === 'MANAGER') getUsers();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const handleChange = (event) => {
    // event.preventDefault();
    setEditFormData({
      ...editFormData,
      [event.target.name]: event.target.value,
    });
    // console.log(editFormData);
  };
  const handleEdit = (email) => (event) => {
    event.preventDefault();
    const data = userData.find((item) => item.email === email);
    // console.log(data);
    setEditFormData({
      name: data.name,
      email: data.email,
      phone: data.phone,
      region: data.region,
      department: data.department,
      role: data.role,
      id: data._id,
    });
    setEditModal(true);
  };
  const handleClose = (event) => {
    event.preventDefault();
    // setModalOpened(false);
    setEditModal(false);
    setEditFormData({
      name: '',
      department: '',
      email: '',
      phone: '',
      region: '',
      role: '',
      id: '',
    });
  };
  const handleEditSubmit = async (event) => {
    // event.preventDefault();
    const data = { userId: user._id, ...editFormData };
    const response = await UserApi.updateUser(data);
    console.log(response);
    setEditModal(false);
  };

  const handleDeleteModal = (id) => (event) => {
    setDeleteUserId(id);
    setDeleteModal(true);
  };
  const handleDeleteConfirm = async (event) => {
    // event.preventDefault();
    const response = await UserApi.deleteUser({
      id: deleteUserId,
      userId: user._id,
    });
    console.log(response);
    if (response?.status === 200) {
      MySwal.fire({
        title: <h5>User Deleted Successfully !!</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: '#1CA0C4',
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width: '375px',
        confirmButtonText: 'Ok !!',
      });
    } else {
      MySwal.fire({
        title: <h5>Error Deleting User !!</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: '#1CA0C4',
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width: '375px',
        confirmButtonText: 'Ok !!',
      });
    }
    setDeleteModal(false);
    setDeleteUserId('');
  };
  const handleDeleteReject = (event) => {
    event.preventDefault();
    setDeleteModal(false);
    setDeleteUserId('');
  };
  const handleModelOpen = () => {
    setCreateModal(true)
  }
  if (user.role === 'ADMIN' || user.role === 'MANAGER') {
    return (
      <>
        {!userData ? (
          <h4 className="unautorizedAccess">Loading...</h4>
        ) : (
          <div className="userManagement">
            <Sidebar />
            <div className="userManagement-right">
              <Navbar pageHeader="Add User" functionality={handleModelOpen} />
              <CreateUserModal
                  createModal={createModal}
                  setCreateModal={setCreateModal}
                />
              <table className="userTable">
                <tr className="userTable-row-header">
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Department</th>
                  <th>Location</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
                {userData.map((data, index) => (
                  <tr className="emp-table-body" key={data._id}>
                    <td>
                      {index+1}
                    </td>
                    <td>{data.name}</td>
                    <td>
                      {data.role[0]?.toUpperCase() +
                        data.role.slice(1).toLowerCase()}
                    </td>
                    <td>{data.email}</td>
                    <td>{data.phone}</td>
                    <td>{data.department}</td>
                    <td>{data.region}</td>

                    {user.email !==  data.email && (
                      <>
                        <td>
                          <img src={Edit} alt=""  onClick={handleEdit(data.email)} style={{width:'60px', marginLeft:"-1rem", cursor:"pointer"}}/>
                        </td>
                        <td>
                          {/* <button
                            type="button"
                            className="userTable-delete"
                            onClick={handleDeleteModal(data._id)}
                          >
                            Delete
                          </button> */}
                          <img src={Delete} alt=""  onClick={handleDeleteModal(data._id)} style={{width:'60px', marginLeft:"-1rem", cursor:"pointer"}}/>
                        </td>
                      </>
                    )}
                    {user.email ===  data.email && (
                      <>
                        <td >
                          
                        </td>
                        <td>
                          
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </table>
            </div>

            <Modal
              style={{
                content: { width: '40rem', padding: '0', marginLeft: '25rem' },
              }}
              isOpen={editModal}
              onRequestClose={() => setEditModal(false)}
            >
              <div className="modalNav">Edit User</div>
              <div className="addEmpModal">
                <form action="" className="createUser-form">
                  <div className="createUser-data">
                    <div>
                      <img src={personLogo} alt="" />
                      <label htmlFor="name">Name</label>
                    </div>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      value={editFormData.name}
                    />
                  </div>
                  <div className="createUser-data">
                    <div>
                      <img src={personLogo} alt="" />
                      <label htmlFor="department">Department</label>
                    </div>
                    <input
                      type="text"
                      id="department"
                      name="department"
                      onChange={handleChange}
                      value={editFormData.department}
                    />
                  </div>
                  <div className="createUser-data">
                    <div>
                      <img src={personLogo} alt="" />
                      <label htmlFor="email">Email</label>
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={editFormData.email}
                    />
                  </div>
                  <div className="createUser-data">
                    <div>
                      <img src={personLogo} alt="" />
                      <label htmlFor="Name">Role</label>
                    </div>
                    <select
                      name="role"
                      onChange={handleChange}
                      value={editFormData.role}
                    >
                      <option selected value="ANALYTICS">
                        Analytics
                      </option>
                      <option value="TEAM">Team</option>

                      <option value="MANAGER">Manager</option>

                      <option value="ADMIN">Admin</option>
                    </select>
                  </div>
                  <div className="createUser-data">
                    <div>
                      <img src={personLogo} alt="" />
                      <label htmlFor="mobile">Mobile</label>
                    </div>
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      onChange={handleChange}
                      value={editFormData.phone}
                    />
                  </div>
                  <div className="createUser-data">
                    <div>
                      <img src={personLogo} alt="" />
                      <label htmlFor="region">Location</label>
                    </div>
                    <input
                      type="text"
                      id="region"
                      name="region"
                      onChange={handleChange}
                      value={editFormData.region}
                    />
                  </div>
                  <div className="createUser-buttons">
                    <button
                      type="submit"
                      className="createUser-button-submit"
                      onClick={handleEditSubmit}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="createUser-button-cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <Modal
              isOpen={deleteModal}
              onRequestClose={() => setDeleteModal(false)}
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.75)',
                },
                content: {
                  position: 'absolute',
                  top: '25%',
                  left: '25%',
                  right: '25%',
                  bottom: '25%',
                  border: '1px solid #ccc',
                  background: '#fff',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '40px',
                  outline: 'none',
                  padding: '2rem',
                },
              }}
            >
              <div className="deleteModal">
                <h1>Are you sure you want to delete?</h1>
                <div className="deleteModal-btn">
                  <button
                    type="submit"
                    className="userTable-edit"
                    onClick={handleDeleteConfirm}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    className="userTable-delete"
                    onClick={handleDeleteReject}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  } else {
    return <h4>You do not have access to this page!</h4>;
  }
};

export default UserManagement;
