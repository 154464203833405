import React, { useEffect, useState } from 'react';
import addIcon from '../../img/addAssetIcon.svg';
import phoneSVG from '../../img/phoneSVG.svg';
import computerSVG from '../../img/computerSVG.svg';
import printSVG from '../../img/printSVG.svg';
import softwareSVG from '../../img/softwareSVG.svg';
import addSVG from '../../img/addSVG.svg';
import * as DateUtils from '../../Utils/dateFormat';
import './Navbar.css';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/AuthAction';
import { Link } from 'react-router-dom';
import plus from '../../img/plus.svg';

const Navbar = (props) => {
  const dispatch = useDispatch();
  const [modalOpened, setModalOpened] = useState(false);

  const user = useSelector((state) => state.authReducer.authData?.userData);
  const company = useSelector((state) => state.companyReducer.companyData);
  const handleLogut = (e) => {
    dispatch(logOut());
  };
  useEffect(() => {}, [company]);
  return (
    <div className="navbar">
      <div className="nav-left">
        {(props.pageHeader === 'Add Category' ||
          props.pageHeader === 'Add User' ||
          props.pageHeader === 'Employees' ||
          props.pageHeader === 'Add Asset') && (
          <img
            src={plus}
            style={{ marginRight: '0.5rem', cursor: 'pointer' }}
            onClick={props.functionality}
            alt=""
          />
        )}
        <div className="navbar-addAsset" style={{ marginTop: '0.1rem' }}>
          <span>{props.pageHeader}</span>
        </div>
      </div>
      <div className="navbar-profile">
        {/* <Link to="../asset-form">
          <img
            // onClick={() => setModalOpened(true)}
            src={addIcon}
            className="addIcon"
            alt="add asset"
          />
        </Link> */}
        {company && (
          <div className="nav-companyLife-outer">
            <div className="nav-companyLife">
              <div>{DateUtils.calculateDaysPassed(company.createdAt)}</div>
              <div>Days</div>
            </div>
          </div>
        )}
        <div className="nav-companyBox">
          <p>
            {company
              ? company.companyName?.split(' ')[0]
              : user?.companyName?.split(' ')[0]}
          </p>
          {company && <div>{company.CompanyType}</div>}
        </div>
        <button className="navbar-logout-button" onClick={handleLogut}>
          Logout
        </button>
        <button className="navbar-profile-button">
          {user?.name.split(' ')[0][0]}
          {user?.name.split(' ').length >= 2
            ? user?.name.split(' ')[1][0]
            : user?.name.split(' ')[0][0]}
        </button>
      </div>
    </div>
  );
};

export default Navbar;

{
  /* <Modal isOpen={modalOpened} onRequestClose={() => setModalOpened(false)}>
<div className="addModal">
  <button
    className="addModal-closebtn"
    onClick={() => setModalOpened(false)}
  >
    Close
  </button>
 
  <div>
    <div className="addModal-type">
      <img src={computerSVG} alt="" />
      <p>Computer</p>
    </div>
    <div className="addModal-type">
      <img src={phoneSVG} alt="" />
      <p>Phone</p>
    </div>
    <div className="addModal-type">
      <img src={printSVG} alt="" />
      <p>Printer</p>
    </div>
  </div>
  <div>
    <div className="addModal-type">
      <img src={softwareSVG} alt="" />
      <p>Software</p>
    </div>
    <div className="addModal-type">
      <img src={addSVG} alt="" />
      <p>Add New</p>
    </div>
    <div className="addModal-type">
      <img src={addSVG} alt="" />
      <p>Add New</p>
    </div>
  </div>
  
</div>
</Modal> */
}
