import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as CompanyApi from '../../../api/CompanyRequest';
import Navbar from '../../Navbar/Navbar';
import Sidebar from '../../Sidebar/Sidebar';
import LockImg from '../../../img/LockImg.svg';
import './SuperAdminCompanyDetails.css';
import Delete from '../../../img/delete.svg';
import Suspend from '../../../img/suspendIcon.svg';
import Person from '../../../img/Person.svg';
import * as API from '../../../api/CompanyRequest';
import * as UserAPI from '../../../api/UserRequest';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '25%',
    height: '25%',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
  },
};

const SuperAdminCompanyDetails = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [deletedCompanyDetails, setDeletedCompanyDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyType, setCompanyType] = useState('Trial');
  const [selectedCompany, setSelectedCompany] = useState(null);
  useEffect(() => {
    const fetchCompanies = async (data) => {
      const res = await CompanyApi.getCompaniesForSuperAdmin(user._id, data);
      console.log(res.data);
      const res2 = await CompanyApi.getDeletedCompaniesForSuperAdmin(user._id);
      setCompanyDetails(res.data);
      setDeletedCompanyDetails(res2.data);
      // console.log(res.data);
    };
    fetchCompanies();
  }, []);

  console.log(deletedCompanyDetails);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  let cnt = 0;
  const handleDelete = (e, data) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this company?')) {
      const sendData = { companyId: data };
      deleteCompany(sendData);
    }
  };
  const deleteCompany = async (data) => {
    try {
      const res = await API.deleteCompanyBySuperAdmin(user._id, data);
      console.log(res.data);
      alert('Company Deleted Successfully');
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuspend = (e, data) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to suspend this company?')) {
      const sendData = { companyId: data };
      suspendCompany(sendData);
    }
  };
  const suspendCompany = async (data) => {
    try {
      const res = await API.suspendCompanyBySuperAdmin(user._id, data);
      console.log(res.data);
      alert('Company Suspended Successfully');
    } catch (err) {
      console.log(err);
    }
  };
  const handleSaveAccountType = (e, data) => {
    e.preventDefault();
    saveAccountType(data);
  };

  const saveAccountType = async (data) => {
    try {
      const res = await API.changeAccountTypeBySuperAdmin(user._id, data);
      alert('Account type updated');
      closeModal();
    } catch (error) {
      console.log(error);
      alert('Error saving account type, try again');
    }
  };

  const handleReset = (e, data) => {
    e.preventDefault();
    const newPassword = prompt('Please enter a new password');
    if (window.confirm('Are you sure you want to save the new password?')) {
      const sendData = { companyId: data, newPassword: newPassword };
      resetPassword(sendData);
    }
  };
  const resetPassword = async (data) => {
    try {
      const res = await UserAPI.resetPasswordByCompany(user._id, data);
      console.log(res.data);
      alert('Password reset Successfully');
    } catch (err) {
      console.log(err);
    }
  };

  if (user.role !== 'SUPER ADMIN') {
    return (
      <h1
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Access Forbidden
      </h1>
    );
  } else {
    return (
      <div className="supercompanyProfile">
        <Sidebar />
        <div className="">
          <Navbar pageHeader="Tenie ALM Super Admin | Company Details " />
          <div className="supercompanytitleBar">
            <div id="sNo">S No.</div>
            <div id="instanceIdTitle">Instance ID</div>
            <div>Created On</div>
            <div>Company </div>
            <div>No of Users</div>
            <div>API Runs</div>
            <div>Storage Used</div>
            <div>Last Accessed</div>
            <div>Industry</div>
            <div>Address</div>
            <div>Country</div>
            <div>GST</div>
            <div>VAT </div>
            <div>PAN</div>
            <div>Account Status</div>
            <div>Account Type</div>
            <div className="supercompanycontainIcons">Change Account Type</div>
            <div className="supercompanycontainIcons">Reset Password</div>
            <div className="supercompanycontainIcons">Suspend</div>
            <div className="supercompanycontainIcons">Delete</div>
          </div>
          <div className="supercompanyDetails">
            {companyDetails &&
              companyDetails.map((company) => {
                cnt++;
                <spam className="vr"></spam>;
                return (
                  <div className={cnt % 2 === 0 ? 'even row' : 'odd row'}>
                    <div id="sNoData">{cnt}</div>
                    <div id="instanceId">{company._id}</div>
                    <div>{company.createdAt.substring(0, 10)}</div>
                    <div>{company.companyName}</div>
                    <div>{company.employees.length + 1}</div>
                    <div>-</div>
                    <div>
                      {(company.storageUsed / (1024 * 1024)).toFixed(2)} MB
                    </div>
                    <div>
                      {company.lastAccessed
                        ? company.lastAccessed
                        : 'Not yet accessed'}
                    </div>
                    <div>{company.industry}</div>
                    <div>{company.address}</div>
                    <div>{company.country}</div>
                    <div>{company.gstNum}</div>
                    <div>{company.vatNum}</div>
                    <div>{company.panNum}</div>
                    <div>{company.isRestricted ? 'Suspended' : 'Active'}</div>
                    <div>{company.CompanyType}</div>
                    <div className="containIcons">
                      <img
                        src={Person}
                        onClick={() => {
                          openModal();
                          setSelectedCompany(company._id);
                          setCompanyType(company.CompanyType);
                        }}
                        alt="Change account type"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        src={LockImg}
                        onClick={(event) => handleReset(event, company._id)}
                        alt="reset password"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        className={company.isRestricted ? 'suspended' : null}
                        src={Suspend}
                        onClick={(event) => handleSuspend(event, company._id)}
                        alt="suspend"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        src={Delete}
                        onClick={(event) => handleDelete(event, company._id)}
                        alt="delete"
                        srcSet=""
                      />
                    </div>
                  </div>
                );
              })}
            {deletedCompanyDetails &&
              deletedCompanyDetails.map((company) => {
                cnt++;
                <spam className="vr"></spam>;
                return (
                  <div className={cnt % 2 === 0 ? 'even row' : 'odd row'}>
                    <div id="sNoData">{cnt}</div>
                    <div id="instanceId">{company._id}</div>
                    <div>{company.createdAt.substring(0, 10)}</div>
                    <div>{company.companyName}</div>
                    <div>-</div>
                    <div>-</div>
                    <div>
                      -
                    </div>
                    <div>
                      -
                    </div>
                    <div>{company.industry}</div>
                    <div>{company.address}</div>
                    <div>{company.country}</div>
                    <div>{company.gstNum}</div>
                    <div>{company.vatNum}</div>
                    <div>{company.panNum}</div>
                    <div>Deleted</div>
                    <div> - </div>
                    <div className="containIcons">
                      <img
                        src={Person}
                        alt="Change account type"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        src={LockImg}
                        alt="reset password"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        className={company.isRestricted ? 'suspended' : null}
                        src={Suspend}
                        alt="suspend"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        src={Delete}
                        alt="delete"
                        srcSet=""
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-header">Tenie ALM Account Type</div>
          <div className="modal-container">
            <div>Change Account Type</div>
            <div>
              <select
                className="modal-selectInput"
                onChange={(e) => setCompanyType(e.target.value)}
              >
                <option value="Trial" selected={companyType === 'Trial'}>
                  Trial
                </option>
                <option value="Standard" selected={companyType === 'Standard'}>
                  Standard
                </option>
                <option value="Pro" selected={companyType === 'Pro'}>
                  Pro
                </option>
              </select>
              <div className="modal-selectButtons">
                <button
                  className="modal-selectButton"
                  onClick={(e) =>
                    handleSaveAccountType(e, {
                      CompanyType: companyType,
                      companyId: selectedCompany,
                    })
                  }
                  style={{ background: '#1CA0C4' }}
                >
                  Save
                </button>
                <button
                  className="modal-selectButton"
                  style={{ background: '#000000' }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* <button onClick={closeModal}>close</button> */}
        </Modal>
      </div>
    );
  }
};

export default SuperAdminCompanyDetails;
