import * as AuthApi from "../api/AuthRequest";

export const logIn = (formData) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    const { data } = await AuthApi.logIn(formData);
    dispatch({ type: "AUTH_SUCCESS", data: data });
  } catch (error) {
    if(error.response.status === 403){
      alert('Please contact info@oaknst.com')
    }
    else if(error.code === "ERR_BAD_REQUEST") alert('Invalid Credentials');
    dispatch({ type: "AUTH_FAIL" });
  }
};

export const signUpAdmin = (formData) => async (dispatch) => {
  dispatch({ type: "AUTH_START" });
  try {
    const { data } = await AuthApi.signUpAdmin(formData);
    dispatch({ type: "AUTH_SUCCESS", data: data });
  } catch (error) {
    console.log(error.response.status);
    if(error.response.status === 409){
      alert('Company already registered, please signIn')
    }
    if(error.response.status === 403){
      alert('Please contact info@oaknst.com')
    }
    dispatch({ type: "AUTH_FAIL" });
  }
};

export const logOut = () => async (dispatch) => {
  dispatch({ type: "LOG_OUT" });
};
