/* eslint-disable */
import React from "react";
import "./Others.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { useSelector } from "react-redux";
import * as CompanyApi from "../../api/CompanyRequest";


const Others = () => {
	const user = useSelector((state) => state.authReducer.authData?.userData);

	const [companyData, setCompanyData] = React.useState({});
	const [currency, setCurrency] = React.useState(companyData.currency);
	const [dateFormat, setDateFormat] = React.useState(companyData.dateFormat);
	const [numberFormat, setNumberFormat] = React.useState(
		companyData.numberFormat
	);
	const currencyOptions = [
		"INR",
		"USD",
		"EUR",
		"GBP",
		"AUD",
		"JPY",
	];
	React.useEffect(() => {
		const getCompanyData = async () => {
			const response = await CompanyApi.getData(user._id);
			setCompanyData(response.data);
		};
		if (user.role === "ADMIN" || user.role === "MANAGER") getCompanyData();
	}, []);
	React.useEffect(() => {
		companyData.currency = currency;
		companyData.dateFormat = dateFormat;
		companyData.numberFormat = numberFormat;
		CompanyApi.editData(user._id, companyData);
	}, [currency, dateFormat, numberFormat]);

	console.log(companyData);

	if (user.role === "ADMIN" || user.role === "MANAGER") {
		return (
			<div className='otherSettings'>
				<Sidebar />
				<div className=''>
					<Navbar pageHeader='Others' />
					<div className='header'>
						<div>
							This section describes different setting that administrator can
							choose in Tenie ALM
						</div>
					</div>
					<div className='settings'>
						<div className='setting-name'>Currency</div>
						<select
							className='select'
							value={currency}
							// aria-readonly='true'
							onChange={(e) => {
								setCurrency(e.target.value);
							}}
							name='currency'
							id='currency'>
							{currencyOptions.map((option) => (
								<option className="option" key={option} selected={companyData.currency === option} value={option}>
									{option}
								</option>
							))}
						</select>
						<div className='desc'>
							Choose the currency you want to use in Tenie ALM
						</div>
					</div>
					<div className='settings'>
						<div className='setting-name'>Date Format</div>
						<select
							className='select'
							value={dateFormat}
							onChange={(e) => {
								setDateFormat(e.target.value);
							}}
							name='dateFormat'
							id='dateFormat'>
							<option
								value='dd-mm-yyyy'
								selected={companyData.dateFormat === "dd-mm-yyyy"}>
								dd-mm-yyyy
							</option>
							<option
								value='mm-dd-yyyy'
								selected={companyData.dateFormat === "mm-dd-yyyy"}>
								mm-dd-yyyy
							</option>
						</select>
						<div className='desc'>
							Choose the date format you want to use in Tenie ALM
						</div>
					</div>
					<div className='settings'>
						<div className='setting-name'>Number Format</div>
						<select
							className='select'
							value={numberFormat}
							onChange={(e) => {
								setNumberFormat(e.target.value);
							}}
							name='numberFormat'
							id='numberFormat'>
							<option
								value='indian'
								selected={companyData.numberFormat === "indian"}>
								12,34,56,789
							</option>
							<option
								value='international'
								selected={companyData.numberFormat === "international"}>
								123,456,789
							</option>
						</select>
						<div className='desc'>
							Choose the number format you want to use in Tenie ALM
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		<h4 className='unautorizedAccess'>You do not have access to this page!</h4>;
	}
};

export default Others;
