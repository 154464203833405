import React, { useState, useEffect } from 'react';
import addOrangeSVG from '../../img/addOrangeSVG.svg';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import './AssetForm.css';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import * as CompanyApi from '../../api/CompanyRequest';
import * as AssetApi from '../../api/AssetRequest';
import { Link } from 'react-router-dom';
import INR from '../../img/currencies/INR.png';
import USD from '../../img/currencies/USD.png';
import Pen from '../../img/pen.svg';
import upload from '../../img/upload.svg';

const AssetForm = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalUserOpened, setModalUserOpened] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [selectValue, setSelectedValue] = useState();
  const [selectData, setSelectedData] = useState();
  const [employeesData, setEmployeesData] = useState();
  const [selectEmp, setSelectEmp] = useState();
  const [empAssignedAssets, setEmpAssignedAssets] = useState([]);
  const [doc, setDoc] = useState(null);
  const [deallocateData, setDeallocateData] = useState({
    id: '',
    date: '',
  });
  const [deallocateModal, setDeallocateModal] = useState(false);
  const [formData, setFormData] = useState({
    description: '',
    document: '',
    model: '',
    warrentyTerms: '',
    serialNum: '',
    warrantyTerms: '',
    status: 'Unassigned',
    purchaseDate: '',
    purchaseValue: '',
    manufacturer: '',
    warrantyPeriod: '',
    dealerName: '',
    dealerNumber: '',
    depRateTax: '',
    depRateOther: '',
    hardwareData: {},
    serviceArr: [],
    allocationHist: [],
    scrapValue: '',
  });
  const [serviceData, setServiceData] = useState({
    serviceDesc: '',
    serviceCost: '',
    serviceWarranty: '',
    serviceDate: '',
    serviceDocument: '',
  });
  const [allocationData, setAllocationData] = useState({
    allocationDate: '',
    deallocationDate: '',
  });

  const [idx, setIdx] = useState();
  const currecy = [INR, USD];

  useEffect(() => {
    const getCategories = async () => {
      const response = await CompanyApi.getCategoriesData(user._id);
      const employeyeesResponse = await CompanyApi.getEmployeesData(user._id);
      setCategoriesData(response.data);
      setEmployeesData(employeyeesResponse.data);
      setSelectedValue(response.data[0]?.categoryName);
      setSelectedData(response.data[0] ? response.data[0] : '');
      setSelectEmp(employeyeesResponse?.data[0]?._id);

      if (response.data[0]) {
        const category = response.data[0];
        const hardwareObj = {};
        hardwareObj['categoryName'] = category.categoryName;
        hardwareObj['categoryId'] = category._id;
        for (let i = 0; i < category.content.length; i++) {
          hardwareObj[category.content[i]?.field] = '';
        }

        setTimeout(() => {
          setFormData({ ...formData, hardwareData: hardwareObj });
        }, 1000);
      }
    };

    if (user.role === 'ADMIN' || user.role === 'MANAGER') getCategories();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  useEffect(() => {
    const getCompnay = async () => {
      const response = await CompanyApi.getData(user._id);
      setIdx(
        currecy.findIndex((item) => {
          return item.toString().includes(response.data.currency);
        })
      );
    };
    getCompnay();
  }, []);

  const handleChange = (e) => {
    let isvalid = true;
    Object.values(formData).forEach((val) => {
      if (val === '') {
        isvalid = false;
      }
    });
    if (e.target.value === '') {
      isvalid = false;
    }
    if (e.target.name === 'status' && e.target.value !== 'Assigned') {
      const updateArr = formData.allocationHist;
      for (let i = 0; i < updateArr.length; i++) {
        if (updateArr[i].deallocationDate === '') {
          alert('Deallocate asset to change the status.');
          return;
        }
      }
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    let isvalid = true;

    if (isvalid) {
      const newData = {
        userId: user._id,
        assetData: formData,
        empAssigned: empAssignedAssets,
      };
      if (formData.status === 'Assigned') {
        let letsubmit = true;
        const updateArr = formData.allocationHist;
        for (let i = 0; i < updateArr.length; i++) {
          if (updateArr[i].deallocationDate === '') {
            letsubmit = true;
          }
        }
        if (letsubmit) {
          try {
            const imageData = new FormData();
            imageData.append('file', doc);
            imageData.append('newData', JSON.stringify(newData));
            const response = await AssetApi.createAsset(imageData);
            console.log('response', response);
            if (response.status === 200) {
              alert('Asset Created Successfully!');
            }
          } catch (error) {
            console.log(error);
            if (error.response.status === 500) alert('Error!');
            else if (error.response.status === 403)
              alert('You do not have access!');
            else alert('ERROR! Asset not created.');
          }
        } else {
          alert('Please assign asset to an employee or change the status!');
          return;
        }
      } else {
        alert('Please assign asset to an employee or change the status!');
      }
    }
  };

  const handleCategoryChange = (e) => {
    const category = categoriesData.find(
      (item) => item.categoryName === e.target.value
    );
    setSelectedData(category);
    setSelectedValue(e.target.value);
    const hardwareObj = {};
    hardwareObj['categoryName'] = category.categoryName;
    hardwareObj['categoryId'] = category._id;
    for (let i = 0; i < category.content.length; i++) {
      hardwareObj[category.content[i]?.field] = '';
    }
    setFormData({ ...formData, hardwareData: hardwareObj });
  };
  const handleHardwareDataChange = (e) => {
    const hardwareObj = formData.hardwareData;
    hardwareObj[e.target.name] = e.target.value;
    setFormData({ ...formData, hardwareData: hardwareObj });
  };
  const handleServiceArrChange = (e) => {
    setServiceData({ ...serviceData, [e.target.name]: e.target.value });
  };
  const handleServiceSave = (e) => {
    const updateArr = formData.serviceArr;
    updateArr.unshift(serviceData);
    setFormData({ ...formData, serviceArr: updateArr });
    setServiceData({
      serviceDesc: '',
      serviceCost: '',
      serviceWarranty: '',
      serviceDate: '',
      serviceDocument: '',
    });
    setModalOpened(false);
  };
  const handleServiceClose = (e) => {
    setModalOpened(false);
    setServiceData({
      serviceDesc: '',
      serviceCost: '',
      serviceWarranty: '',
      serviceDate: '',
      serviceDocument: '',
    });
  };

  const handleAllocationChange = (e) => {
    setAllocationData({ ...allocationData, [e.target.name]: e.target.value });
  };

  const handleAllocationClose = (e) => {
    setModalUserOpened(false);
    setAllocationData({
      empId: '',
      name: '',
      email: '',
      department: '',
      allocationDate: '',
      deallocationDate: '',
      location: '',
      phone: '',
    });
  };

  const handleSelectEmp = (e) => {
    e.preventDefault();
    const empdata = employeesData.find((item) => item._id === selectEmp);

    const newObj = {
      empId: empdata.empId,
      name: empdata.name,
      email: empdata.email,
      department: empdata.department,
      allocationDate: allocationData.allocationDate,
      deallocationDate: allocationData.deallocationDate,
      location: empdata.region,
      phone: empdata.phone,
    };
    const updateArr = formData.allocationHist;

    let shouldAdd = true; //it checks that if an employee is already in the allocation array and is still not deallocated then do not add it.
    for (let i = 0; i < updateArr.length; i++) {
      if (updateArr[i].deallocationDate === '') {
        if (newObj.deallocationDate === '') {
          shouldAdd = false;
          alert('Asset can be assigned to only one user at a time!');
          break;
        }
      }
    }

    if (shouldAdd === true) {
      updateArr.unshift(newObj);
      if (newObj.deallocationDate === '') {
        setFormData({
          ...formData,
          status: 'Assigned',
          allocationHist: updateArr,
        });
        setEmpAssignedAssets([
          ...empAssignedAssets,
          { empId: empdata.empId, _id: empdata._id },
        ]);
        setAllocationData({ allocationDate: '', deallocationDate: '' });
      } else {
        setFormData({
          ...formData,
          allocationHist: updateArr,
        });
      }
    }

    setSelectEmp(employeesData[0]._id);
    setModalUserOpened(false);
  };
  const handleDeallocate = (index) => (e) => {
    e.preventDefault();
    setDeallocateData({ id: index, date: '' });
    setDeallocateModal(true);
  };

  const handleDeallocateConfirm = async (e) => {
    e.preventDefault();

    if (deallocateData.date === '') {
      alert('Deallocation date cannot be empty!');
      return;
    }
    const updateAllocation = formData.allocationHist.map((item, index) => {
      if (index === deallocateData.id) {
        return { ...item, deallocationDate: deallocateData.date };
      } else {
        return item;
      }
    });

    setFormData({
      ...formData,
      allocationHist: updateAllocation,
      status: 'Unassigned',
    });
    setDeallocateModal(false);
  };

  const handleDeallocateClose = (e) => {
    e.preventDefault();
    setDeallocateModal(false);
    setDeallocateData({ id: '', date: '' });
  };

  return (
    <div className="computerForm sidebar-container">
      <Sidebar />
      <div>
        <Navbar pageHeader="Add Asset" />
        <form action="" className="computerAssetForm">
          <div className="formSection">
            <div className="formSection-header">
              <span>Basic Information</span>
              <img style={{ marginLeft: '0.5rem' }} src={Pen} alt="" />
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  placeholder="Type Description..."
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div>
                <label htmlFor="document">Document</label>
                <img
                  src={upload}
                  style={{ width: '1.5rem', marginLeft: '1rem' }}
                  alt=""
                />
                <input
                  type="file"
                  name="document"
                  style={{ opacity: 0, marginTop: '-2rem' }}
                  value={formData.document}
                  onChange={(e) => {
                    handleChange(e);
                    setDoc(e.target.files[0]);
                  }}
                />
              </div>
              <div>
                <label htmlFor="model">Model</label>
                <input
                  type="text"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="description">Serial Number</label>
                <input
                  type="text"
                  name="serialNum"
                  value={formData.serialNum}
                  onChange={handleChange}
                />
              </div>
              {/* <div>
                <label htmlFor="document">Warrany Terms</label>
                <input
                  type="text"
                  id="warrantyTerms"
                  name="warrantyTerms"
                  value={formData.warrantyTerms}
                  onChange={handleChange}
                />
              </div> */}
              <div>
                <label htmlFor="status">Warrenty Terms</label>
                <input
                  name="warrentyTerms"
                  id="warrentyTerms"
                  value={formData.warrentyTerms}
                  onChange={handleChange}
                ></input>
              </div>
              <div>
                <label htmlFor="status">Status</label>
                <select
                  name="status"
                  id="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value="Assigned">Assigned</option>
                  <option value="Unassigned">Unassigned</option>
                  <option value="Service">Service</option>
                  <option value="Scrapped">Scrapped</option>
                </select>
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Purchase Details</span>
              <img style={{ marginLeft: '0.5rem' }} src={Pen} alt="" />
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="purchaseDate">Purchase Date</label>
                <input
                  type="date"
                  id="purchaseDate"
                  name="purchaseDate"
                  value={formData.purchaseDate}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="purchaseValue">
                  Purchase Value (
                  <img
                    src={currecy[idx]}
                    style={{
                      width: '1.3rem',
                      marginBottom: '-0.23rem',
                      marginLeft: '-0.3rem',
                      marginRight: '-0.4rem',
                    }}
                  />
                  )
                </label>
                <input
                  type="text"
                  id="purchaseValue"
                  name="purchaseValue"
                  value={formData.purchaseValue}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="purchaseDate">Manufacturer</label>
                <input
                  type="text"
                  id="manufacturer"
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="warrantyPeriod">Warranty Period</label>
                <input
                  type="text"
                  id="warrantyPeriod"
                  name="warrantyPeriod"
                  value={formData.warrantyPeriod}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="dealerName">Dealer Name</label>
                <input
                  type="text"
                  id="dealerName"
                  name="dealerName"
                  value={formData.dealerName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="dealerNumber">Dealer Number</label>
                <input
                  type="number"
                  id="dealerNumber"
                  name="dealerNumber"
                  value={formData.dealerNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="depRateTax">
                  Dep Rate(Tax) (
                  <img
                    src={currecy[idx]}
                    style={{
                      width: '1.3rem',
                      marginBottom: '-0.23rem',
                      marginLeft: '-0.3rem',
                      marginRight: '-0.4rem',
                    }}
                  />
                  )
                </label>
                <input
                  type="text"
                  id="depRateTax"
                  name="depRateTax"
                  value={formData.depRateTax}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="depRateOther">
                  Dep Rate(Other) (
                  <img
                    src={currecy[idx]}
                    style={{
                      width: '1.3rem',
                      marginBottom: '-0.23rem',
                      marginLeft: '-0.3rem',
                      marginRight: '-0.4rem',
                    }}
                  />
                  )
                </label>
                <input
                  type="text"
                  id="depRateOther"
                  name="depRateOther"
                  value={formData.depRateOther}
                  onChange={handleChange}
                />
              </div>
              <div>{/*for space*/}</div>
              <div>
                <label htmlFor="depValueTax">
                  Dep Value(Tax) (
                  <img
                    src={currecy[idx]}
                    style={{
                      width: '1.3rem',
                      marginBottom: '-0.23rem',
                      marginLeft: '-0.3rem',
                      marginRight: '-0.4rem',
                    }}
                  />
                  )
                </label>
                <input
                  type="text"
                  id="depValueTax"
                  name="depValueTax"
                  value={(
                    (formData.depRateTax / 100) *
                    formData.purchaseValue
                  ).toFixed(2)}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="depValueOther">
                  Dep Value(Other) (
                  <img
                    src={currecy[idx]}
                    style={{
                      width: '1.3rem',
                      marginBottom: '-0.23rem',
                      marginLeft: '-0.3rem',
                      marginRight: '-0.4rem',
                    }}
                  />
                  )
                </label>
                <input
                  type="text"
                  id="depValueOther"
                  name="depValueOther"
                  value={(
                    (formData.depRateOther / 100) *
                    formData.purchaseValue
                  ).toFixed(2)}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="scrapValue">
                  Scrap Value (
                  <img
                    src={currecy[idx]}
                    style={{
                      width: '1.3rem',
                      marginBottom: '-0.23rem',
                      marginLeft: '-0.3rem',
                      marginRight: '-0.4rem',
                    }}
                  />
                  )
                </label>
                <input
                  type="text"
                  id="scrapValue"
                  name="scrapValue"
                  value={formData.scrapValue}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Hardware Details</span>
              <img style={{ marginLeft: '0.5rem' }} src={Pen} alt="" />
            </div>

            <div className="formSection-one grid">
              <div>
                <label htmlFor="categoryName">Category</label>
                <select
                  name="categoryName"
                  id="categoryName"
                  value={selectValue}
                  onChange={handleCategoryChange}
                >
                  {categoriesData?.map((item, index) => (
                    <option value={item.categoryName}>
                      {item.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="formSection-one grid">
              {selectData &&
                selectData.content &&
                selectData.content.map((item, index) => (
                  <div>
                    <label htmlFor="">{item.field}</label>
                    <input
                      type={item.fieldType}
                      name={item.field}
                      value={formData.hardwareData[item.field]}
                      onChange={handleHardwareDataChange}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Service & Repair </span>
              <img
                src={addOrangeSVG}
                onClick={() => setModalOpened(true)}
                className="addOrangeSVG"
                alt=""
              />
            </div>
            <div className="formSection-one table">
              {formData?.serviceArr.length > 0 ? (
                <table className="userTable computerForm-table">
                  <tr computerForm-row-header>
                    <th>Service Date</th>
                    <th>Service Cost </th>
                    <th>Warranty</th>
                    <th>Description</th>
                    <th>Document</th>
                  </tr>
                  {formData?.serviceArr.map((item, index) => (
                    <tr>
                      <td>{item.serviceDate}</td>
                      <td>{item.serviceCost}</td>
                      <td>{item.serviceWarranty}</td>
                      <td>{item.serviceDesc}</td>
                      <td>Document</td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>Click on Add Icon to add service data</p>
              )}
            </div>
            <Modal
              style={{
                content: { width: '40rem', padding: '0', marginLeft: '25rem' },
              }}
              isOpen={modalOpened}
              onRequestClose={() => setModalOpened(false)}
            >
              <div className="modalNav">Add Service Details</div>
              <form className="formModal">
                <div className="formSection-one grid">
                  <div>
                    <label htmlFor="serviceDate">Service Date</label>
                    <input
                      type="date"
                      id="serviceDate"
                      name="serviceDate"
                      value={serviceData.serviceDate}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceCost">
                      Service Cost (
                      <img
                        src={currecy[idx]}
                        style={{
                          width: '1.3rem',
                          marginBottom: '-0.23rem',
                          marginLeft: '-0.3rem',
                          marginRight: '-0.4rem',
                        }}
                      />
                      )
                    </label>
                    <input
                      type="number"
                      id="serviceCost"
                      name="serviceCost"
                      value={serviceData.serviceCost}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceWarranty">Warranty</label>
                    <input
                      type="text"
                      id="serviceWarranty"
                      name="serviceWarranty"
                      value={serviceData.serviceWarranty}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                </div>
                <div className="formSection-one grid">
                  <div>
                    <label htmlFor="serviceDesc">Description</label>
                    <input
                      type="text"
                      id="serviceDesc"
                      name="serviceDesc"
                      value={serviceData.serviceDesc}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceUploadDoc">Upload Document</label>
                    <img
                      src={upload}
                      style={{ width: '1.5rem', marginLeft: '2rem' }}
                      alt=""
                    />
                    <input
                      type="file"
                      id="serviceDocument"
                      name="serviceDocument"
                      style={{ opacity: 0, marginTop: '-2rem' }}
                      value={serviceData.serviceDocument}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                </div>
                <div className="modal-buttons">
                  <button
                    className="saveModal-button"
                    type="button"
                    onClick={handleServiceSave}
                  >
                    Save
                  </button>
                  <button
                    className="closeModal-button"
                    onClick={handleServiceClose}
                  >
                    Close
                  </button>
                </div>
              </form>
            </Modal>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Allocation Details </span>
              <img
                src={addOrangeSVG}
                onClick={() => setModalUserOpened(true)}
                className="addOrangeSVG"
                alt=""
              />
            </div>
            <div className="formSection-one table">
              {formData?.allocationHist.length > 0 ? (
                <table className="userTable computerForm-table">
                  <tr computerForm-row-header>
                    <th>Emp ID</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Location</th>
                    <th>Allocation Date</th>
                    <th>Deallocation Date</th>
                  </tr>
                  {formData?.allocationHist.map((item, index) => (
                    <tr>
                      <td>{item.empId}</td>
                      <td>{item.name}</td>
                      <td>{item.department}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.location}</td>
                      <td>{item.allocationDate}</td>
                      <td>
                        {item.deallocationDate === '' ? (
                          <td
                            style={{
                              // paddingLeft: "100%",
                              // paddingRight: "100%",
                              textAlign: 'center',
                              alignSelf: 'center',
                            }}
                          >
                            <button
                              onClick={handleDeallocate(index)}
                              className="userTable-delete"
                            >
                              Deallocte
                            </button>
                          </td>
                        ) : (
                          item.deallocationDate
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>Click on Add Icon to add service data</p>
              )}
            </div>

            <Modal
              isOpen={modalUserOpened}
              onRequestClose={() => setModalUserOpened(false)}
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.75)',
                },
				content: { width: '40rem', padding: '0', marginLeft: '25rem', overflow:'hidden' },
              }}
            >
			<div className="modalNav">Allocate Details</div>
              {employeesData?.length > 0 ? (
                <div className="company-selectModal">
                  <select
                    value={selectEmp}
                    onChange={(e) => setSelectEmp(e.target.value)}
                  >
                    {employeesData?.map((item) => {
                      return (
                        <option key={item.email} value={item._id}>
                          {item.name + ' (' + item.empId + ')'}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    className="formSection-one grid"
                    style={{
                      marginTop: '2rem',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div>
                      <label htmlFor="allocationDate">Allocation Date</label>
                      <input
                        type="date"
                        id="allocationDate"
                        name="allocationDate"
                        value={allocationData.allocationDate}
                        onChange={handleAllocationChange}
                      />
                    </div>
                  </div>
                  <button
                    className="company-button-changeOwner"
                    onClick={handleSelectEmp}
                    style={{ marginTop: '4rem', width:"8rem", borderRadius:"8px" }}
                    type="button"
                  >
                    Select
                  </button>
                  <button
                    className="companyModal-closebtn"
                    onClick={handleAllocationClose}
                  >
                    Close
                  </button>
                </div>
              ) : (
                'Add employees data To allocate assets.'
              )}
            </Modal>
            <Modal
              isOpen={deallocateModal}
              onRequestClose={() => setDeallocateModal(false)}
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.75)',
                },
                content: {
                  position: 'absolute',
                  top: '25%',
                  left: '25%',
                  right: '25%',
                  bottom: '25%',
                  border: '1px solid #ccc',
                  background: '#fff',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                  borderRadius: '40px',
                  outline: 'none',
                  padding: '2rem',
                },
              }}
            >
              <div
                className="formSection-one grid"
                style={{
                  marginTop: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <label htmlFor="deallocationDate">Deallocation Date</label>
                  <input
                    type="date"
                    id="deallocationDate"
                    name="deallocationDate"
                    value={deallocateData.date}
                    onChange={(e) =>
                      setDeallocateData({
                        ...deallocateData,
                        date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <button
                className="company-button-changeOwner"
                onClick={handleDeallocateConfirm}
                style={{ marginTop: '4rem' }}
                type="button"
              >
                Select
              </button>
              <button
                className="companyModal-closebtn"
                onClick={handleDeallocateClose}
              >
                Close
              </button>
            </Modal>
          </div>

          <div className="createUser-buttons" style={{ marginLeft: '6rem' }}>
            <button
              type="button"
              className="createUser-button-submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <Link to="../home">
              <button
                type="button"
                className="createUser-button-cancel"
                // onClick={handlecl}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssetForm;
