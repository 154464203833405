import React, { useState, useEffect } from "react";
import addOrangeSVG from "../../img/addOrangeSVG.svg";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import "../AssetForms/AssetForm.css";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import * as CompanyApi from "../../api/CompanyRequest";
import * as AssetApi from "../../api/AssetRequest";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const HomePageEdit = ({ editFormData, editAssetId, setEditForm }) => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const navigate = useNavigate();
  const [modalOpened, setModalOpened] = useState(false);
  const [modalUserOpened, setModalUserOpened] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [selectValue, setSelectedValue] = useState();
  const [selectData, setSelectedData] = useState();
  const [employeesData, setEmployeesData] = useState();
  const [selectEmp, setSelectEmp] = useState();
  const [empAssignedAssets, setEmpAssignedAssets] = useState([]);
  const [deallocateData, setDeallocateData] = useState({
    id: "",
    date: "",
  });
  const [deallocateModal, setDeallocateModal] = useState(false);

  const [formData, setFormData] = useState({
    description: editFormData?.description,
    document: editFormData?.document,
    model: editFormData?.model,
    serialNum: editFormData?.serialNum,
    warrantyTerms: editFormData?.warrantyTerms,
    status: editFormData?.status,
    purchaseDate: editFormData?.purchaseDate,
    purchaseValue: editFormData?.purchaseValue,
    manufacturer: editFormData?.manufacturer,
    warrantyPeriod: editFormData?.warrantyPeriod,
    dealerName: editFormData?.dealerName,
    dealerNumber: editFormData?.dealerNumber,
    depRateTax: editFormData?.depRateTax,
    depRateOther: editFormData?.depRateOther,
    hardwareData: editFormData?.hardwareData,
    serviceArr: editFormData?.serviceArr,
    allocationHist: editFormData?.allocationHist,
  });
  const [serviceData, setServiceData] = useState({
    serviceDesc: "",
    serviceCost: "",
    serviceWarranty: "",
    serviceDate: "",
    serviceDoc: "",
  });
  const [allocationData, setAllocationData] = useState({
    allocationDate: "",
    deallocationDate: "",
  });

  useEffect(() => {
    const getCategories = async () => {
      const response = await CompanyApi.getCategoriesData(user._id);
      const employeyeesResponse = await CompanyApi.getEmployeesData(user._id);
      setEmployeesData(employeyeesResponse.data);

      setSelectEmp(employeyeesResponse?.data[0]?._id);
      setCategoriesData(response.data);
      //   console.log(response.data);
      const currCategory = response.data.find(
        (item) => item._id === editFormData.hardwareData.categoryId
      );

      setSelectedValue(currCategory.categoryName);
      setSelectedData(currCategory);

      if (currCategory) {
        const category = currCategory;
        const hardwareObj = {};
        hardwareObj["categoryName"] = category.categoryName;
        hardwareObj["categoryId"] = category._id;
        for (let i = 0; i < category.content.length; i++) {
          if (editFormData.hardwareData[category.content[i].field])
            hardwareObj[category.content[i]?.field] =
              editFormData.hardwareData[category.content[i].field];
          else hardwareObj[category.content[i]?.field] = "";
        }
        setFormData({ ...formData, hardwareData: hardwareObj });
      }
    };

    if (user.role === "ADMIN" || user.role === "MANAGER") getCategories();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  console.log(editFormData);

  const handleChange = (e) => {
    let isvalid = true;
    Object.values(formData).forEach((val) => {
      if (val === "") {
        isvalid = false;
      }
    });
    if (e.target.value === "") {
      isvalid = false;
    }
    if (e.target.name === "status" && e.target.value !== "ASSIGNED") {
      const updateArr = formData.allocationHist;
      for (let i = 0; i < updateArr.length; i++) {
        if (updateArr[i].deallocationDate === "") {
          alert("Deallocate asset to change the status.");
          return;
        }
      }
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isvalid = true;
    // Object.values(formData).forEach((val) => {
    //   if (val === "") {
    //     isvalid = false;
    //   }
    // });

    if (isvalid) {
      const newData = {
        userId: user._id,
        assetData: formData,
        assetId: editAssetId,
      };
      if (formData.status === "ASSIGNED") {
        let letsubmit = false;
        const updateArr = formData.allocationHist;
        for (let i = 0; i < updateArr.length; i++) {
          if (updateArr[i].deallocationDate === "") {
            letsubmit = true;
          }
        }
        if (letsubmit) {
          try {
            const response = await AssetApi.editAsset(newData);
            console.log(response);
            if (response.status === 200) {
              //   alert("Asset Created Successfully!");
            }
          } catch (error) {
            console.log(error);
            if (error.response.status === 500) alert("Error!");
            else if (error.response.status === 403)
              alert("You do not have access!");
            else alert("ERROR! Asset not created.");
          }
          setEditForm(false);
          window.location.reload(false);
        } else {
          alert("Please assign asset to an employee or change the status!");
          return;
        }
      }
      try {
        const response = await AssetApi.editAsset(newData);
        console.log(response);
        if (response.status === 200) {
          //   alert("Asset Created Successfully!");
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 500) alert("Error!");
        else if (error.response.status === 403)
          alert("You do not have access!");
        else alert("ERROR! Asset not created.");
      }
      setEditForm(false);
      window.location.reload(false);
    }
  };

  const handleCategoryChange = (e) => {
    const category = categoriesData.find(
      (item) => item.categoryName === e.target.value
    );
    setSelectedData(category);
    setSelectedValue(e.target.value);
    const hardwareObj = {};
    hardwareObj["categoryName"] = category.categoryName;
    hardwareObj["categoryId"] = category._id;
    for (let i = 0; i < category.content.length; i++) {
      hardwareObj[category.content[i]?.field] = "";
    }

    setFormData({ ...formData, hardwareData: hardwareObj });
  };
  const handleHardwareDataChange = (e) => {
    const hardwareObj = formData.hardwareData;
    hardwareObj[e.target.name] = e.target.value;
    setFormData({ ...formData, hardwareData: hardwareObj });
  };
  const handleServiceArrChange = (e) => {
    // const serviceObj = formData.serviceArr;
    // serviceObj[e.target.name] = e.target.value;

    setServiceData({ ...serviceData, [e.target.name]: e.target.value });
  };
  const handleServiceSave = (e) => {
    const updateArr = formData.serviceArr;
    updateArr.push(serviceData);
    setFormData({ ...formData, serviceArr: updateArr });
    setServiceData({
      serviceDesc: "",
      serviceCost: "",
      serviceWarranty: "",
      serviceDate: "",
      serviceDoc: "",
    });
    setModalOpened(false);
  };
  const handleServiceClose = (e) => {
    setModalOpened(false);
    setServiceData({
      serviceDesc: "",
      serviceCost: "",
      serviceWarranty: "",
      serviceDate: "",
      serviceDoc: "",
    });
  };

  const handleAllocationChange = (e) => {
    setAllocationData({ ...allocationData, [e.target.name]: e.target.value });
  };

  // const handleAllocationSave = (e) => {
  //   const updateArr = formData.allocationHist;
  //   updateArr.push(allocationData);
  //   setFormData({ ...formData, allocationHist: updateArr });
  //   setAllocationData({
  //     empId: "",
  //     name: "",
  //     email: "",
  //     department: "",
  //     allocationDate: "",
  //     deallocationDate: "",
  //     location: "",
  //     phone: "",
  //   });
  //   setModalUserOpened(false);
  // };

  const handleAllocationClose = (e) => {
    setModalUserOpened(false);
    setAllocationData({
      empId: "",
      name: "",
      email: "",
      department: "",
      allocationDate: "",
      deallocationDate: "",
      location: "",
      phone: "",
    });
  };

  const handleSelectEmp = (e) => {
    e.preventDefault();
    const empdata = employeesData.find((item) => item._id === selectEmp);

    const newObj = {
      empId: empdata.empId,
      name: empdata.name,
      email: empdata.email,
      department: empdata.department,
      allocationDate: allocationData.allocationDate,
      deallocationDate: allocationData.deallocationDate,
      location: empdata.region,
      phone: empdata.phone,
    };
    const updateArr = formData.allocationHist;

    let shouldAdd = true; //it checks that if an employee is already in the allocation array and is still not deallocated then do not add it.

    //This for loop check that if there is already an unaccocated asset entry in allocatedHist : if there is and the current entry is also unallocted then set shouldAdd=false else let it staty true
    for (let i = 0; i < updateArr.length; i++) {
      if (updateArr[i].deallocationDate === "") {
        if (newObj.deallocationDate === "") {
          shouldAdd = false;
          alert("Asset can be assigned to only one user at a time!");
          break;
        }
      }
    }

    // for (let i = 0; i < updateArr.length; i++) {
    //   if (updateArr[i].empId === empdata?.empId) {
    //     if (updateArr[i].deallocationDate === "") {
    //       if (newObj.deallocationDate !== "") continue;
    //       else {
    //         shouldAdd = false;
    //         alert(
    //           "Asset could not be assigned to same employee more than once!"
    //         );
    //         break;
    //       }
    //     }
    //   }
    // }

    if (shouldAdd === true) {
      updateArr.unshift(newObj);
      if (newObj.deallocationDate === "") {
        setFormData({
          ...formData,
          status: "ASSIGNED",
          allocationHist: updateArr,
        });
        setEmpAssignedAssets([
          ...empAssignedAssets,
          { empId: empdata.empId, _id: empdata._id },
        ]);
        setAllocationData({ allocationDate: "", deallocationDate: "" });
      } else {
        setFormData({
          ...formData,
          allocationHist: updateArr,
        });
      }

      // if (newObj.deallocationDate === "") {
      //   setFormData({ ...formData, status: "ASSIGNED" });
      //   setEmpAssignedAssets([
      //     ...empAssignedAssets,
      //     { empId: empdata.empId, _id: empdata._id },
      //   ]);
      // }
    }

    setSelectEmp(employeesData[0]._id);
    setModalUserOpened(false);
  };
  const handleDeallocate = (index) => (e) => {
    e.preventDefault();
    setDeallocateData({ id: index, date: "" });
    console.log(index);
    setDeallocateModal(true);
  };

  const handleDeallocateConfirm = async (e) => {
    e.preventDefault();
    console.log(deallocateData.date);
    if (deallocateData.date === "") {
      alert("Deallocation date cannot be empty!");
      return;
    }
    const updateAllocation = formData.allocationHist.map((item, index) => {
      if (index === deallocateData.id) {
        return { ...item, deallocationDate: deallocateData.date };
      } else {
        return item;
      }
    });

    console.log(updateAllocation);

    setFormData({
      ...formData,
      allocationHist: updateAllocation,
      status: "Unassigned",
    });
    setDeallocateModal(false);
  };

  const handleDeallocateClose = (e) => {
    e.preventDefault();
    setDeallocateModal(false);
    setDeallocateData({ id: "", date: "" });
  };
  
  const handleDeleteS3Object = (e) => {
    e.preventDefault();
    try{
      const res = axios.get(`${process.env.REACT_APP_BASE_URL}/asset/${user._id}/deleteimage/${editFormData?._id}`);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Document Deleted Successfully!",
      });
    }catch(err){
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      
    }
  }
  return (
    <div className="computerForm sidebar-container">
      <Sidebar />
      <div>
        <Navbar pageHeader="Edit Asset" />
        <form action="" className="computerAssetForm">
          <div className="formSection">
            <div className="formSection-header">
              <span>Basic Information</span>
              {/* <img src={editIcon} alt="" /> */}
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  placeholder="Type Description..."
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div>
                <label htmlFor="document">Document</label>
                <div style={{display:'flex', flexDirection:'row'}}>
                <button style={{display:'flex', justifyContent:'center',width:'6rem', paddingTop:'0.4rem', paddingBottom:'0.4rem', marginLeft:'-3.5rem'}}><a href={`${process.env.REACT_APP_BASE_URL}/asset/${user._id}/getimage/${editFormData?._id}`} style={{textDecoration:'none', color:'white'}} target='_blank'>View</a></button>
                <button onClick={handleDeleteS3Object} style={{backgroundColor:'#222',display:'flex', justifyContent:'center',width:'6rem', paddingTop:'0.4rem', paddingBottom:'0.4rem'}}>Delete</button>
                </div>
              </div>
              <div>
                <label htmlFor="model">Model</label>
                <input
                  type="text"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="description">Serial Number</label>
                <input
                  type="text"
                  name="serialNum"
                  value={formData.serialNum}
                  onChange={handleChange}
                />
              </div>  
              <div>
                <label htmlFor="status">Status</label>
                <select
                  name="status"
                  id="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value="ASSIGNED">Assigned</option>
                  <option value="Unassigned">Unassigned</option>
                  <option value="Inservice">Service</option>
                  <option value="Scrapped">Scrapped</option>
                </select>
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Purchase Details</span>
              {/* <img src={editIcon} alt="" /> */}
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="purchaseDate">Purchase Date</label>
                <input
                  type="date"
                  id="purchaseDate"
                  name="purchaseDate"
                  value={formData.purchaseDate}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="purchaseValue">Purchase Value</label>
                <input
                  type="text"
                  id="purchaseValue"
                  name="purchaseValue"
                  value={formData.purchaseValue}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="purchaseDate">Manufacturer</label>
                <input
                  type="text"
                  id="manufacturer"
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="warrantyPeriod">Warranty Period</label>
                <input
                  type="text"
                  id="warrantyPeriod"
                  name="warrantyPeriod"
                  value={formData.warrantyPeriod}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="dealerName">Dealer Name</label>
                <input
                  type="text"
                  id="dealerName"
                  name="dealerName"
                  value={formData.dealerName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="dealerNumber">Dealer Number</label>
                <input
                  type="number"
                  id="dealerNumber"
                  name="dealerNumber"
                  value={formData.dealerNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="formSection-one grid">
              <div>
                <label htmlFor="depRateTax">Dep Rate(Tax)</label>
                <input
                  type="text"
                  id="depRateTax"
                  name="depRateTax"
                  value={formData.depRateTax}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="depRateOther">Dep Rate(Other)</label>
                <input
                  type="text"
                  id="depRateOther"
                  name="depRateOther"
                  value={formData.depRateOther}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Hardware Details</span>
              {/* <img src={editIcon} alt="" /> */}
            </div>

            <div className="formSection-one grid">
              <div>
                <label htmlFor="categoryName">Category</label>
                <select
                  name="categoryName"
                  id="categoryName"
                  value={selectValue}
                  onChange={handleCategoryChange}
                >
                  {categoriesData?.map((item, index) => (
                    <option value={item.categoryName}>
                      {item.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="formSection-one grid">
              {selectData?.content.map((item, index) => (
                <div>
                  <label htmlFor="">{item.field}</label>
                  <input
                    type={item.fieldType}
                    name={item.field}
                    value={formData.hardwareData[item.field]}
                    onChange={handleHardwareDataChange}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Service & Repair </span>
              <img
                src={addOrangeSVG}
                onClick={() => setModalOpened(true)}
                className="addOrangeSVG"
                alt=""
              />
            </div>
            <div className="formSection-one table">
              {formData?.serviceArr?.length > 0 ? (
                <table className="userTable computerForm-table">
                  <tr computerForm-row-header>
                    <th>Service Date</th>
                    <th>Service Cost</th>
                    <th>Warranty</th>
                    <th>Description</th>
                    <th>Document</th>
                  </tr>
                  {formData?.serviceArr.map((item, index) => (
                    <tr>
                      <td>{item.serviceDate}</td>
                      <td>{item.serviceCost}</td>
                      <td>{item.serviceWarranty}</td>
                      <td>{item.serviceDesc}</td>
                      <td>{item.serviceDoc}</td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>Click on Add Icon to add service data</p>
              )}
            </div>
            <Modal
              isOpen={modalOpened}
              onRequestClose={() => setModalOpened(false)}
            >
              <form className="formModal">
                <h2>Add Service Details</h2>
                <div className="formSection-one grid">
                  <div>
                    <label htmlFor="serviceDate">Service Date</label>
                    <input
                      type="date"
                      id="serviceDate"
                      name="serviceDate"
                      value={serviceData.serviceDate}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceCost">Service Cost</label>
                    <input
                      type="number"
                      id="serviceCost"
                      name="serviceCost"
                      value={serviceData.serviceCost}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceWarranty">Warranty</label>
                    <input
                      type="text"
                      id="serviceWarranty"
                      name="serviceWarranty"
                      value={serviceData.serviceWarranty}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                </div>
                <div className="formSection-one grid">
                  <div>
                    <label htmlFor="serviceDesc">Description</label>
                    <input
                      type="text"
                      id="serviceDesc"
                      name="serviceDesc"
                      value={serviceData.serviceDesc}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="serviceUploadDoc">Upload Document</label>
                    <input
                      type="file"
                      id="serviceDocument"
                      name="serviceDoc"
                      value={serviceData.serviceDoc}
                      onChange={handleServiceArrChange}
                    />
                  </div>
                </div>
                <div className="modal-buttons">
                  <button
                    className="saveModal-button"
                    type="button"
                    onClick={handleServiceSave}
                  >
                    Save
                  </button>
                  <button
                    className="closeModal-button"
                    onClick={handleServiceClose}
                  >
                    Close
                  </button>
                </div>
              </form>
            </Modal>
          </div>

          <div className="formSection">
            <div className="formSection-header">
              <span>Allocation Detials </span>
              <img
                src={addOrangeSVG}
                onClick={() => setModalUserOpened(true)}
                className="addOrangeSVG"
                alt=""
              />
            </div>
            <div className="formSection-one table">
              {formData?.allocationHist?.length > 0 ? (
                <table className="userTable computerForm-table">
                  <tr computerForm-row-header>
                    <th>Emp ID</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Location</th>
                    <th>Allocation Date</th>
                    <th>Deallocation Date</th>
                  </tr>
                  {formData?.allocationHist.map((item, index) => (
                    <tr>
                      <td>{item.empId}</td>
                      <td>{item.name}</td>
                      <td>{item.department}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.location}</td>
                      <td>{item.allocationDate}</td>
                      <td>
                        {item.deallocationDate === "" ? (
                          <td>
                            <button
                              type="button"
                              className="userTable-delete"
                              onClick={handleDeallocate(index)}
                            >
                              Deallocte
                            </button>
                          </td>
                        ) : (
                          item.deallocationDate
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>Click on Add Icon to add service data</p>
              )}
            </div>
            <Modal
              isOpen={modalUserOpened}
              onRequestClose={() => setModalUserOpened(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {
                  position: "absolute",
                  top: "15%",
                  left: "25%",
                  right: "25%",
                  bottom: "25%",
                  border: "1px solid #ccc",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "40px",
                  outline: "none",
                  padding: "2rem",
                },
              }}
            >
              {employeesData?.length > 0 ? (
                <div className="company-selectModal">
                  <select
                    value={selectEmp}
                    onChange={(e) => setSelectEmp(e.target.value)}
                  >
                    {employeesData?.map((item) => {
                      return (
                        <option key={item.email} value={item._id}>
                          {item.name + " (" + item.empId + ")"}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    className="formSection-one grid"
                    style={{
                      marginTop: "2rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <label htmlFor="allocationDate">Allocation Date</label>
                      <input
                        type="date"
                        id="allocationDate"
                        name="allocationDate"
                        value={allocationData.allocationDate}
                        onChange={handleAllocationChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="deallocationDate">
                        Deallocation Date
                      </label>
                      <input
                        type="date"
                        id="deallocationDate"
                        name="deallocationDate"
                        value={allocationData.deallocationDate}
                        onChange={handleAllocationChange}
                      />
                    </div>
                  </div>
                  <button
                    className="company-button-changeOwner"
                    onClick={handleSelectEmp}
                    style={{ marginTop: "4rem" }}
                    type="button"
                  >
                    Select
                  </button>
                  <button
                    className="companyModal-closebtn"
                    onClick={handleAllocationClose}
                  >
                    Close
                  </button>
                </div>
              ) : (
                "Add employees data To allocate assets."
              )}
            </Modal>
            <Modal
              isOpen={deallocateModal}
              onRequestClose={() => setDeallocateModal(false)}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                },
                content: {
                  position: "absolute",
                  top: "25%",
                  left: "25%",
                  right: "25%",
                  bottom: "25%",
                  border: "1px solid #ccc",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "40px",
                  outline: "none",
                  padding: "2rem",
                },
              }}
            >
              <div
                className="formSection-one grid"
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <label htmlFor="deallocationDate">Deallocation Date</label>
                  <input
                    type="date"
                    id="deallocationDate"
                    name="deallocationDate"
                    value={deallocateData.date}
                    onChange={(e) =>
                      setDeallocateData({
                        ...deallocateData,
                        date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <button
                className="company-button-changeOwner"
                onClick={handleDeallocateConfirm}
                style={{ marginTop: "4rem" }}
                type="button"
              >
                Select
              </button>
              <button
                className="companyModal-closebtn"
                onClick={handleDeallocateClose}
              >
                Close
              </button>
            </Modal>
          </div>
          <div className="createUser-buttons" style={{ marginLeft: "6rem" }}>
            <button
              type="button"
              className="createUser-button-submit"
              onClick={handleSubmit}
            >
              Update
            </button>

            <Link to="../home">
              <button
                type="button"
                className="createUser-button-cancel"
                onClick={() => setEditForm(false)}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HomePageEdit;
