import SidebarItem from "./SidebarItem";
import items from "../../data/SidebarData.json";
import { useSelector } from 'react-redux';
import superAdminItems from "../../data/SuperAdminSideBar.json";
import logo from "../../img/tenie_logo.svg";
import "./Sidebar.css";
export default function Sidebar() {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  return (
    <div className="sidebarContainer">
      <img src={logo} className="sidebarContainer-logo" alt="tenie logo" />
      <div className="sidebar">
        {user.role !== 'SUPER ADMIN' && items.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
        {user.role === 'SUPER ADMIN' && superAdminItems.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
}
