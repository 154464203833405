import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { read, utils, writeFile } from "xlsx";
import * as XLSX from "xlsx";
// import downArrow from "../../img/downArrow.svg";
import sortSign from "../../img/sortSign.svg";
import personLogo from "../../img/createUserField.svg";
import Modal from "react-modal";
import "./Roles.css";
// import { set } from "react-hook-form";
// import { formatMs } from "@material-ui/core";

const Roles = () => {
  return (
    <div className="roles sidebar-container">
      <Sidebar />
      <div className="homeRight">
        <Navbar pageHeader="Roles Info" />

        <div className="roles-header">
          <p>
            This section describes different user roles available for assignment
          </p>
        </div>
        <div>
          <table className="userTable roles-table">
            <tr className="userTable-row-header rolesheader" >
              <th style={{backgroundColor:'#E4E3E3'}}>S.No.</th>
              <th style={{backgroundColor:'#E4E3E3'}}>Role</th>
              <th style={{ width: "60%",backgroundColor:'#E4E3E3' }}>Description</th>
            </tr>

            <tr className="roles-table-body">
              <td>1</td>
              <td>Admin</td>
              <td>Full access to all areas of the application.</td>
            </tr>
            <tr className="roles-table-body">
              <td>2</td>
              <td>Manager</td>
              <td>
                Full access to all areas of the application, except user
                management. In user management, he can see all the users and
                crate new users for the role of team or Analytics. Can’t delete
                or edit any user.
              </td>
            </tr>
            <tr className="roles-table-body">
              <td>3</td>
              <td>Team</td>
              <td>
                Access to Dashboard, Assets, Status & Reports modules. In
                assets, user can create new asset, add new service, allocate to
                a user, and change asset status. User can’t delete/remove any
                asset or any section thereof. Settings module is hidden.
              </td>
            </tr>
            <tr className="roles-table-body">
              <td>4</td>
              <td>Aanlytics</td>
              <td>
                View only access to Dashboard, Assets, Status & Reports modules.
                Settings module is hidden.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Roles;
