import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as CompanyApi from '../../../api/CompanyRequest';
import Navbar from '../../Navbar/Navbar';
import Sidebar from '../../Sidebar/Sidebar';
import LockImg from '../../../img/LockImg.svg';
import './InstanceDetails.css';
import Delete from '../../../img/delete.svg';
import * as API from '../../../api/CompanyRequest';
import * as UserAPI from '../../../api/UserRequest';
const InstanceDetails = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [companyDetails, setCompanyDetails] = useState([]);
  let cnt = 0;
  const deleteCompany = async (data) => {
    try {
      const res = await API.deleteCompanyBySuperAdmin(user._id, data);
      console.log(res.data);
      alert('Company Deleted Successfully');
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = (e, data) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this company?')) {
      const sendData = { companyId: data };
      deleteCompany(sendData);
    }
  };
  const handleReset = (e, data) => {
    e.preventDefault();
    const newPassword = prompt('Please enter a new password');
    if (window.confirm('Are you sure you want to save the new password?')) {
      const sendData = { companyId: data, newPassword: newPassword };
      resetPassword(sendData);
    }
  };
  const resetPassword = async (data) => {
    try {
      const res = await UserAPI.resetPasswordByCompany(user._id, data);
      console.log(res.data);
      alert('Password reset Successfully');
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const fetchCompanies = async () => {
      const res = await CompanyApi.getCompaniesForSuperAdmin(user._id);
      setCompanyDetails(res.data);
    };
    fetchCompanies();
  }, []);
  if (user.role !== 'SUPER ADMIN') {
    return (
      <h1
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Access Forbidden
      </h1>
    );
  } else {
    return (
      <div className="instanceProfile">
        <Sidebar />
        <div className="">
          <Navbar pageHeader="Tenie ALM Super Admin | Instance Details " />
          <div className="instancetitleBar">
            <div id="sNo">S No.</div>
            <div id="instanceIdTitle">Instance ID</div>
            <div>Created On</div>
            <div>Company </div>
            <div>No of Users</div>
            <div>API Runs</div>
            <div>Storage Used</div>
            <div>Last Accessed</div>
            <div className="instancecontainIcons">Reset Password</div>
            <div className="instancecontainIcons">Delete</div>
          </div>
          <div className="instanceDetails">
            {companyDetails &&
              companyDetails.map((company) => {
                cnt++;
                return (
                  <div className={cnt % 2 === 0 ? 'even row' : 'odd row'}>
                    <div id="sNoData">{cnt}</div>
                    <div id="instanceId">{company._id}</div>
                    <div>{company.createdAt.substring(0, 10)}</div>
                    <div>{company.companyName}</div>
                    <div>{company.employees.length + 1}</div>
                    <div>-</div>
                    <div>
                      {(company.storageUsed / (1024 * 1024)).toFixed(2)} MB
                    </div>
                    <div>
                      {company.lastAccessed
                        ? company.lastAccessed
                        : 'Not yet accessed'}
                    </div>
                    <div className="containIcons">
                      <img
                        src={LockImg}
                        onClick={(event) => handleReset(event, company._id)}
                        alt="reset password"
                        srcSet=""
                      />
                    </div>
                    <div className="containIcons">
                      <img
                        src={Delete}
                        onClick={(event) => handleDelete(event, company._id)}
                        alt="delete"
                        srcSet=""
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
};

export default InstanceDetails;
