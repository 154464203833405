/* eslint-disable */
import React from 'react';
import './ReportDetails.css';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Calendar from '../../img/Calendar.svg';
import DropDown from '../../img/DropDown.svg';
import Settings from '../../img/Settings.svg';
import PrinterLogo from '../../img/Print.svg';
import sortSign from '../../img/sortSign.svg';
import { useSelector } from 'react-redux';
import * as AssetApi from '../../api/AssetRequest';
import * as CompanyApi from '../../api/CompanyRequest';
import * as numberConverter from '../../Utils/numberFormatConverter';
import * as dateConverter from '../../Utils/dateFormat';
import XLSX from 'sheetjs-style';
import INR from '../../img/currencies/INR.png';
import USD from '../../img/currencies/USD.png';
import exportImg from '../../img/export.svg';

const ReportDetails = (props) => {
  // use-selector
  const user = useSelector((state) => state.authReducer.authData?.userData);

  //use-states
  const currecy = [INR, USD];
  const [idx, setIdx] = React.useState();
  const [date, setDate] = React.useState('Today');
  const [calenderDate, setCalenderDate] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [numberFormat, setNumberFormat] = React.useState(false);
  const [dateFormat, setDateFormat] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const [sortByCapitalCost, setSortByCapitalCost] = React.useState(false);
  const [sortByCategory, setSortByCategory] = React.useState(false);
  const [sortByStatus, setSortByStatus] = React.useState(false);
  const [sortByCount, setSortByCount] = React.useState(false);
  const [sortByRepair, setSortByRepair] = React.useState(false);

  //function
  const sliceData = (data) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  //regular variables
  let cnt = 0;
  const exportableData = [];
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  //use-effects
  React.useEffect(() => {
    const getCategories = async () => {
      const response = await AssetApi.getAllAssets(user._id);
      const dataByCategory = response.data.reduce((acc, cur) => {
        const category = cur.hardwareData.categoryName;
        const status = cur.status;
        if (!acc[category]) {
          acc[category] = {};
        }
        if (!acc[category][status]) {
          acc[category][status] = [];
        }
        acc[category][status].push(cur);
        return acc;
      }, {});

      Object.entries(dataByCategory).map(([category, item]) => {
        Object.entries(item).map(([status, info]) => {
          let capitalCost = 0;
          let serviceCost = 0;
          let scrappedValue = 0;
          info.map((data) => {
            if (data.purchaseValue) capitalCost += parseInt(data.purchaseValue);
            if (data.scrapValue) scrappedValue += parseInt(data.scrapValue);
            data.serviceArr.map((service) => {
              serviceCost += parseInt(service.serviceCost);
            });
          });
          exportableData.push({
            Category: category,
            Status: status,
            Count: info.length,
            CapitalCost: capitalCost,
            ServiceCost: serviceCost,
            ScrappedValue: scrappedValue,
          });
        });
      });

      console.log(exportableData);
      setExportData([...exportableData]);
    };
    getCategories();
  }, []);

  React.useEffect(() => {
    const getCompnay = async () => {
      const response = await CompanyApi.getData(user._id);
      console.log(response.data);
      if (response.data.numberFormat === 'indian') {
        setNumberFormat(true);
      }
      if (response.data.dateFormat === 'dd-mm-yyyy') {
        setDateFormat(true);
      }
      setIdx(
        currecy.findIndex((item) => {
          console.log(item);
          console.log(response.data.currency);
          return item.toString().includes(response.data.currency);
        })
      );
    };
    getCompnay();
  }, []);

  //fuctions
  const handleExport = async () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'AssetReport' + fileExtension);
  };

  // sorting functions
  const handleSortByCapitalCost = () => {
    if (!sortByCapitalCost) {
      const sortedData = exportData.sort(
        (a, b) => a.CapitalCost - b.CapitalCost
      );
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort(
        (a, b) => b.CapitalCost - a.CapitalCost
      );
      setExportData(sortedData);
    }
    setSortByCapitalCost(!sortByCapitalCost);
  };

  const handleSortByCategory = () => {
    if (!sortByCategory) {
      const sortedData = exportData.sort((a, b) =>
        a.Category.localeCompare(b.Category)
      );
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort((a, b) =>
        b.Category.localeCompare(a.Category)
      );
      setExportData(sortedData);
    }
    setSortByCategory(!sortByCategory);
  };

  const handleSortByStatus = () => {
    if (!sortByStatus) {
      const sortedData = exportData.sort((a, b) =>
        a.Status.localeCompare(b.Status)
      );
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort((a, b) =>
        b.Status.localeCompare(a.Status)
      );
      setExportData(sortedData);
    }
    setSortByStatus(!sortByStatus);
  };

  const handleSortByCount = () => {
    if (!sortByCount) {
      const sortedData = exportData.sort((a, b) => a.Count - b.Count);
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort((a, b) => b.Count - a.Count);
      setExportData(sortedData);
    }
    setSortByCount(!sortByCount);
  };

  const handleSortByRepair = () => {
    if (!sortByRepair) {
      const sortedData = exportData.sort(
        (a, b) => a.ServiceCost - b.ServiceCost
      );
      console.log(sortedData);
      setExportData(sortedData);
    } else {
      const sortedData = exportData.sort(
        (a, b) => b.ServiceCost - a.ServiceCost
      );
      setExportData(sortedData);
    }
    setSortByRepair(!sortByRepair);
  };

  if (exportData) {
    return (
      <>
        <div className="reportProfile">
          <Sidebar />
          <div className="">
            <Navbar pageHeader={props.header} />
            <div className="pageHeader">
              <div className="date-picker">
                <div className="dropdown">
                  <button className="dropbtn">
                    <img src={Calendar} />
                    {date}
                    <img src={DropDown} />{' '}
                  </button>
                  <div className="dropdown-content">
                    <a
                      onClick={() => {
                        setDate('Today');
                      }}
                    >
                      Today
                    </a>
                    <a
                      onClick={() => {
                        setDate('Last Month');
                      }}
                    >
                      Last Month
                    </a>
                    <a
                      onClick={() => {
                        setDate('Last Year');
                      }}
                    >
                      Last Year
                    </a>
                    <a
                      style={{
                        color: '#1167b1',
                        borderTop: '1px solid lightgray',
                      }}
                      onClick={() => {
                        setDate('Choose');
                      }}
                    >
                      <label style={{ marginRight: '4px' }}>Choose</label>
                      <input
                        value={calenderDate}
                        onChange={(e) => setCalenderDate(e.target.value)}
                        type="date"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="customize"
                style={{
                  color: 'black',
                  fontWeight: 'bolder',
                  fontSize: '14px',
                }}
              >
                <img src={Settings} />
                Customize Report
              </div>
              <div className="printOrExport">
                <div></div>
                <div></div>
                <div>
                  <img
                    src={exportImg}
                    style={{ width: '6rem', cursor: 'pointer' }}
                    onClick={handleExport}
                    alt=""
                  />
                </div>
                {/* <div onClick={handleExport}>Export</div> */}
              </div>
            </div>
            <div className="headline">
              <div>{props.header}</div>
              <div>{props.company}</div>
              {date === 'Today' && (
                <div>{dateConverter.getToday(dateFormat)}</div>
              )}
              {date === 'Last Month' && (
                <div>{dateConverter.getLastMonth(dateFormat)}</div>
              )}
              {date === 'Last Year' && (
                <div>{dateConverter.getLastYear(dateFormat)}</div>
              )}
              {date === 'Choose' && (
                <div>
                  {dateConverter.convertToDateFormat(calenderDate, dateFormat)}
                </div>
              )}
            </div>
            <div className="table-header">
              <div className="SNo">
                S.No.
                <img src={sortSign} className="sort-btn" alt="" />
              </div>
              <spam className="vr"></spam>
              <div>
                Category
                <img
                  src={sortSign}
                  onClick={handleSortByCategory}
                  className="sort-btn"
                  alt=""
                />
              </div>
              <spam className="vr"></spam>
              <div>
                Status
                <img
                  src={sortSign}
                  onClick={handleSortByStatus}
                  className="sort-btn"
                  alt=""
                />
              </div>
              <spam className="vr"></spam>
              <div>
                No. of Assets
                <img
                  src={sortSign}
                  onClick={handleSortByCount}
                  className="sort-btn"
                  alt=""
                />
              </div>
              <spam className="vr"></spam>
              <div>
                Capital Cost (
                <img
                  src={currecy[idx]}
                  style={{
                    width: '1.3rem',
                    height: '1rem',
                    marginLeft: '-1.5rem',
                    marginRight: '-1.6rem',
                  }}
                />
                )
                <img
                  src={sortSign}
                  onClick={handleSortByCapitalCost}
                  className="sort-btn"
                  alt=""
                />
              </div>
              <spam className="vr"></spam>
              <div>
                Repair & Service Cost
                <img
                  src={sortSign}
                  onClick={handleSortByRepair}
                  className="sort-btn"
                  alt=""
                />
              </div>
              <spam className="vr"></spam>
              <div>
                Scrapped Value (
                <img
                  src={currecy[idx]}
                  style={{
                    width: '1.3rem',
                    height: '1rem',
                    marginLeft: '-0.4rem',
                    marginRight: '-0.5rem',
                  }}
                />
                )
                <img src={sortSign} className="sort-btn" alt="" />
              </div>
              <spam className="vr"></spam>
              <div>
                Gross Depreciation
                <img src={sortSign} className="sort-btn" alt="" />
              </div>
              <spam className="vr"></spam>
              <div>
                WDV
                <img src={sortSign} className="sort-btn" alt="" />
              </div>
              <spam className="vr"></spam>
            </div>
            {sliceData(
              exportData.map((data) => {
                console.log(data.Category);
                cnt++;
                return (
                  <div
                    className={
                      cnt % 2 === 0 ? 'table-content BG2' : 'table-content BG1'
                    }
                  >
                    <div className="SNo">{cnt}</div>
                    <div>{data.Category}</div>
                    <div>{data.Status}</div>
                    <div>{data.Count}</div>
                    <div>
                      {numberFormat
                        ? numberConverter.formatIndian(data.CapitalCost)
                        : numberConverter.formatInternational(data.CapitalCost)}
                    </div>
                    <div>
                      {numberFormat
                        ? numberConverter.formatIndian(data.ServiceCost)
                        : numberConverter.formatInternational(data.ServiceCost)}
                    </div>
                    <div>
                      {numberFormat
                        ? numberConverter.formatIndian(data.ScrappedValue)
                        : numberConverter.formatInternational(
                            data.ScrappedValue
                          )}
                    </div>
                    <div>{0}</div>
                    <div>{0}</div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {/* <div className='pagination-controls'>
					<div className='inner-div-pageController'>
						<label>Show:</label>
						<select
							value={itemsPerPage}
							onChange={(e) => setItemsPerPage(parseInt(e.target.value))}>
							<option value={10}>10</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</select>
						<button onClick={() => setCurrentPage(1)}>First</button>
						<button
							onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
							Prev
						</button>
						<button
							onClick={() =>
								setCurrentPage(
									Math.min(
										Math.ceil(exportData.length / itemsPerPage),
										currentPage + 1
									)
								)
							}>
							Next
						</button>
						<button
							onClick={() =>
								setCurrentPage(Math.ceil(exportData.length / itemsPerPage))
							}>
							Last
						</button>
						<label>
							Page {currentPage} of{" "}
							{Math.ceil(exportData.length / itemsPerPage)}
						</label>
					</div>
				</div> */}
      </>
    );
  } else {
    return (
      <div className="unautorizedAccess">
        <h4>Loading Data...</h4>
      </div>
    );
  }
};

export default ReportDetails;
