import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import * as dashboardUtils from '../../Utils/dashboardUtils';
import { Bar, Pie, Line } from 'react-chartjs-2';
import * as AssetAPI from '../../api/AssetRequest';
// import 'chartjs-adapter-moment';
import './Dashboard.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const Dashboard = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const company = useSelector((state) => state.companyReducer.companyData);
  console.log(company);
  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [assetPurchasedThisMonth, setAssetPurchasedThisMonth] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [pieDetails, setPieDetails] = useState([]);
  const lastSixMonths = dashboardUtils.getLastSixMonths();

  // use Effects for all 4 graphs
  useEffect(() => {
    const getAssets = async () => {
      const response = await AssetAPI.getAllAssets(user._id);
      setTotalAssets(response.data.length);
      console.log(response.data);

      //bar graph - 1 (Capital Expenditure (INR))
      const updatedPurchaseDetails = response.data.map((item) => ({
        purchaseValue: item.purchaseValue,
        purchaseDate: item.purchaseDate,
      }));

      const formatedUpdatedPurchaseDetails = dashboardUtils.convertDateToFormat(
        updatedPurchaseDetails
      );
      const finalPDetails = formatedUpdatedPurchaseDetails
        .map((item) => item.purchaseValue)
        .reverse();
      setPurchaseDetails((prevPurchaseDetails) => [
        ...prevPurchaseDetails,
        ...finalPDetails,
      ]);

      // pie chart

      const pieInfo = [0, 0, 0, 0];
      response.data.map((item) => {
        if (item.status === 'Assigned') {
          pieInfo[0]++;
        } else if (item.status === 'Unassigned') {
          pieInfo[1]++;
        } else if (item.status === 'Inservice') {
          pieInfo[2]++;
        } else {
          pieInfo[3]++;
        }
      });
      setPieDetails([...pieInfo]);

      //bar graph - 2 (Service Expenditure (INR))
      const updatedServiceDetails = response.data.flatMap((item) => {
        return item.serviceArr.map((service) => ({
          purchaseValue: service.serviceCost,
          purchaseDate: service.serviceDate,
        }));
      });
      const formatedUpdatedServiceDetails = dashboardUtils.convertDateToFormat(
        updatedServiceDetails
      );
      const finalSDetails = formatedUpdatedServiceDetails
        .map((item) => item.purchaseValue)
        .reverse();
      finalSDetails.unshift(0)
      finalSDetails.unshift(0)
      finalSDetails.unshift(0)
      setServiceDetails((prevServiceDetails) => [
        ...prevServiceDetails,
        ...finalSDetails,
      ]);

      //bar graph - 3 (Asset Changes (#))
      response.data.map((item) => {
        if (dashboardUtils.isPurchasedInCurrentMonth(item.purchaseDate)) {
          setAssetPurchasedThisMonth(assetPurchasedThisMonth + 1);
        }
      });
    };
    getAssets();
  }, []);

  // data and options for bar graph - 1
  const data = {
    labels: lastSixMonths,
    datasets: [
      {
        label: 'Purchase Value',
        data: purchaseDetails,
        backgroundColor: '#1CA0C4',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Set to false to hide the legend
      },
      datalabels: false
    },
    scales: {
      x: {
        grid: {
          color: '#71797E', // Change the color of the vertical grid lines
        },
      },
      y: {
        grid: {
          color: '#71797E', // Change the color of the horizontal grid lines
        },
      },
    },
  };

  //data and options for bar graph - 2
  const data2 = {
    labels: lastSixMonths,
    datasets: [
      {
        label: 'Service Value',
        data: serviceDetails,
        backgroundColor: '#1CA0C4',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  //data and options for pie-chart
  const pieData = {
    labels: ['Allocated', 'Unallocated', 'In Service', 'Scrapped'],
    datasets: [
      {
        label: 'items:',
        data: pieDetails,
        backgroundColor: ['#87CEEB', '#0F708A', '#696B6C', '#939395'],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, context) => {
          if (value !== 0) {
            return value;
          } else {
            return null; 
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
            },
          },
        },
      },
    },
  };

  // data for bar-graph 3
  const data3 = {
    labels: [`# of Assets (${lastSixMonths[4]})`, `New Assets (${lastSixMonths[5]})`, `Total Assets (${lastSixMonths[5]})`],
    datasets: [
      {
        label: 'Number of assets',
        data: [
          totalAssets - assetPurchasedThisMonth,
          [totalAssets - assetPurchasedThisMonth,totalAssets],
          totalAssets,
        ],
        backgroundColor: '#1CA0C4',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        steppedLine: true // Use the stepped line
      },
    ],
  };


  if (user.role === 'ADMIN' || user.role === 'MANAGER' || user.role === 'ANALYTICS') {
    return (
      <>
        <div className="dashboardProfile">
          <Sidebar />
          <div className="dashboardRight">
            <Navbar pageHeader="Dashboard" />
            <div className="Charts">
              <div className="chart-containers">
                <div className="chart-header">Capital Expenditure ({`${company?company.currency:"INR"}`})</div>
                <div className="chart">
                  <Bar data={data} options={options} />
                </div>
              </div>
              <div className="chart-containers">
                <div className="chart-header">Service Expenditure ({`${company?company.currency:"INR"}`})</div>
                <div className="chart">
                  <Bar data={data2} options={options} />
                </div>
              </div>
              <div className="chart-containers">
                <div className="chart-header">Asset Changes (#)</div>
                <div className="chart">
                  <Bar data={data3} options={options} />
                </div>
              </div>
              <div className="chart-containers">
                <div className="chart-header">Assets by Status (%)</div>
                <div className="chart">
                  <Pie data={pieData} options={pieOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Dashboard;
