export const getToday = (format1) => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = String(today.getFullYear());
    if (format1)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;

}

export const getLastMonth = (format1) => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 0);
    const day = String(lastMonth.getDate()).padStart(2, '0');
    const month = String(lastMonth.getMonth() + 1).padStart(2, '0');
    const year = String(lastMonth.getFullYear());
    if (format1)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;

}

export const getLastYear = (format1) => {
    const today = new Date();
    const lastYear = new Date(today.getFullYear() - 1, 12, 0);

    const day = String(lastYear.getDate()).padStart(2, '0');
    const month = String(lastYear.getMonth() + 1).padStart(2, '0');
    const year = String(lastYear.getFullYear());

    if (format1)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;

}

export const getNextYear = (format1) => {
    const today = new Date();
    const nextYear = new Date(today.getFullYear() + 1, 12, 0);
    
    const day = String(nextYear.getDate()).padStart(2, '0');
    const month = String(nextYear.getMonth() + 1).padStart(2, '0');
    const year = String(nextYear.getFullYear());

    if (format1)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;
    
}

export const getLastThreeYears = (format1) => {
    const today = new Date();
    const lastYear = new Date(today.getFullYear() - 3, 12, 0);

    const day = String(lastYear.getDate()).padStart(2, '0');
    const month = String(lastYear.getMonth() + 1).padStart(2, '0');
    const year = String(lastYear.getFullYear());

    if (format1)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;
    
}

export const getNextThreeYears = (format1) => {
    const today = new Date();
    const nextYear = new Date(today.getFullYear() + 3, 12, 0);

    const day = String(nextYear.getDate()).padStart(2, '0');
    const month = String(nextYear.getMonth() + 1).padStart(2, '0');
    const year = String(nextYear.getFullYear());

    if (format1)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;

}


export const convertToDateFormat = (dateString, format) => {
    if(!dateString) return;
    const dateParts = dateString.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    if (format)
        return `${day}-${month}-${year}`;
    return `${month}-${day}-${year}`;

}

export const calculateDaysPassed = (givenDate) => {
    const currentDate = new Date();
    const givenDateTime = new Date(givenDate);
  
    // Calculate the difference in milliseconds between the two dates
    const timeDiff = Math.abs(currentDate - givenDateTime);
  
    // Convert milliseconds to days
    const daysPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  
    return daysPassed;
  }

