import React, { useEffect, useState } from 'react';
import Navbar from '../../Navbar/Navbar';
import Sidebar from '../../Sidebar/Sidebar';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import * as dashboardUtils from '../../../Utils/dashboardUtils';
import { Bar, Pie, Line } from 'react-chartjs-2';
import * as CompanyAPI from '../../../api/CompanyRequest';
// import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import 'chartjs-adapter-moment';

import './SuperAdminDashboard.css';

Chart.register(ChartDataLabels);
const SuperAdminDashboard = () => {
  const user = useSelector((state) => state.authReducer.authData?.userData);
  const [registeredInCurrentMonth, setRegisteredInCurrentMonth] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [pieDetails, setPieDetails] = useState([]);
  const [pieAccountDetails, setPieAccountDetails] = useState([]);
  const [pieUserDetails, setPieUserDetails] = useState([]);
  const lastSixMonths = dashboardUtils.getLastSixMonths();
  useEffect(() => {
    const getCompanies = async () => {
      const res = await CompanyAPI.getCompaniesForSuperAdmin(user._id);
      const res2 = await CompanyAPI.getDeletedCompaniesForSuperAdmin(user._id);
      setTotalCompanies(res.data.length);
      const pieInfo1 = [0, 0, res2.data.length];
      const pieInfo2 = [0, 0, 0];
      const pieInfo3 = [0, 0, 0];
      res.data.map((item) => {
        const date = new Date(item.createdAt);
        if (item.totalUsers.length === 1) pieInfo3[0]++;
        else if (item.totalUsers.length === 2 || item.totalUsers.length === 3)
          pieInfo3[1]++;
        else pieInfo3[2]++;
        const formattedDate = date.toISOString().split('T').shift();
        if (dashboardUtils.isPurchasedInCurrentMonth(formattedDate)) {
          setRegisteredInCurrentMonth(registeredInCurrentMonth + 1);
        }
        if (item.isRestricted) pieInfo1[1]++;
        else {
          pieInfo1[0]++;
          if (item.CompanyType === 'Pro') pieInfo2[0]++;
          else if (item.CompanyType === 'Trial') pieInfo2[2]++;
          else pieInfo2[1]++;
        }
      });
      setPieDetails([...pieInfo1]);
      setPieAccountDetails([...pieInfo2]);
      setPieUserDetails([...pieInfo3]);
    };
    getCompanies();
  }, []);
  const data = {
    labels: [
      `# of Companies (${lastSixMonths[4]})`,
      `New Companies (${lastSixMonths[5]})`,
      `Total Companies (${lastSixMonths[5]})`,
    ],
    datasets: [
      {
        label: 'Number of Companies',
        data: [
          totalCompanies - registeredInCurrentMonth,
          [totalCompanies - registeredInCurrentMonth, totalCompanies],
          totalCompanies,
        ],
        backgroundColor: '#1CA0C4',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        steppedLine: true, // Use the stepped line
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Set to false to hide the legend
      },
      datalabels: false,
    },
  };

  //data and options for pie-chart 1
  const pieData1 = {
    labels: ['Active', 'Suspended', 'Deleted'],
    datasets: [
      {
        label: 'account status:',
        data: pieDetails,
        backgroundColor: ['#87CEEB', '#0F708A', '#696B6C', '#939395'],
      },
    ],
  };

  const pieData2 = {
    labels: ['Pro', 'Standard', 'Trial'],
    datasets: [
      {
        label: 'account type:',
        data: pieAccountDetails,
        backgroundColor: ['#87CEEB', '#0F708A', '#696B6C', '#939395'],
      },
    ],
  };

  const pieData3 = {
    labels: ['1 User', '2-3 Users', '3+ Users'],
    datasets: [
      {
        label: 'Number of Companies:',
        data: pieUserDetails,
        backgroundColor: ['#87CEEB', '#0F708A', '#696B6C', '#939395'],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      datalabels: {
        color: 'white',
        formatter: (value, context) => {
          if (value !== 0) {
            return value;
          } else {
            return null; 
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
            },
          },
        },
      },
    },
  };
  
  if (user.role === 'SUPER ADMIN') {
    return (
      <>
        <div className="SA-dashboardProfile">
          <Sidebar />
          <div className="SA-dashboardRight">
            <Navbar pageHeader="Dashboard" />
            <div className="SA-Charts">
              <div className="SA-chart-containers">
                <div className="SA-chart-header">Companies Registered (#)</div>
                <div className="SA-chart">
                  <Bar data={data} options={options} />
                </div>
              </div>
              <div className="SA-chart-containers">
                <div className="SA-chart-header">Companies by Status (%)</div>
                <div className="SA-chart">
                  <Pie data={pieData1} options={pieOptions} />
                </div>
              </div>
              <div className="SA-chart-containers">
                <div className="SA-chart-header">Companies by Types</div>
                <div className="SA-chart">
                  <Pie data={pieData2} options={pieOptions} />
                </div>
              </div>
              <div className="SA-chart-containers">
                <div className="SA-chart-header">Companies by Users (%)</div>
                <div className="SA-chart">
                  <Pie data={pieData3} options={pieOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SuperAdminDashboard;
