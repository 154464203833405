import axios from 'axios';

const API = axios.create({
  //baseURL: `${process.env.REACT_APP_BASE_URL}`
   baseURL: `https://alm.tenie.co`,
});

export const logIn = (formData) => API.post('/auth/login', formData);
export const signUpAdmin = (formData) => API.post('/auth/register', formData); //Only first time registration link(contains both company details form and admin user form requests)
export const checkValidity = (formData) =>
  API.post('/auth/checkValidity', formData);
