import React, { useState } from 'react';
import './SignIn.css';
import waves from '../../img/waves.svg';
import logo from '../../img/tenie_logo.svg';
import profileDefault from '../../img/profileDefault.svg';
import { logIn } from '../../actions/AuthAction.js';
import * as userAPI from '../../api/UserRequest';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
/* SWAL - Sweet Alert */
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import wave from '../../img/alert_wave.svg'
import bell from '../../img/bellIcon.svg'

const MySwal = withReactContent(Swal);
const SignIn = () => {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({ email: '', password: '' });
  const [forgetPassword, setforgetPassword] = useState(false);
  const [forgetPasswordEmail, setforgetPasswordEmail] = useState('');
  const [otpRequested, setOtpRequested] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  const [newPassword, setnewPassword] = useState(null);
  const [otp, setOtp] = useState('');

  const resetPassword = async() => {
    const data = {token:resetPasswordToken, newPassword};
    try{  
      const res = await userAPI.resetPasswordByUser(data);
      MySwal.fire({
        title: <h5>Reset Password Successfull</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: "#1CA0C4",
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width:'375px',
        confirmButtonText: 'Ok !!',
      });
      setIsOtpVerified(false);
      setOtpRequested(false);
      setforgetPassword(false);
    }catch(err){
      MySwal.fire({
        title: <h5>Reset Password fail</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: "#1CA0C4",
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width:'375px',
        confirmButtonText: 'Ok !!',
      });
    }
  }
  const handleNewPassword = () => {
    if(!newPassword){
      MySwal.fire({
        title: <h5>Enter New Password</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: "#1CA0C4",
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width:'375px',
        confirmButtonText: 'Ok !!',
      });
      return;
    }
    resetPassword();
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (loginData.email && loginData.password) {
        dispatch(logIn(loginData));
      } else {
        MySwal.fire({
          title: <h5>Please input all the fields</h5>,
          imageUrl: '/img/bell.svg',
          imageAlt: 'Custom image',
          confirmButtonColor: "#1CA0C4",
          background: '#fff url(/img/wave.svg) no-repeat center bottom',
          width:'375px',
          confirmButtonText: 'Ok !!',
        });
      }
    } catch (err) {
      console.log('err', err);
    }
  };


  const sendOTP = async () => {
    const data = { email: forgetPasswordEmail };
    await userAPI.sendOtpToUser(data);
  };
  const verifyOTP = async() => {
    const data = { email: forgetPasswordEmail, otp };
    try{
      const res = await userAPI.verifyUserOtp(data);
      console.log(res.data.resetPasswordToken);
      setResetPasswordToken(res.data.resetPasswordToken);
      setIsOtpVerified(true);
    }catch(err){
      MySwal.fire({
        title: <h5>OTP Validation Failed</h5>,
        imageUrl: '/img/bell.svg',
        imageAlt: 'Custom image',
        confirmButtonColor: "#1CA0C4",
        background: '#fff url(/img/wave.svg) no-repeat center bottom',
        width:'375px',
        confirmButtonText: 'Ok !!',
      });
    }
  }
  const handleOTPrequest = (e) => {
    e.preventDefault();
    if (!otpRequested) {
      sendOTP();
    }else{
      if(!otp){
        alert('Please Enter the OTP');
        return; 
      }
      verifyOTP();
      return;
    }
    const data = otpRequested;
    setOtpRequested(!data);
  };
  if (!forgetPassword) {
    return (
      <div className="signin">
        <div className="signin-right" style={{marginTop:'-4rem'}}>
          <span className="signin-already-account" >
            Don't have a Tenie ALM account? <Link to="/signup">Sign Up</Link>
          </span>
        </div>
        <img className="tenie-logo-signin" src={logo} />
        <img src={profileDefault} className="profileDefault" alt="" />
        <p>Login</p>
        <form action="" className="loginForm">
          <input
            type="text"
            name="email"
            value={loginData.email}
            className="loginForm-data"
            placeholder="Email address"
            onChange={(e) =>
              setLoginData({ ...loginData, [e.target.name]: e.target.value })
            }
          />
          <input
            type="password"
            name="password"
            value={loginData.password}
            onChange={(e) =>
              setLoginData({ ...loginData, [e.target.name]: e.target.value })
            }
            className="loginForm-data"
            placeholder="Password"
          />
          <button
            type="submit"
            className="loginForm-button"
            onClick={handleSubmit}
          >
            Sign In
          </button>
          <p
            className="forgot-password"
            onClick={() => setforgetPassword(true)}
          >
            Forgot Password?
          </p>
        </form>
        <div className="waves-cont"></div>
      </div>
    );
  } else {
    return (
      <div className="signin">
        <div className="signin-right">
          <span className="signin-already-account">
            Don't have a Tenie ALM account? <Link to="/signup">Sign Up</Link>
          </span>
        </div>
        <img className="tenie-logo-signin" src={logo} />
        <img src={profileDefault} className="profileDefault" alt="" />
        <p>OTP Verification</p>
        <form action="" className="loginForm">
          <input
            type="text"
            name="email"
            value={forgetPasswordEmail}
            className="loginForm-data"
            placeholder="Enter your Email"
            onChange={(e) => {
              setforgetPasswordEmail(e.target.value);
            }}
          />
          {otpRequested && (
            <input
              type="text"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="loginForm-data"
              placeholder="Enter OTP Recieved on Email"
            />
          )}
          {isOtpVerified && (
            <input
              type="text"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setnewPassword(e.target.value)}
              className="loginForm-data"
              placeholder="Enter New Password"
            />
          )}
          <button
            type="button"
            className="loginForm-button"
            onClick={isOtpVerified?handleNewPassword:handleOTPrequest}
          >
            {isOtpVerified?'Confirm New Password':otpRequested ? 'Submit OTP' : 'Request OTP'}
          </button>
        </form>
        <div className="waves-cont"></div>
      </div>
    );
  }
};

export default SignIn;